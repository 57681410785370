// region-district-service.js

import { supabase } from "../../../../api/supabase";
import { useState, useEffect } from 'react';

// Constants
export const LANGUAGES = {
  ENGLISH: 'en',
  TRADITIONAL_CHINESE: 'zh-Hant',
  SIMPLIFIED_CHINESE: 'zh-Hans',
};

// Module cache
let cachedData = null;

/**
 * Fetch all region and district data
 */
const fetchData = async () => {
  try {
    const [regionsResult, districtsResult, relationsResult] = await Promise.all([
      supabase
        .from('region')
        .select(`
          id,
          name,
          region_translation (
            language,
            label
          )
        `)
        .order('id'),
      
      supabase
        .from('district')
        .select(`
          id,
          name,
          district_translation (
            language,
            label
          )
        `)
        .order('id'),
      
      supabase
        .from('region_district')
        .select('*')
    ]);

    if (regionsResult.error) throw regionsResult.error;
    if (districtsResult.error) throw districtsResult.error;
    if (relationsResult.error) throw relationsResult.error;

    // Transform regions data
    const regions = regionsResult.data.map(region => ({
      id: region.id,
      name: region.name,
      translations: region.region_translation.reduce((acc, t) => ({
        ...acc,
        [t.language]: t
      }), {})
    }));

    // Transform districts data with region relationships
    const districts = districtsResult.data.map(district => ({
      id: district.id,
      name: district.name,
      regionId: relationsResult.data.find(r => r.district_id === district.id)?.region_id || 0,
      translations: district.district_translation.reduce((acc, t) => ({
        ...acc,
        [t.language]: t
      }), {})
    }));

    return { regions, districts };
  } catch (error) {
    console.error('Failed to fetch region-district data:', error);
    throw error;
  }
};

/**
 * Public API
 */

export const getRegionDistrictData = async (forceFresh = false) => {
  if (!cachedData || forceFresh) {
    cachedData = await fetchData();
  }
  return cachedData;
};

export const getAllRegions = () => {
  return cachedData?.regions || [];
};

export const getAllDistricts = () => {
  return cachedData?.districts || [];
};

export const getRegionById = (id) => {
  return cachedData?.regions.find(r => r.id === Number(id));
};

export const getDistrictById = (id) => {
  return cachedData?.districts.find(d => d.id === Number(id));
};

export const getDistrictsByRegionId = (regionId) => {
  return cachedData?.districts.filter(d => d.regionId === Number(regionId)) || [];
};

export const getRegionFromDistrict = (districtId) => {
  const district = cachedData?.districts?.find(d => d.id === parseInt(districtId));
  if (!district) return null;
  
  const region = cachedData?.regions?.find(r => r.id === district.regionId);
  return region?.id || null;
};

export const getRegionTranslatedName = (item, language) => {
  return item?.translations?.[language].label || '';
};

export const getDistrictTranslatedName = (item, language) => {
  return item?.translations?.[language].label || '';
};

export const formatLocationDisplay = (regionId, districtId, language) => {
  const region = getRegionById(regionId);
  const district = getDistrictById(districtId);
  
  if (!region || !district) return '';
  
  return `${getRegionTranslatedName(region, language)} - ${getDistrictTranslatedName(district, language)}`;
};

/**
 * React Hook for region district data
 * @param {boolean} forceFresh - Force fetch fresh data
 * @returns {Object} - Region district data and state
 */
export const useRegionDistrict = (forceFresh = false) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const result = await getRegionDistrictData(forceFresh);
        setData(result);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [forceFresh]);

  return {
    regions: data?.regions || [],
    districts: data?.districts || [],
    loading,
    error,
    refresh: () => getRegionDistrictData(true).then(setData)
  };
};

// Additional helper functions can be added here as needed
export const clearCache = () => {
  cachedData = null;
};

export const isDistrictInRegion = (districtId, regionId) => {
  const district = getDistrictById(districtId);
  return district?.regionId === Number(regionId);
};

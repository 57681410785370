import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  Box,
  Flex,
  Input,
  SimpleGrid,
  VStack,
  InputGroup,
  InputLeftElement,
  Container,
  useColorModeValue,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Spinner,
  Center,
  Heading,
  Stack,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  useDisclosure,
} from '@chakra-ui/react';
import { SearchIcon, ChevronDownIcon, CloseIcon, RepeatIcon, TriangleUpIcon, TriangleDownIcon } from '@chakra-ui/icons';
import StatCard from './StatCard';
import DataTablePagination from './DataTablePagination';
import DataTable from '../components/base/DataTable'
import EditMemberModal from './EditMemberModal';
import DeleteMemberAlert from './DeleteMemberAlert';
// Import the axios instance
import { get, post, put, del } from '../../../api/request';
import { isEmptyArray } from 'formik';
import TableOperations from '../components/base/utils/TableOperations';

const DashboardPage = () => {
  const [members, setMembers] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [recentMembersCount, setRecentMembersCount] = useState(0);
  const [overallMembersCount, setOverallMembersCount] = useState(0);
  const [filterConfig, setFilterConfig] = useState({ text: '', field: null, value: '' });
  const [sortConfig, setSortConfig] = useState({ field: 'id', direction: 'asc' });
  const [pagination, setPagination] = useState({ currentPage: 1, itemsPerPage: 10
   });
  
  const [recentTimeFilter, setRecentTimeFilter] = useState('today'); // 独立的 Recent Members 状态
  const [overallTimeFilter, setOverallTimeFilter] = useState('thisMonth'); // 独立的 Members Over Time 状态

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [selectedMember, setSelectedMember] = useState(null);

  const { isOpen: isUpdateModalOpen, onOpen: onUpdateModalOpen, onClose: onUpdateModalClose } = useDisclosure();
  const { isOpen: isAlertDialogOpen, onOpen: onAlertDialogOpen, onClose: onAlertDialogClose } = useDisclosure();

  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const [idMap, setIdMap] = useState({}); // 新增：存储索引与 id 的映射
  const columnMap = useMemo(() => ({
    index: { label: '#', filterType: 'text' },
    given_name: { type: 'text', label: 'Given Name' },
    surname: { type: 'text', label: 'Surname' },
    email: { type: 'email', label: 'Email'},
    phone: { type: 'number', label: 'Phone' },
    birthday: { label: 'Birthday', filterType: 'date'},
    gender: { label: 'Gender', filterType: 'text'},
    points: { type: 'input', label: 'Points' },

  }), []);

  const columnLabels = useMemo(() => 
    Object.fromEntries(Object.entries(columnMap).map(([key, value]) => [key, value.label]))
  , [columnMap]);

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-CA');
  };

  const formatLanguage = (languageString) => {
    if (languageString === 'zh-Hant') {
      return 'Traditional Chinese'
    } else if (languageString === 'zh-Hans') {
      return 'Simplified Chinese'
    } else {
      return 'English'
    }
  };
  
  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

 // 使用 user_id 来请求总数
 const fetchTotalCount = useCallback(async () => {
  try {
    const userId = localStorage.getItem('userId');
    const response = await post('member/totalCount', { user_id: userId });
    
    const count = response.data.total_count || 0;

    setTotalCount(count);
  } catch (error) {
    console.error('Error fetching total count:', error);
  }
}, []);

const processMembers = useCallback((members) => {
  const idMapping = {};
  const processed = members.map((member, index) => {
    idMapping[index] = member.id; // 保存索引与 ID 的映射
    return {
      index: index + 1 + (pagination.currentPage - 1) * pagination.itemsPerPage,
      given_name: member.given_name,
      surname: member.surname,
      email: member.email,
      phone: member.phone,
      birthday: formatDate(member.birthday),
      gender: member.gender,
      points: member.points,
    };
  });
  setIdMap(idMapping); // 更新 ID 映射
  return processed;
}, [pagination.currentPage, pagination.itemsPerPage]);

const fetchPageTotalCount = async () => {
  try {
    const { count } = await global.genericapi.fetchRecords('users_profile', {
      select: 'id', 
      count: 'exact',
    });
    setTotalCount(count || 0);
    console.log('Total count:',count)
  } catch (error) {
    console.error('Error fetching total count:', error);
  }
};

  const fetchMembers = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const options = {
        filterConfig,
        sortConfig,
        pagination: {
          currentPage: pagination.currentPage,
          itemsPerPage: pagination.itemsPerPage,
        },
        searchFields: ['email']
      };
      if (options.filterConfig.value == ''){
        var { data, count } = await global.genericapi.fetchRecordsNew('users_profile', options);
        var data = await TableOperations['users_profile'].query()
      }else{
        var { data, count } = await global.genericapi.fetchRecordsNew('users_profile', options);
      }
      
      setMembers(data); 

      setTotalCount(count || 0); 
    } catch (error) {
      
      setError(error.message || 'Failed to fetch members. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }, [filterConfig, sortConfig, pagination]);

  const filterOptionIds = {
    recent: { today: 0, '7days': 1, '15days': 2, '30days': 3 },
    overall: { thisMonth: 0, '3months': 1, '6months': 2, '9months': 3, '12months': 4 }
  };
  

  // 请求 recentCount
  const fetchRecentCount = useCallback(async (option_id = "0") => {
    try {
      const userId = localStorage.getItem('userId');
      const response = await post('member/recentCount', { user_id: userId, option_id:String(option_id) });
      setRecentMembersCount(response.data.total_count || "0");
    } catch (error) {
      console.error('Error fetching recent count:', error);
      setError(error.message || 'Failed to fetch recent count');
    }
  }, []);

  // 请求 overTimeCount
  const fetchOverallCount = useCallback(async (option_id = "0") => {
    try {
      const userId = localStorage.getItem('userId');
      const response = await post('member/overTimeCount', { user_id: userId, option_id:String(option_id) });
      setOverallMembersCount(response.data.total_count || "0");
    } catch (error) {
      console.error('Error fetching overall count:', error);
      setError(error.message || 'Failed to fetch overall count');
    }
  }, []);

  const getFilteredMembers = useCallback(async (filter) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    const filterMap = {
      today: today,
      '7days': new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000),
      '15days': new Date(today.getTime() - 15 * 24 * 60 * 60 * 1000),
      '30days': new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000),
      thisMonth: new Date(today.getFullYear(), today.getMonth(), 1),
      '3months': new Date(today.getFullYear(), today.getMonth() - 3, 1),
      '6months': new Date(today.getFullYear(), today.getMonth() - 6, 1),
      '9months': new Date(today.getFullYear(), today.getMonth() - 9, 1),
      '12months': new Date(today.getFullYear() - 1, today.getMonth(), 1),
    };

    const filterDate = filterMap[filter]?.toISOString();

    if (!filterDate) return 0;

    // try {
    //   const options = {
    //     filterConfig: { field: 'created_at', value: filterDate, operator: 'gte' },
    //     sortConfig: { field: 'created_at', direction: 'desc' }
    //   };
    //   console.log(filter,77878)
    //   const count = await fetchMemberInfo(filter);
    //   // const { count } = await global.genericapi.fetchRecordsNew('members', options);
    //   return count;
    // } catch (error) {
    //   console.error('Error getting filtered members:', error);
    //   return 0;
    // }
  }, []);
  useEffect(() => {
    const recentOptionId = filterOptionIds.recent[recentTimeFilter];
    fetchRecentCount(recentOptionId);
  }, [recentTimeFilter, fetchRecentCount]);

  // 当 timeFilters.overall 改变时，重新请求 overTimeCount API
  useEffect(() => {
    const overallOptionId = filterOptionIds.overall[overallTimeFilter];
    fetchOverallCount(overallOptionId);
  }, [overallTimeFilter, fetchOverallCount]);


  const fetchMemberInfo = async (timeFilter) => {
    try {
      const response = await post('member/totalCount', { key: timeFilter });
      return response; 
    } catch (error) {
      console.error('Error fetching member info:', error);
      throw error;
    }
  };
  
  useEffect(() => {
    fetchTotalCount()
    fetchRecentCount(); // 默认 option_id = 0
    fetchOverallCount(); // 默认 option_id = 0
    fetchMembers();
    fetchPageTotalCount();
  },[pagination, fetchMembers]);

  const processedData = useMemo(() => {
    console.log('Processed data:', processMembers(members)); // 添加日志检查
    return processMembers(members);
  }, [members, pagination]);

  const handleSort = useCallback((field) => {
    setSortConfig(prevConfig => ({
      field,
      direction: prevConfig.field === field && prevConfig.direction === 'asc' ? 'desc' : 'asc'
    }));
  }, []);

  const handleUpdateClick = (member, index) => {
    const id = idMap[index];
    console.log(idMap, "idMap内容");
console.log(index, "当前index");
console.log(idMap[index], "对应的id");
    setSelectedMember({ ...member, id });
    onUpdateModalOpen();
  };
  
  const handleDeleteClick = (member, index) => {
    const id = idMap[index];
    setSelectedMember({ ...member, id });
    onAlertDialogOpen();
  };

  const handleFilterChange = useCallback((field) => {
    setFilterConfig(prev => ({ ...prev, field, value: '' }));
  }, []);

  const handleFilterValueChange = useCallback((value) => {
    setFilterConfig(prev => ({ ...prev, value }));
  }, []);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchText(value);
    console.log(value,9999)
    if (value === '') {
      // 重置分页设置
      setFilterConfig({ text: '', field: null, value: '' }); // 清空搜索条件
      console.log(value,33333)
      setPagination({ currentPage: 1, itemsPerPage: 10 });
      fetchMembers();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      // 设置分页并进行搜索
      setPagination({ currentPage: 1, itemsPerPage: 10 });
      setFilterConfig((prev) => ({ ...prev, text: searchText }));
      fetchMembers();
    }
  };

  const handlePageChange = useCallback((newPage) => {
    setPagination(prev => ({ ...prev, currentPage: newPage }));
  }, []);

  const handleItemsPerPageChange = useCallback((newItemsPerPage) => {
    setPagination(prev => ({ ...prev, itemsPerPage: newItemsPerPage, currentPage: 1 }));
  }, []);

  if (isLoading) {
    return (
      <Center h="100vh">
        <Spinner size="xl" color="blue.500" thickness="4px" />
      </Center>
    );
  }

  return (
    <Box width="100%" maxWidth="100vw" overflowX="hidden">
      <Container maxW="full" px={[2, 4, 6]}>
        <VStack spacing={[4, 6, 8]} align="stretch">

          {error && (
            <Alert status="error">
              <AlertIcon />
              <AlertTitle mr={2}>Error!</AlertTitle>
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}

          <SimpleGrid columns={{ base: 1, sm: 2, lg: 3 }} spacing={[4, 6]} width="100%">
            <StatCard
              label="Total Active Members"
              value={totalCount}
              color="teal.500"
              isEmpty={totalCount === 0}
              emptyMessage="No members"
              bgColor={bgColor}
              borderColor={borderColor}
              tooltip="Total number of registered members"
            />
            <StatCard
              label="Recent Members"
              value={recentMembersCount}
              color="blue.500"
              isEmpty={recentMembersCount === 0}
              emptyMessage="No recent members"
              bgColor={bgColor}
              borderColor={borderColor}
              tooltip="Members who joined recently"
              options={[
                { value: 'today', label: 'Today' },
                { value: '7days', label: '7 Days' },
                { value: '15days', label: '15 Days' },
                { value: '30days', label: '30 Days' },
              ]}
              onOptionChange={(e) => setRecentTimeFilter(e.target.value)} 
            />
            <StatCard
              label="Members Over Time"
              value={overallMembersCount}
              color="green.500"
              isEmpty={overallMembersCount === 0}
              emptyMessage="No members in selected period"
              bgColor={bgColor}
              borderColor={borderColor}
              tooltip="Members who joined over a specific time period"
              options={[
                { value: 'thisMonth', label: 'This Month' },
                { value: '3months', label: '3 Months' },
                { value: '6months', label: '6 Months' },
                { value: '9months', label: '9 Months' },
                { value: '12months', label: '12 Months' },
              ]}
              onOptionChange={(e) => setOverallTimeFilter(e.target.value)}
            />
          </SimpleGrid>

          <Box
            bg={bgColor}
            borderRadius="lg"
            boxShadow="md"
            p={[3, 4, 5]}
            borderWidth={1}
            borderColor={borderColor}
            width="100%"
          >
            <VStack spacing={[3, 4, 5]} align="stretch">
              <Flex direction={["column", "row"]} justifyContent="space-between" alignItems={["flex-start", "center"]} wrap="wrap" gap={4}>
                <InputGroup maxW={["full", "300px"]}>
                  <InputLeftElement pointerEvents="none">
                    <SearchIcon color="gray.300" />
                  </InputLeftElement>
                  <Input
                    placeholder="Search members email..."
                    value={searchText}
                    onChange={handleSearchChange}
                    onKeyDown={handleKeyDown}
                    borderRadius="full"
                  />
                </InputGroup>
                <IconButton
                  variant='action'
                  ml="auto"
                  icon={<RepeatIcon />}
                  aria-label="Refresh"
                  onClick={fetchMembers}
                />
              </Flex>
              
              {filterConfig.field && (
                <Flex direction={["column", "row"]} alignItems={["flex-start", "center"]} wrap="wrap" gap={2}>
                  <Box>Filter by {columnMap[filterConfig.field].label}:</Box>
                  <Input
                    value={filterConfig.value}
                    onChange={(e) => handleFilterValueChange(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') fetchMembers();
                    }}
                    placeholder={`Enter ${columnMap[filterConfig.field].label.toLowerCase()}...`}
                    maxW={["full", "300px"]}
                  />
                  <IconButton
                    icon={<CloseIcon />}
                    onClick={() => {
                      setFilterConfig(prev => ({ ...prev, field: null, value: '' }));
                      fetchMembers();
                    }}
                    aria-label="Clear filter"
                    size={["sm", "md"]}
                  />
                </Flex>
              )}

              <DataTable
                data={processedData}
                columnMap={columnLabels}
                sortField={sortConfig.field}
                sortDirection={sortConfig.direction}
                onSort={handleSort}
                onEdit={(member, idx) => handleUpdateClick(member, idx)} // 确保 `idx` 被传递
                onDelete={(member, idx) => handleDeleteClick(member, idx)} // 确
              />
    
              <DataTablePagination
                currentPage={pagination.currentPage}
                totalPages={Math.ceil(totalCount / pagination.itemsPerPage)}
                onPageChange={handlePageChange}
                itemsPerPage={pagination.itemsPerPage}
                setItemsPerPage={handleItemsPerPageChange}
                itemsPerPageOptions={[5, 10, 20, 50]}
              />
              
            </VStack>
          </Box>
        </VStack>
      </Container>

      <EditMemberModal 
        isOpen={isUpdateModalOpen}
        member={selectedMember}
        onClose={onUpdateModalClose}
        onUpdate={fetchMembers}
        tableName="users_profile"
        title="Edit Member" 
      />

      <DeleteMemberAlert
        isOpen={isAlertDialogOpen}
        onClose={onAlertDialogClose}
        memberId={selectedMember?.id}
        onSuccess={fetchMembers}
        tableName="users_profile"
        title="Delete Member"
        message="Are you sure you want to delete this member? This action cannot be undone."
      />
    </Box>
    
  );
};

export default DashboardPage;
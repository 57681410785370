import React, { useMemo } from 'react';
import {
    Box,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    HStack,
    Text,
    Icon,
    IconButton,
    useColorModeValue,
    useBreakpointValue,
    Flex,
    Button,
    Image,
    Badge,
    Tooltip,
    Divider
} from '@chakra-ui/react';
import { ChevronUpIcon, ChevronDownIcon, EditIcon, DeleteIcon, RepeatIcon } from '@chakra-ui/icons';
import { FaBoxOpen } from 'react-icons/fa';

const DataTable = ({
    data,
    columnMap = {},
    sortField = '',
    sortDirection = '',
    onSort,
    onEdit,
    onDelete,
    onRefresh,
}) => {
    // Theme and responsive values
    const tableSize = useBreakpointValue({ base: 'sm', md: 'md' });
    const fontSizes = useBreakpointValue({ base: 'sm', md: 'md' });
    const bgColor = useColorModeValue('white', 'gray.800');
    const textColor = useColorModeValue('gray.800', 'white');
    const borderColor = useColorModeValue('gray.200', 'gray.700');
    const hoverBgColor = useColorModeValue('gray.50', 'gray.700');
    const thBgColor = useColorModeValue('gray.50', 'gray.700');
    const thHoverBgColor = useColorModeValue('gray.100', 'gray.600');
    const dividerColor = useColorModeValue('gray.100', 'gray.600');

    // Get visible columns based on the data and columnMap
    const columns = useMemo(() => {
        if (data.length === 0) return [];
        return Object.keys(data[0])
            .filter(key => !key.startsWith('_') && key !== 'raw')
            .map(key => ({
                field: key,
                label: columnMap[key]?.label || key.charAt(0).toUpperCase() + key.slice(1),
                sortField: columnMap[key]?.sortField,
                sortable: columnMap[key]?.sortable !== false,
            }));
    }, [data, columnMap]);

    // Render cell content based on field type
    const renderCellContent = (content, field) => {
        if (content === undefined || content === null) return '-';

        const columnConfig = columnMap[field] || {};

        // Handle different content types based on column configuration
        if (React.isValidElement(content)) {
            return content;
        }

        // Handle status field
        if (field === 'is_active') {
            return (
                <Badge
                    colorScheme={content.colorScheme}
                    variant="subtle"
                    px={3}
                    py={1.5}
                    borderRadius="full"
                    fontSize="sm"
                    fontWeight="medium"
                    textTransform="none"
                    boxShadow="sm"
                >
                    {content.label}
                </Badge>
            );
        }

        // Handle image field
        if (field === 'image_url') {
            const column = columnMap[field] || {};
            const imageConfig = column.imageConfig || {};

            const baseSize = imageConfig.baseSize || '60px'; // Base size for calculation
            const ratio = imageConfig.ratio || '1:1';

            // Calculate dimensions based on ratio
            let width, height;
            const [w, h] = ratio.split(':').map(Number);

            if (w > h) {
                width = baseSize;
                height = `${parseInt(baseSize) * (h / w)}px`;
            } else {
                height = baseSize;
                width = `${parseInt(baseSize) * (w / h)}px`;
            }

            // console.log('width height ratio', width, height, ratio)
            return (
                <Box
                    width={width}
                    height={height}
                    margin="auto"
                    position="relative"
                    overflow="hidden"
                    borderRadius="md"
                    boxShadow="sm"
                >
                    <Image
                        src={content.url || ''}
                        alt={content.alt || ''}
                        objectFit="contain" // Changed from 'cover' to 'contain'
                        width="100%"
                        height="100%"
                        fallbackSrc=""
                        transition="transform 0.2s"
                        _hover={{
                            transform: 'scale(1.05)'
                        }}
                    />
                </Box>
            );
        }

        // Default text content
        const displayText = String(content);
        return (
            <Tooltip
                label={displayText}
                isDisabled={displayText.length < 10}
                placement="top"
                hasArrow
            >
                <Text
                    fontSize={fontSizes}
                    overflow="hidden"
                    maxW="100%"
                    cursor="pointer"
                    sx={{
                        display: '-webkit-box',
                        WebkitLineClamp: '2',
                        WebkitBoxOrient: 'vertical'
                    }}
                >
                    {displayText}
                </Text>
            </Tooltip>
        );
    };

    // Empty state component
    if (data.length === 0) {
        return (
            <Box
                borderWidth="1px"
                borderRadius="lg"
                borderColor={borderColor}
                p={6}
                bg={bgColor}
                boxShadow="sm"
            >
                <Flex direction="column" align="center" justify="center" py={10}>
                    <Icon as={FaBoxOpen} w={12} h={12} color="gray.400" mb={4} />
                    <Text fontSize="lg" fontWeight="medium" color="gray.500" mb={3}>
                        No data available
                    </Text>
                    <Button
                        leftIcon={<RepeatIcon />}
                        onClick={onRefresh}
                        colorScheme="blue"
                        size="sm"
                        boxShadow="sm"
                    >
                        Refresh
                    </Button>
                </Flex>
            </Box>
        );
    }

    return (
        <Box
            borderWidth="1px"
            borderRadius="lg"
            borderColor={borderColor}
            overflow="hidden"
            bg={bgColor}
            boxShadow="sm"
        >
            <Box overflowX="auto">
                <Table variant="simple" size={tableSize} width="100%">
                    <Thead>
                        <Tr bg={thBgColor}>
                            {columns.map((column) => (
                                <Th
                                    key={column.field}
                                    onClick={() => column.sortable && onSort(column.sortField || column.field)}
                                    cursor={column.sortable ? "pointer" : "default"}
                                    userSelect="none"
                                    transition="all 0.2s"
                                    _hover={{
                                        bg: column.sortable ? thHoverBgColor : 'inherit',
                                    }}
                                >
                                    <Flex
                                        alignItems={columnMap[column.field]?.align || 'center'}
                                        justifyContent={columnMap[column.field]?.align || 'center'}
                                        gap={1}
                                    >

                                        <Text
                                            fontSize={fontSizes}
                                            fontWeight="medium"
                                            textTransform="none"
                                            color={textColor}
                                            overflow="hidden"
                                            maxW="100%"
                                            // lineHeight="1.2em"  // Add this
                                            // maxHeight="2.4em"   // Add this (2 lines × 1.2em)
                                            paddingBottom="1px" // Add this to ensure descenders are visible
                                            sx={{
                                                display: '-webkit-box',
                                                WebkitLineClamp: '2',
                                                WebkitBoxOrient: 'vertical',
                                                textOverflow: 'ellipsis', // Add this
                                                wordBreak: 'break-word'    // Add this
                                            }}
                                        >
                                            {column.label}
                                        </Text>
                                        {column.sortable && sortField === (column.sortField || column.field) && (
                                            <Icon
                                                as={sortDirection === 'asc' ? ChevronUpIcon : ChevronDownIcon}
                                                w={5}
                                                h={5}
                                            />
                                        )}
                                    </Flex>
                                </Th>
                            ))}
                            <Th width="8%" textAlign="center">
                                <Text
                                    fontSize={fontSizes}
                                    fontWeight="medium"
                                    color={textColor}
                                    textTransform="none"
                                >
                                    Actions
                                </Text>
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {data.map((item, rowIndex) => (
                            <React.Fragment key={rowIndex}>
                                <Tr
                                    _hover={{ bg: hoverBgColor }}
                                    transition="background-color 0.2s"
                                    px={6}
                                >
                                    {columns.map((column) => (
                                        <Td
                                            key={column.field}
                                            textAlign={columnMap[column.field]?.align || 'center'}
                                            minW={columnMap[column.field]?.minWidth || 20}
                                            maxW={columnMap[column.field]?.maxWidth || 200}
                                            width={columnMap[column.field]?.width || 'auto'}
                                        >
                                            {renderCellContent(item[column.field], column.field)}
                                        </Td>
                                    ))}
                                    <Td textAlign="center">
                                        <HStack spacing={2} justifyContent="center">
                                            <IconButton
                                                icon={<EditIcon />}
                                                aria-label="Edit"
                                                size="sm"
                                                onClick={() => onEdit(item)}
                                                colorScheme="blue"
                                                variant="ghost"
                                            />
                                            <IconButton
                                                icon={<DeleteIcon />}
                                                aria-label="Delete"
                                                size="sm"
                                                onClick={() => onDelete(item)}
                                                colorScheme="red"
                                                variant="ghost"
                                            />
                                        </HStack>
                                    </Td>
                                </Tr>
                                {rowIndex < data.length - 1 && (
                                    <Tr>
                                        <Td colSpan={columns.length + 1} p={0}>
                                            <Divider
                                                borderColor={dividerColor}
                                                borderWidth="1px"
                                                opacity={0.6}
                                            />
                                        </Td>
                                    </Tr>
                                )}
                            </React.Fragment>
                        ))}
                    </Tbody>
                </Table>
            </Box>
        </Box>
    );
};

export default DataTable;
import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Heading,
  Grid,
  InputGroup,
  InputRightElement,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  useBreakpointValue,
  RadioGroup,
  Radio,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { CalendarIcon } from '@chakra-ui/icons';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const EditMemberModal = ({ member, isOpen, onClose, onUpdate }) => {

    const [formData, setFormData] = useState({
       
    });

    // Initialize formData when member changes
    useEffect(() => {
      console.log(member,"member edit info")
        if (member) {
            setFormData({
                ...member,
                given_name: member.given_name || '', // 从表中获取字段
                surname: member.surname || '',
                gender: member.gender || 'Other',
                birthday: parseDateOfBirth(member.birthday),
               points: member.points || "0",
            });
        }
    }, [member]);

  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  function parseDateOfBirth(dateString) {
    if (!dateString) return null;
    const date = new Date(dateString);
    return isNaN(date.getTime()) ? null : date;
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: '' }));
  };

  const handleDateChange = (date) => {
    setFormData((prev) => ({ ...prev, birthday: date }));
    setErrors((prev) => ({ ...prev, birthday: '' }));
  };
  const genders = [
    { label: 'Female', code: 'Female' },
    { label: 'Male', code: 'Male' },
    { label: 'Other', code: 'Other' }
  ];

  const handleLanguageChange = (value) => {
    // e.preventDefault();
    setFormData((prev) => ({ ...prev, preferred_language: value }));
  };

  const handleGenderChange = (value) => {
    // e.preventDefault();
    setFormData((prev) => ({ ...prev, gender: value }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.given_name) newErrors.given_name = 'Given name is required';
    if (!formData.surname) newErrors.surname = 'Surname is required';
    if (!formData.email) newErrors.email = 'Email is required';
    if (!formData.phone) newErrors.phone = 'Mobile number is required';
    if (!formData.birthday) newErrors.birthday = 'Date of birth is required';
    if (!formData.gender) newErrors.gender = 'Gender is required';
    if (!formData.points) newErrors.points = 'Points are required';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    const { index, ...rest } = formData;

    setIsLoading(true);
    try {
      await global.genericapi.updateRecord('users_profile', member.id, rest, 'id');
      onUpdate();
      onClose();
    } catch (error) {
      setErrors((prev) => ({ ...prev, submit: error.message }));
    } finally {
      setIsLoading(false);
    }
  };

  const gridTemplateColumns = useBreakpointValue({ base: '1fr', md: 'repeat(2, 1fr)' });
  const modalSize = useBreakpointValue({ base: "sm", md: "2xl", lg: "4xl" });

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={modalSize} scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Update Member</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box width="100%" p={3}>
            <form onSubmit={handleSubmit}>
              <VStack spacing={4} align="stretch">
                <Grid templateColumns={gridTemplateColumns} gap={4}>
                  <FormControl isInvalid={errors.given_name}>
                    <FormLabel>Given Name</FormLabel>
                    <Input name="given_name" value={formData.given_name|| ''} onChange={handleInputChange} />
                    {errors.given_name && (
                      <Alert status="error" mt={2} fontSize="sm">
                        <AlertIcon />
                        {errors.given_name}
                      </Alert>
                    )}
                  </FormControl>
                  <FormControl isInvalid={errors.surname}>
                    <FormLabel>Surname</FormLabel>
                    <Input name="surname" value={formData.surname || ''} onChange={handleInputChange} />
                    {errors.surname && (
                      <Alert status="error" mt={2} fontSize="sm">
                        <AlertIcon />
                        {errors.surname}
                      </Alert>
                    )}
                  </FormControl>
                </Grid>
                <FormControl isInvalid={errors.email}>
                  <FormLabel>Email</FormLabel>
                  <Input name="email" value={formData.email || ''} onChange={handleInputChange} type="email" />
                  {errors.email && (
                    <Alert status="error" mt={2} fontSize="sm">
                      <AlertIcon />
                      {errors.email}
                    </Alert>
                  )}
                </FormControl>
                <FormControl isInvalid={errors.phone}>
                  <FormLabel>Phone</FormLabel>
                  <Input name="phone" value={formData.phone || ''} onChange={handleInputChange} />
                  {errors.phone && (
                    <Alert status="error" mt={2} fontSize="sm">
                      <AlertIcon />
                      {errors.phone}
                    </Alert>
                  )}
                </FormControl>
                <FormControl isInvalid={errors.birthday}>
                  <FormLabel>Birthday</FormLabel>
                  <InputGroup onClick={() => document.querySelector('.react-datepicker__input-container input').focus()}>
                    <DatePicker
                      selected={formData.birthday || ''}
                      onChange={handleDateChange}
                      dateFormat="yyyy-MM-dd"
                      customInput={<Input readOnly />}
                    />
                    <InputRightElement children={<CalendarIcon color="gray.300" />} />
                  </InputGroup>
                  {errors.date_of_birth && (
                    <Alert status="error" mt={2} fontSize="sm">
                      <AlertIcon />
                      {errors.date_of_birth}
                    </Alert>
                  )}
                </FormControl>
                <FormControl isInvalid={errors.tier}>
                  <FormLabel>Gender</FormLabel>
                  <RadioGroup name="gender" value={formData.gender} onChange={handleGenderChange}>
                    <Grid templateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }} gap={4}>
                      {genders.map(({ label, code }) => (
                        <Radio key={code} value={code}>
                          {label}
                        </Radio>
                      ))}
                    </Grid>
                  </RadioGroup>
                  {errors.tier && (
                    <Alert status="error" mt={2} fontSize="sm">
                      <AlertIcon />
                      {errors.gender}
                    </Alert>
                  )}
                </FormControl>
                {/* <FormControl isInvalid={errors.preferred_language}>
                  <FormLabel>Preferred Language</FormLabel>
                  <RadioGroup name="preferred_language" value={formData.preferred_language} onChange={handleLanguageChange}>
                    <Grid templateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }} gap={4}>
                      {languages.map(({ label, code }) => (
                        <Radio key={code} value={code}>
                          {label}
                        </Radio>
                      ))}
                    </Grid>
                  </RadioGroup>
                  {errors.preferred_language && (
                    <Alert status="error" mt={2} fontSize="sm">
                      <AlertIcon />
                      {errors.preferred_language}
                    </Alert>
                  )}
                </FormControl> */}
                <FormControl isInvalid={errors.points}>
                  <FormLabel>Points</FormLabel>
                  <Input name="points" value={formData.points || ''} onChange={handleInputChange} />
                  {errors.points && (
                    <Alert status="error" mt={2} fontSize="sm">
                      <AlertIcon />
                      {errors.points}
                    </Alert>
                  )}
                </FormControl>
                {errors.submit && (
                  <Alert status="error">
                    <AlertIcon />
                    <AlertTitle mr={2}>Error!</AlertTitle>
                    <AlertDescription>{errors.submit}</AlertDescription>
                  </Alert>
                )}
              </VStack>
            </form>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
          <Button colorScheme="blue" onClick={handleSubmit} isLoading={isLoading} loadingText="Updating">
            Update
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditMemberModal;
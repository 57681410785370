import React, { useState, useEffect } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';

import TableOperations from '../../components/base/utils/TableOperations';

const AlertDialogComponent = ({ isOpen, onClose, onComplete, store, title, message }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const cancelRef = React.useRef();

  // Reset states when dialog reopens
  const handleDelete = async () => {
    setIsLoading(true);
    setError(null);
  
    try {
      const storesTableName = 'stores';
      const storesOperation = TableOperations[storesTableName] || TableOperations.default;
      const jsonData = {
        'p_store_id': parseInt(store.id)
      };
      await storesOperation.delete(jsonData);

      handleClose();
      onComplete();
    } catch (err) {
      console.error('Error deleting store:', err);
      setError(err.message || 'Failed to delete store');
    } finally {
      setIsLoading(false);
    }
  };

  // Handle close with cleanup
  const handleClose = () => {
    setIsLoading(false);
    setError(null);
    onClose();
  };

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={handleClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>

          <AlertDialogBody>
            {message}
            {error && (
              <Alert status="error" mt={4}>
                <AlertIcon />
                {error}
              </Alert>
            )}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={handleClose}>
              Cancel
            </Button>
            <Button 
              colorScheme="red" 
              onClick={handleDelete} 
              ml={3} 
              isLoading={isLoading}
              loadingText="Deleting..."
            >
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default AlertDialogComponent;
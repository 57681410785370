import React, { useRef } from 'react';
import { FormControl, FormLabel, InputGroup, InputRightElement, Button, Input } from '@chakra-ui/react';
import { CalendarIcon } from '@chakra-ui/icons';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
const DateTimeField = ({ label, value, onChange, ...rest }) => {
    const datePickerRef = useRef(null);
  
    return (
      <FormControl mb={4}>
        <FormLabel>{label}</FormLabel>
        <InputGroup>
          <Input
            as={DatePicker}
            selected={value ? new Date(value) : null}
            onChange={(date) => onChange(date)}
            showTimeSelect
            dateFormat="yyyy/MM/dd h:mm aa" // Format with time
            timeFormat="HH:mm"
            timeIntervals={15} // Time intervals of 15 minutes
            className="chakra-input css-1c6j008"
            wrapperClassName="chakra-datepicker"
            ref={datePickerRef}
            popperPlacement="top-start"
            {...rest}
          />
          <InputRightElement width="3rem">
            <Button h="1.75rem" size="sm" onClick={() => datePickerRef.current.setOpen(true)}>
              <CalendarIcon />
            </Button>
          </InputRightElement>
        </InputGroup>
      </FormControl>
    );
  };
  
  export default DateTimeField;
// UsersPage.js
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  Box,
  Flex,
  VStack,
  useColorModeValue,
  IconButton,
  Spinner,
  Center,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  useDisclosure,
  HStack,
  Avatar
} from '@chakra-ui/react';
import { RepeatIcon, AddIcon } from '@chakra-ui/icons';
import DataTablePagination from '../../components/base/DataTablePagination';
import DataTable from '../../components/base/DataTableTranslate';
import AddEditUserModal from './AddEditUserModal';
import DeleteUserAlert from './AlertDialogComponent';
import { format } from 'date-fns';
import { supabase } from '../../../../api/supabase';

const createColumnMap = () => ({
  index: {
    label: '#',
    sortable: false,
    width: '5%',
    align: 'center',
    formatter: (_, index, pagination) => (pagination.currentPage - 1) * pagination.itemsPerPage + index + 1
  },
  full_name: {
    label: 'Name',
    sortField: 'full_name',
    width: '20%',
    minWidth: '180px',
    align: 'center',
    formatter: (user) => user.full_name || '-',
    searchable: true
  },
  roles: {
    label: 'Roles',
    sortable: false,
    width: '15%',
    align: 'center',
    formatter: (user) => {
      const roleName = user.cms_user_roles?.[0]?.cms_roles?.name || '-';
      const capitalizedRole = roleName.charAt(0).toUpperCase() + roleName.slice(1);
      return capitalizedRole;
    },
  },
  email: {
    label: 'Email',
    sortField: 'email',
    width: '20%',
    minWidth: '250px',
    align: 'center',
    formatter: (user) => user.email || '-',
    searchable: true
  },
  department: {
    label: 'Department',
    sortField: 'department',
    width: '15%',
    minWidth: '150px',
    align: 'center',
    formatter: (user) => user.department || '-',
    searchable: true
  },
  last_sign_in_at: {
    label: 'Last Sign In',
    sortField: 'last_sign_in_at',
    width: '20%',
    align: 'center',
    minWidth: '180px',
    formatter: (user) => user.last_sign_in_at
      ? format(new Date(user.last_sign_in_at), 'yyyy-MM-dd HH:mm')
      : '-'
  },
  is_active: {
    label: 'Status',
    sortField: 'is_active',
    width: '10%',
    align: 'center',
    isStatus: true,
    formatter: (user) => ({
      value: user.is_active,
      label: user.is_active ? 'Active' : 'Inactive',
      colorScheme: user.is_active ? 'green' : 'red'
    })
  }
});

const UsersPage = () => {
  const [users, setUsers] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [filterConfig, setFilterConfig] = useState({ text: '', field: null, value: '' });
  const [sortConfig, setSortConfig] = useState({ field: 'created_at', direction: 'asc' });
  const [pagination, setPagination] = useState({ currentPage: 1, itemsPerPage: 10 });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);

  const {
    isOpen: isCreateModalOpen,
    onOpen: onCreateModalOpen,
    onClose: onCreateModalClose
  } = useDisclosure();

  const {
    isOpen: isUpdateModalOpen,
    onOpen: onUpdateModalOpen,
    onClose: onUpdateModalClose
  } = useDisclosure();

  const {
    isOpen: isAlertDialogOpen,
    onOpen: onAlertDialogOpen,
    onClose: onAlertDialogClose
  } = useDisclosure();

  const columnMap = useMemo(() => createColumnMap(), []);

  const processDataForTable = useCallback((usersData) => {
    return usersData.map((user, index) => {
      const processedData = {};

      Object.keys(columnMap).forEach(key => {
        const formattedValue = columnMap[key].formatter?.(
          user,
          index,
          pagination
        );
        processedData[key] = formattedValue;
      });

      processedData._id = user.id;
      return processedData;
    });
  }, [columnMap, pagination]);

  const fetchUsers = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const searchFields = Object.keys(columnMap)
        .filter(key => columnMap[key].searchable)
        .map(key => columnMap[key].sortField);

      // Start with base query
      let query = supabase
        .from('cms_users')
        .select(`
          *,
          cms_user_roles!cms_user_roles_user_id_fkey (
            role_id,
            cms_roles (
              id,
              name,
              description
            )
          )
        `)
        .eq('is_active', true); // Filter for active users

      // Add text search if filterConfig has field and value
      if (filterConfig.field && filterConfig.value) {
        query = query.ilike(filterConfig.field, `%${filterConfig.value}%`);
      }

      // Add sorting
      if (sortConfig.field && sortConfig.direction) {
        query = query.order(sortConfig.field, { 
          ascending: sortConfig.direction === 'asc' 
        });
      }

      // Add pagination
      const from = (pagination.currentPage - 1) * pagination.itemsPerPage;
      const to = from + pagination.itemsPerPage - 1;
      query = query.range(from, to);

      // Execute query
      const { data, error: queryError } = await query;
      if (queryError) throw queryError;

      // Get total count of active users
      const { count, error: countError } = await supabase
        .from('cms_users')
        .select('*', { count: 'exact', head: true })
        .eq('is_active', true);

      if (countError) throw countError;

      setUsers(data || []);
      setTotalCount(count || 0);
    } catch (error) {
      console.error('Error fetching users:', error);
      setError(error.message || 'Failed to fetch users');
    } finally {
      setIsLoading(false);
    }
  }, [filterConfig, sortConfig, pagination, columnMap]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const handleSort = useCallback((field) => {
    setSortConfig(prevConfig => ({
      field,
      direction: prevConfig.field === field && prevConfig.direction === 'asc' ? 'desc' : 'asc'
    }));
  }, []);

  const handleUpdateClick = useCallback((item) => {
    const originalUser = users.find(user => user.id === item._id);
    setSelectedUser(originalUser);
    onUpdateModalOpen();
  }, [onUpdateModalOpen, users]);

  const handleDeleteClick = useCallback((item) => {
    const originalUser = users.find(user => user.id === item._id);
    setSelectedUser(originalUser);
    onAlertDialogOpen();
  }, [onAlertDialogOpen, users]);

  const handlePageChange = useCallback((newPage) => {
    setPagination(prev => ({ ...prev, currentPage: newPage }));
  }, []);

  const handleItemsPerPageChange = useCallback((newItemsPerPage) => {
    setPagination(prev => ({ ...prev, itemsPerPage: newItemsPerPage, currentPage: 1 }));
  }, []);

  const bgColor = useColorModeValue('white', 'gray.800');

  return (
    <Box width="100%" maxWidth="100vw" overflowX="hidden">
      {error && (
        <Alert status="error" mb={4}>
          <AlertIcon />
          <AlertTitle mr={2}>Error!</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}

      <Box
        bg={bgColor}
        p={[3, 4, 5]}
        width="100%"
      >
        <VStack spacing={[3, 4, 5]} align="stretch">
          <Flex
            direction={["column", "row"]}
            justifyContent="flex-end"
            alignItems={["flex-start", "center"]}
            wrap="wrap"
            gap={4}
          >
            <HStack spacing={4} mt={[0, 0]} wrap="wrap">
              <IconButton variant='action' ml="auto" icon={<RepeatIcon />} aria-label="Refresh" onClick={fetchUsers} />
              <IconButton
                variant='action' ml="auto"
                icon={<AddIcon />}
                aria-label="Add"
                onClick={onCreateModalOpen}
              />
            </HStack>
          </Flex>

          {isLoading ? (
            <Center py={10}>
              <Spinner size="xl" color="blue.500" thickness="4px" />
            </Center>
          ) : (
            <DataTable
              data={processDataForTable(users)}
              columnMap={columnMap}
              sortField={sortConfig.field}
              sortDirection={sortConfig.direction}
              onSort={handleSort}
              onEdit={handleUpdateClick}
              onDelete={handleDeleteClick}
              onRefresh={fetchUsers}
            />
          )}

          {totalCount < pagination.itemsPerPage ? <></> : (
            <DataTablePagination
              currentPage={pagination.currentPage}
              totalPages={Math.ceil(totalCount / pagination.itemsPerPage)}
              onPageChange={handlePageChange}
              itemsPerPage={pagination.itemsPerPage}
              setItemsPerPage={handleItemsPerPageChange}
              itemsPerPageOptions={[5, 10, 20, 50]}
            />
          )}
        </VStack>
      </Box>

      <AddEditUserModal
        isOpen={isCreateModalOpen}
        onClose={onCreateModalClose}
        onComplete={fetchUsers}
      />

      <AddEditUserModal
        isOpen={isUpdateModalOpen}
        initialData={selectedUser}
        onClose={onUpdateModalClose}
        onComplete={fetchUsers}
      />

      <DeleteUserAlert
        isOpen={isAlertDialogOpen}
        onClose={onAlertDialogClose}
        onComplete={fetchUsers}
        user={selectedUser}
        title="Delete User"
        message="Are you sure you want to delete this user? This action cannot be undone."
      />
    </Box>
  );
};

export default UsersPage;
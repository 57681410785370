import React, { useMemo, useState, useCallback, useRef } from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  HStack,
  Text,
  Icon,
  IconButton,
  useColorModeValue,
  useBreakpointValue,
  Flex,
  Button,
} from '@chakra-ui/react';
import { ChevronUpIcon, ChevronDownIcon, EditIcon, DeleteIcon, RepeatIcon } from '@chakra-ui/icons';
import { FaBoxOpen } from 'react-icons/fa';

const DataTable = ({
  data,
  columnMap = {},
  sortField = '',
  sortDirection = '',
  onSort,
  onEdit,
  onDelete,
  onRefresh,
}) => {
  const [columnWidths, setColumnWidths] = useState({});
  const resizingColumn = useRef(null);
  const tableRef = useRef(null);

  const columns = useMemo(() => {
    if (data.length === 0) return [];
    return Object.keys(data[0])
      .filter(key => key !== 'user_id' && key !== 'fcm_token' && key !== 'created_at')
      .map(key => ({
        field: key,
        label: columnMap[key] || key.charAt(0).toUpperCase() + key.slice(1)
      }));
  }, [data, columnMap]);

  const tableSize = useBreakpointValue({ base: 'sm', md: 'sm', lg: 'sm' });
  const fontSizes = useBreakpointValue({ base: 'sm', md: 'sm', lg: 'sm' });

  const bgColor = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.800', 'white');
  const theadBgColor = useColorModeValue('gray.50', 'gray.700');
  const hoverBgColor = useColorModeValue('gray.50', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const thHoverBgColor = useColorModeValue('gray.100', 'gray.600');
  const resizeHandleColor = useColorModeValue('gray.300', 'gray.500');
  const resizeHandleHoverColor = useColorModeValue('blue.500', 'blue.300');

  const handleResizeStart = useCallback((e, field) => {
    e.preventDefault();
    resizingColumn.current = field;
    const startX = e.pageX;
    const startWidth = columnWidths[field] || e.target.offsetParent.offsetWidth;
    const tableWidth = tableRef.current.offsetWidth;

    const handleMouseMove = (e) => {
      if (resizingColumn.current) {
        const diff = e.pageX - startX;
        const newWidth = Math.max(startWidth + diff, 50); // Minimum width of 50px
        const newWidthPercentage = (newWidth / tableWidth) * 100;
        setColumnWidths(prev => ({ ...prev, [resizingColumn.current]: `${newWidthPercentage}%` }));
      }
    };

    const handleMouseUp = () => {
      resizingColumn.current = null;
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  }, [columnWidths]);

  const getColumnWidth = (index, field) => {
    if (columnWidths[field]) return columnWidths[field];
    if (index === 0) return '10%'; // ID column
    const remainingWidth = 90; // 100% - 10% for ID column
    const remainingColumns = columns.length; // Including the Actions column
    return `${remainingWidth / remainingColumns}%`;
  };

  const getColumnAlignment = (field) => {
    switch (field.toLowerCase()) {
      case 'id':
        return 'center';
      case 'title':
      case 'content':
        return 'left';
      default:
        return 'left';
    }
  };

  const renderEmptyState = () => (
    <Flex direction="column" align="center" justify="center" py={10}>
      <Icon as={FaBoxOpen} w={10} h={10} color="gray.400" mb={4} />
      <Text fontSize="lg" fontWeight="medium" color="gray.500" mb={2}>
        No data available
      </Text>
      <Button leftIcon={<RepeatIcon />} onClick={onRefresh} colorScheme="blue" size="sm">
        Refresh
      </Button>
    </Flex>
  );

  return (
    <Box
      borderWidth="1px"
      borderRadius="sm"
      borderColor={borderColor}
      boxShadow="lg"
      overflow="hidden"
    >
      <Box overflowX="auto">
        {data.length === 0 ? (
          renderEmptyState()
        ) : (
          <Table width="100%" ref={tableRef} variant="simple" size={tableSize} bg={bgColor} color={textColor} layout="fixed">
            <Thead >
              <Tr>
                {columns.map((column, index) => (
                  <Th
                    textTransform='none'
                    key={column.field}
                    onClick={() => onSort(column.field)}
                    cursor="pointer"
                    userSelect="none"
                    position="relative"
                    transition="all 0.2s"
                    _hover={{
                      bg: thHoverBgColor,
                    }}
                    width={getColumnWidth(index, column.field)}
                    textAlign={getColumnAlignment(column.field)}
                    // height="30px"  // Increased header height
                    verticalAlign="middle"
                  >
                    <Flex
                      alignItems="center"
                      justifyContent={column.field.toLowerCase() === 'id' ? 'center' : 'flex-start'}
                      height="100%"
                      overflow="hidden"
                    >
                      <Text
                        fontSize={fontSizes}
                        color={textColor}
                        whiteSpace="nowrap"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        isTruncated
                      >
                        {column.label}
                      </Text>
                      {sortField === column.field && (
                        <Icon
                          as={sortDirection === 'asc' ? ChevronUpIcon : ChevronDownIcon}
                          w={3}
                          h={3}
                          ml={1}
                          transition="transform 0.2s"
                        />
                      )}
                    </Flex>
                  </Th>
                ))}
                <Th
                  textTransform='none'
                  textAlign="center"
                  //   fontWeight="bold"
                  fontSize={fontSizes}
                  color={textColor}
                  //   bg={theadBgColor}
                  _hover={{
                    bg: thHoverBgColor,
                  }}
                  width={getColumnWidth(columns.length, 'actions')}
                  height="60px"  // Increased header height
                  verticalAlign="middle"
                >
                  <Flex alignItems="center" justifyContent="center" height="100%">
                    <Text>Actions</Text>
                  </Flex>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.map((item, rowIndex) => (
                <Tr
                  key={rowIndex}
                  _hover={{ bg: hoverBgColor }}
                  transition="background-color 0.2s"
                >
                  {columns.map((column, colIndex) => (
                    <Td
                      key={column.field}
                      fontSize={fontSizes}
                      width={getColumnWidth(colIndex, column.field)}
                      transition="width 0.3s ease"
                      textAlign={getColumnAlignment(column.field)}
                    >
                      {item[column.field]}
                    </Td>
                  ))}
                  <Td textAlign="center" width={getColumnWidth(columns.length, 'actions')}>
                    <HStack spacing={2} justifyContent="center">
                      <IconButton
                        icon={<EditIcon />}
                        aria-label="Edit"
                        size="sm"
                        onClick={() => onEdit(item, rowIndex)}
                        colorScheme="blue"
                        variant="ghost"
                      />
                      <IconButton
                        icon={<DeleteIcon />}
                        aria-label="Delete"
                        size="sm"
                        onClick={() => onDelete(item, rowIndex)}
                        colorScheme="red"
                        variant="ghost"
                      />
                    </HStack>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
      </Box>
    </Box>
  );
};

export default DataTable;
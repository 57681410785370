import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Alert,
  AlertIcon,
  useBreakpointValue,
  Text,
  Image,
  IconButton,
} from '@chakra-ui/react';

import * as FormFields from './FormFields';
import { DeleteIcon } from '@chakra-ui/icons';
import { ensureConvertersInitialized, handleTranslate } from './utils/TranslationHelper';
import { formatTranslationString } from './utils/formatTranslationString';
import DataTransformer from './utils/DataTransformer';
import TableOperations from './utils/TableOperations';

import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { Tabs, TabList, TabPanels, Tab, TabPanel,FormControl,FormLabel} from '@chakra-ui/react';
const LANGUAGE_LABELS = {
  en: 'English',
  'zh-Hans': 'Traditional Chinese',
  'zh-Hant': 'Simplified Chinese',
};
const BaseAddEditModal = ({
  isOpen,
  onClose,
  onSave,
  initialData,
  dataStructure,
  tableName,
  isEdit,
  isMultiLanguageSupported = false
}) => {
  const fileInputRef = useRef(isMultiLanguageSupported ? {} : null);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(null);
  const [isTranslatorReady, setIsTranslatorReady] = useState(false);
  const modalSize = useBreakpointValue({ base: "sm", md: "2xl", lg: "4xl" });
  const keysWithTranslateButton = ['caption', 'detail', 'tnc'];
  const [isImagePreviewOpen, setIsImagePreviewOpen] = useState(false);
  const [activeLanguageTab, setActiveLanguageTab] = useState(0); 
  const translations = {
    en: {
      editItem: "Edit Item",
      addItem: "Add Item"
    },
  };
  const supportedLanguage = ['en', 'zh-Hant', 'zh-Hans']
  const initializeTranslations = () => {
    return supportedLanguage.reduce((acc, language) => {
      acc[language] = Object.keys(dataStructure).reduce((fieldAcc, key) => {
        fieldAcc[key] = ''; // 每个字段初始化为空字符串
        return fieldAcc;
      }, {});
      return acc;
    }, {});
  };

  const [data, setData] = useState({
    ...initialData || {},
    translations: initialData?.translations || initializeTranslations()
  });
  const [previewImage, setPreviewImage] = useState(initialData?.image_url || null); 

  useEffect(() => {
    if (isOpen) {
      let translations = {};
      if (isMultiLanguageSupported) {
        translations = initialData.translations || {}; 
      }
  
      setData({
        ...initialData,
        translations: translations,
      });
      if (tableName === 'member_tier_config'){
        setPreviewImage(initialData?.card_face || null);
      }else{
        setPreviewImage(initialData?.image_url || null);
      }
      setAlert(null);
      setLoading(false);

      console.log("Initial Data:", initialData);
    }
  }, [isOpen, initialData, isMultiLanguageSupported]);

  useEffect(() => {
    ensureConvertersInitialized().then(() => setIsTranslatorReady(true));
  }, []);

  const handleChange = useCallback((field, value, language = null) => {
    setData((prevData) => {
      if (language) {
        const updatedTranslations = { ...prevData.translations };
        updatedTranslations[language] = {
          ...(updatedTranslations[language] || {}), // 确保 language 对象存在
          [field]: value,
        };
        return {
          ...prevData,
          translations: updatedTranslations,
        };
      } else {
        return { ...prevData, [field]: value };
      }
    });
  }, []);
  
  useEffect(() => {
    console.log(data.translations, 'Updated translations');
  }, [data.translations]);
  const handleImageUpload = (url, language) => {
    if (isMultiLanguageSupported) {
      handleChange('image_url', url, language);
    }else{
      handleChange('image_url', url);
    }
    
  };

  const handleImageDelete = (language) => {
    if (isMultiLanguageSupported == true) {
      handleChange("image_url", "", language); 
      fileInputRef.current[language].value = null;
      console.log("handleImageDelete: Cleared image_url for", language, data.translations[language]?.image_url);
    }else {
      handleChange("image_url", ""); 
      fileInputRef.current.value = null;
    }
  };

  const validateFields = useCallback(() => {
    const newErrors = {};
    const updatedTranslations = { ...data.translations };

    if (!isMultiLanguageSupported) {
      if (tableName === "member_tier_config"){
        if (data['image_url']) {
          data['card_face'] = data['image_url'];
        }else  {
          console.log(92341, data['card_face']);
          data['card_face'] = "" ;
        }
        if (!data['tier']) {
          data['tier'] = 'gold'; // 默认设置为 'gold'
        }
      }
      Object.entries(dataStructure).forEach(([key, field]) => {
        if (field.required && (!data[key] || data[key].toString().trim() === '')) {
          newErrors[key] = `${field.label} is required`;
        }
      });
      
    }else{
      supportedLanguage.forEach((language) => {
        const translation = data.translations[language];
        if (translation) {
          const updatedTranslation = { ...translation };
  
          if (!updatedTranslation.image_url) {
            updatedTranslation.image_url = "https://ibcmqseevofryxpilmwv.supabase.co/storage/v1/object/public/icon/event.jpg";
          }
  
          Object.entries(dataStructure).forEach(([key, field]) => {
            if (field.required && (!updatedTranslation[key] || updatedTranslation[key].toString().trim() === '')) {
              if (key === 'image_url') {
                updatedTranslation[key] = "https://ibcmqseevofryxpilmwv.supabase.co/storage/v1/object/public/icon/event.jpg";
              } else {
                newErrors[`${language}-${key}`] = `${field.label} is required in ${LANGUAGE_LABELS[language]}`;
              }
            }
          });
  
          // 更新 updatedTranslations
          updatedTranslations[language] = updatedTranslation;
        } else {
          newErrors[language] = `${LANGUAGE_LABELS[language]} translations are missing`;
        }
      });
  
      // 在验证完成后，使用 setData 更新状态
      setData(prevData => ({
        ...prevData,
        translations: updatedTranslations,
      }));
    }
    return newErrors;
  }, [data, dataStructure, supportedLanguage]);


  const createCleanData = (data) => {
    const translations = Object.entries(data.translations || {}).map(([language, translation]) => {
    
      const cleanTranslation = { language, ...translation };
      if (!cleanTranslation.image_url) {

        cleanTranslation.image_url = "https://ibcmqseevofryxpilmwv.supabase.co/storage/v1/object/public/icon/event.jpg";
    
        console.log("createCleanData: Assigned default image_url for", language, cleanTranslation.image_url);
      }

      delete cleanTranslation["updated_at"];
      delete cleanTranslation["created_at"];
  
      return cleanTranslation;
    });
  
  
    return {
      translations,
    };
  };
  
  const handleSave = useCallback(async () => {

    const newErrors = validateFields();
    
    if (Object.keys(newErrors).length > 0) {
      setAlert({
        title: 'Validation Error',
        description: Object.values(newErrors).join('\n'),
        status: 'error',
      });
      return;
    }
  
    setLoading(true);
    setAlert(null);
    if (isMultiLanguageSupported == true) {
    try {
        const operation = TableOperations[tableName] || TableOperations.default;
        const id = initialData.user_id ?? initialData.id;
        
        if (isEdit) {
          const cleanData = createCleanData(data);
          cleanData["p_event_id"] = data.parentId;
          await operation.update(cleanData);
        } else {
          const cleanData = createCleanData(data);
          
          await operation.add(cleanData);
        }
  
      setAlert({ title: 'Success', description: 'Records updated successfully', status: 'success' });
      onClose();
      onSave();
      window.location.reload();
    } catch (error) {
      setAlert({ title: 'Error', description: error.message, status: 'error' });
    } finally {
      setLoading(false);
    }
  }else{
    try {
      setLoading(true);
      const cleanData = { ...data };
      Object.keys(cleanData).forEach((key) => {
        if (!dataStructure[key] && key !== "id") {
          delete cleanData[key];
        }
      });
  
      const id = initialData.id ?? initialData.user_id ;

      if (isEdit) {
        await global.genericapi.updateRecord(tableName, id,cleanData);
      } else {
        
        await global.genericapi.addRecord(tableName, cleanData);
      }

      setAlert({ title: 'Success', description: 'Record updated successfully', status: 'success' });
      onClose();
      onSave();
    } catch (error) {
      setAlert({ title: 'Error', description: error.message, status: 'error' });
    } finally {
      setLoading(false);
    }
  }
  }, [data, initialData, onClose, onSave, tableName, validateFields, supportedLanguage]);
  

 
  // const renderImageField = useCallback((language) => {
   
  //   // 判断是否支持多语言
  //   const imageUrl = isMultiLanguageSupported
  //     ? data.translations[language]?.image_url
  //     : data.image_url;
  
  //   const handleUpload = (url) => {
  //     if (isMultiLanguageSupported) {
  //       handleImageUpload(url, language);
  //     } else {
  //       handleImageUpload(url);
  //     }
  //   };
  
  //   const handleDelete = () => {
  //     if (isMultiLanguageSupported) {
  //       handleImageDelete(language);
  //     } else {
  //       handleImageDelete();
  //     }
  //   };
 
  //   return (
  //     <FormControl mb={4}>
  //       {/* Label 始终在顶部 */}
  //       <FormLabel>Image</FormLabel>
  
  //       {imageUrl && (
  //         <Box position="relative" display="inline-block" mb={2}>
  //           <Image
  //             src={imageUrl}
  //             alt="Uploaded"
  //             maxHeight="150px"
  //             onClick={() => {
  //               setPreviewImage(imageUrl);
  //               setIsImagePreviewOpen(true);
  //             }} // 点击图片打开预览
  //             style={{ cursor: "pointer" }}
  //           />
  //           <IconButton
  //             icon={<DeleteIcon />}
  //             onClick={handleDelete}
  //             position="absolute"
  //             top="3px"
  //             right="3px"
  //             size="sm"
  //             aria-label="Delete Image"
  //             color="gray.500" // 图标颜色
  //             bg="gray.200" // 按钮背景色
  //             borderRadius="full" // 圆形按钮
  //             opacity="0.8" // 半透明效果
  //             _hover={{ bg: "gray.300", opacity: 1 }} // 悬停时不透明
  //             p="4px" // 内边距更小
  //             _active={{ bg: "gray.400" }} // 点击时背景色变化
  //           />
  //         </Box>
  //       )}
  
  //       {/* Input 框始终在底部 */}
  //       <FormFields.ImageField
  //         value={imageUrl || ""}
  //         onChange={handleUpload}
  //         fileInputRef={fileInputRef}
  //         showPreview={false}
  //         tableName={tableName}
  //         language={language}
  //         isImageError={false}
  //         isMultiLanguageSupported={isMultiLanguageSupported}
  //       />
  //     </FormControl>
  //   );
  // }, [data, isMultiLanguageSupported, handleImageDelete, handleImageUpload, fileInputRef, tableName]);

  const renderImageField = useCallback(
    (language = null) => {
      let imageUrl;
      if (isMultiLanguageSupported) {
        imageUrl = data.translations[language]?.image_url;
      } else if (tableName === 'member_tier_config') {
        imageUrl = data.card_face;
      } else {
        imageUrl = data.image_url;
      }

      // 上传处理函数
      const handleUpload = (imageUrl) => {
        if (isMultiLanguageSupported && language) {
          handleImageUpload(imageUrl, language);
        } else {
          handleImageUpload(imageUrl);
        }
      };
  
      // 删除处理函数
      const handleDelete = () => {
        if (isMultiLanguageSupported && language) {
          handleImageDelete(language);
        } else {
          handleImageDelete();
        }
      };
  
      return (
        <FormControl mb={4}>
          <FormLabel>Image</FormLabel>
  
          {imageUrl && (
            <Box position="relative" display="inline-block" mb={2}>
              <Image
                src={imageUrl}
                alt="Uploaded"
                maxHeight="150px"
                onClick={() => {
                  setPreviewImage(imageUrl);
                  setIsImagePreviewOpen(true);
                }}
                style={{ cursor: "pointer" }}
              />
              <IconButton
                icon={<DeleteIcon />}
                onClick={handleDelete}
                position="absolute"
                top="3px"
                right="3px"
                size="sm"
                aria-label="Delete Image"
                color="gray.500"
                bg="gray.200"
                borderRadius="full"
                opacity="0.8"
                _hover={{ bg: "gray.300", opacity: 1 }}
                p="4px"
                _active={{ bg: "gray.400" }}
              />
            </Box>
          )}
  
          {/* 始终渲染 ImageField */}
          <FormFields.ImageField
            value={imageUrl || ""}
            onChange={handleUpload}
            fileInputRef={fileInputRef}
            showPreview={false}
            tableName={tableName}
            language={language}
            isImageError={false}
            isMultiLanguageSupported={isMultiLanguageSupported}
          />
        </FormControl>
      );
    },
    [data, isMultiLanguageSupported, handleImageDelete, handleImageUpload, fileInputRef, tableName]
  );
  


  const handleTranslateClick = useCallback(async (field, language, currentValue) => {
    if (!isTranslatorReady) {
      console.log('Translator not ready yet');
      return;
    }

    let sourceLanguage, sourceText, targetLanguage;

    // Determine the source language based on the target language
    targetLanguage = language;
    if (language === 'zh-Hant') {
      sourceLanguage = 'zh-Hans';
    } else if (language === 'zh-Hans') {
      sourceLanguage = 'zh-Hant';
    } else {
      // For English or any other language, you might want to set a default behavior
      console.error('Unexpected language for translation');
      return;
    }

    if (currentValue) {
      // Use the current value if the field has text
      sourceText = currentValue;
    } else {
      // Find the source text from the other language translation
      const sourceTranslation = data.translations.find(t => t.language === sourceLanguage);
      if (!sourceTranslation || !(field in sourceTranslation)) {
        console.error(`Field ${field} not found in source translation`);
        return;
      }
      sourceText = sourceTranslation[field];
    }

    if (!sourceText) {
      console.error('No source text available for translation');
      return;
    }

    const translatedText = await handleTranslate(sourceText, sourceLanguage, targetLanguage);
    handleChange(field, translatedText, language);

  }, [data.translations, isTranslatorReady, handleChange]);
  const currentLanguage = supportedLanguage[activeLanguageTab];
  const renderField = useCallback((key, fieldType, language = null) => {

    if (key == 'image_url') {
      return renderImageField(language);
    }
    const { type, label, options, ...rest } = fieldType;

    let value = isMultiLanguageSupported
    ? data.translations?.[language]?.[key]
    : data[key]; 
  
    if ((type === "date" || type === 'dateTime') && (!value || isNaN(new Date(value).getTime()))) {
      value = ""; 
    }
    if (value === undefined || value === null) {
      value = ""; //  undefined or null 
    }
    const commonProps = {
      key: language ? `${language}-${key}` : key,
      label,
      value: String(value),
      onChange: (value) => handleChange(key, value, language),
      ...rest
    };

    const showTranslateButton = isMultiLanguageSupported &&  language && keysWithTranslateButton.includes(key);

    if (showTranslateButton) {
      commonProps.showTranslateButton = true;
      commonProps.onTranslate = () => handleTranslateClick(key, language, value);
    }

    switch (type) {
      case 'rich-text':
        return (
          <Box key={key} mb={4}>
            <Text fontWeight={500} mb={2}>{label}</Text> {/* show title */}
            <SunEditor
              setOptions={{
                height: 150, 
                zIndex: -9999,
                buttonList: [
                  ['undo', 'redo'],
                  ['font', 'fontSize', 'formatBlock'],
                  ['paragraphStyle', 'blockquote'],
                  ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                  ['fontColor', 'hiliteColor', 'textStyle'],
                  ['removeFormat'],
                  '/',
                  ['outdent', 'indent'],
                  ['align', 'horizontalRule', 'list', 'lineHeight'],
                  ['table', 'link', 'image', 'video', 'audio' /** ,'math' */],

                  ['fullScreen', 'showBlocks', 'codeView'],
                  ['preview', 'print'],
                  ['save', 'template'],
                ]
              }}
              setContents={String(value || "")}
              onChange={(newContent) => handleChange(key, newContent, language)}
            />
          </Box>
        );
      case 'text':
        return <FormFields.TextField {...commonProps} />;
      case 'textarea':
        return <FormFields.TextareaField {...commonProps} />;
      case 'date':
        return <FormFields.DateField {...commonProps} />;
      case 'dateTime':
        return <FormFields.DateTimeField {...commonProps} />;
      case 'image':
        // console.log(value, 'Updated image URL');
        // return <FormFields.ImageField {...commonProps} tableName={tableName} />;
        return renderImageField(language);
        // return (
        //   <FormFields.ImageField
        //     {...commonProps}
        //     tableName={tableName}
        //     fileInputRef={fileInputRef}
        //     isMultiLanguageSupported={isMultiLanguageSupported}
        //     language={language}
        //   />
        // );
      case 'inputNum':
          return <FormFields.InputNumberField {...commonProps} />;
      case 'select':
        return <FormFields.SelectField {...commonProps} options={options} />;
      case 'switch':
        return <FormFields.SwitchField {...commonProps} />;
      case 'number':
        return <FormFields.NumberField {...commonProps} />;
      case 'radio':
        return <FormFields.RadioField {...commonProps} options={options} />;
      case 'color':
        return <FormFields.ColorField {...commonProps} />;
      case 'checkbox':
        return <FormFields.CheckboxField {...commonProps} options={options} />;
      default:
        return null;
    }
  }, [data, handleChange, handleTranslateClick]);

  const modalTitle = isEdit
    ? translations.en.editItem
    : translations.en.addItem;

  return (
    <>
    <Modal isOpen={isOpen} onClose={onClose} size={modalSize}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{isEdit ? translations.en.editItem : translations.en.addItem}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {alert && (
            <Alert status={alert.status} mb={4}>
              <AlertIcon />
              <Box>
                <Box fontWeight="bold">{alert.title}</Box>
                <Box>{alert.description}</Box>
              </Box>
            </Alert>
          )}
           {isMultiLanguageSupported ? (
            <Tabs index={activeLanguageTab} onChange={setActiveLanguageTab}>
            <TabList>
              {Object.entries(LANGUAGE_LABELS).map(([code, label]) => (
                <Tab key={code}>{label}</Tab>
              ))}
            </TabList>
            <TabPanels>
              {supportedLanguage.map(language => (
                <TabPanel key={language}>
                  {Object.entries(dataStructure).map(([key, field]) => (
                    <Box key={key}>
                      {renderField(key, field, language)}
                    </Box>
                  ))}
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
       )  : (
          Object.entries(dataStructure).map(([key, field]) => renderField(key, field))
        )}
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>Cancel</Button>
          <Button colorScheme="blue" onClick={handleSave} ml={3} isLoading={loading}>Save</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>

{isImagePreviewOpen && (
  <Modal isOpen={isImagePreviewOpen} onClose={() => setIsImagePreviewOpen(false)} size="xl">
    <ModalOverlay />
    <ModalContent>
      <ModalCloseButton />
      <ModalBody>
        <Image src={previewImage} alt="Preview" maxWidth="100%" />
      </ModalBody>
    </ModalContent>
  </Modal>
)}
</>
  );
};

export default BaseAddEditModal;
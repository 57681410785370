import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Tab,
  Text,
  useBreakpointValue,
  Select,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Center,
} from '@chakra-ui/react';

import TableOperations from '../../../components/base/utils/TableOperations';
import DragNDrop from '../../../components/base/DragNDrop';
import { i18n, LANGUAGES } from './translations';
import PropTypes from 'prop-types';

const INITIAL_FORM_STATE = {
  translations: Object.values(LANGUAGES).map(lang => ({
    language: lang,
    image_url: '',
  }))
};

const AddEditTutorialModal = ({
  isOpen,
  onClose,
  onComplete,
  initialData = null,
  sortOrderOptions = []
}) => {
  const modalSize = useBreakpointValue({
    base: "90%",
    md: "90%",
    lg: "85%",
    xl: "80%"
  });

  const modalMaxWidth = useBreakpointValue({
    base: "90%",
    md: "800px",
    lg: "1000px",
    xl: "1200px"
  });

  const contentPadding = useBreakpointValue({
    base: "1rem",
    md: "1.5rem",
    lg: "2rem"
  });

  const submitButtonSize = useBreakpointValue({
    base: "80%",
    md: "70%",
    lg: "60%",
    xl: "50%"
  });

  const [formData, setFormData] = useState(INITIAL_FORM_STATE);
  const [activeLanguageTab, setActiveLanguageTab] = useState(0);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(i18n.getCurrentLanguage());
  const [alertInfo, setAlertInfo] = useState(null);

  const showAlert = (status, title, description) => {
    setAlertInfo({ status, title, description });
    setTimeout(() => setAlertInfo(null), 3000);
  };

  useEffect(() => {
    if (isOpen) {
      if (initialData) {
        setFormData({
          id: initialData.id,
          sort_order: initialData.sort_order?.toString(),
          translations: Object.values(LANGUAGES).map(lang => {
            const translation = initialData.translations.find(t => t.language === lang) || {};
            return {
              id: translation.id,
              language: lang,
              image_url: translation.image_url || '',
              tutorial_setting_id: translation.tutorial_setting_id
            };
          })
        });
      } else {
        setFormData(INITIAL_FORM_STATE);
      }
      setActiveLanguageTab(0);
      setErrors({});
      setAlertInfo(null);
    }
  }, [isOpen, initialData]);

  useEffect(() => {
    const languages = Object.values(LANGUAGES);
    const newLanguage = languages[activeLanguageTab];
    if (newLanguage) {
      setCurrentLanguage(newLanguage);
      i18n.setLanguage(newLanguage);
    }
  }, [activeLanguageTab]);

  const handleClose = () => {
    setFormData(INITIAL_FORM_STATE);
    setErrors({});
    setActiveLanguageTab(0);
    setAlertInfo(null);
    onClose();
  };

  const handleBasicInfoChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
    setErrors(prev => ({ ...prev, [field]: '' }));
  };

  const handleImageUpload = async (language, file) => {
    if (!file) return;

    const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
    const maxSize = 5 * 1024 * 1024;

    if (!validImageTypes.includes(file.type)) {
      showAlert('error', 'Invalid File Type', i18n.t('invalidImageType'));
      return;
    }

    if (file.size > maxSize) {
      showAlert('error', 'File Too Large', i18n.t('imageSizeLimit'));
      return;
    }

    try {
      const image_url = await global.genericapi.uploadImageAndGetPublicUrl('tutorial', file);
      setFormData(prev => ({
        ...prev,
        translations: prev.translations.map(translation =>
          translation.language === language
            ? { ...translation, image_url }
            : translation
        )
      }));
      showAlert('success', 'Success', i18n.t('uploadSuccess'));
    } catch (error) {
      showAlert('error', 'Upload Failed', error.message);
    }
  };

  const validateForm = () => {
    const newErrors = {};

    if (initialData && !formData.sort_order) {
      newErrors.sort_order = i18n.t('required');
    }

    formData.translations.forEach(translation => {
      if (!translation.image_url?.trim()) {
        newErrors[`${translation.language}_image_url`] = i18n.t('required');
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      showAlert('error', 'Validation Error', i18n.t('checkRequiredFields'));
      return;
    }

    setIsLoading(true);

    try {
      const tutorialTableName = 'tutorial_settings';
      const tutorialOperation = TableOperations[tutorialTableName] || TableOperations.default;
      if (initialData) {
        const transformedTranslations = {};
        formData.translations.forEach(translation => {
          transformedTranslations[translation.language] = {
            image_url: translation.image_url
          };
        });
        const jsonData = {
          'tutorial_setting_id': parseInt(initialData.id),
          'sort_order': parseInt(formData.sort_order),
          'translations': transformedTranslations
        };
        // console.log('jsonData',jsonData);
        await tutorialOperation.update(jsonData);
        showAlert('success', 'Success', i18n.t('storeUpdatedSuccess'));
      } else {
        const transformedTranslations = {};
        formData.translations.forEach(translation => {
          transformedTranslations[translation.language] = {
            image_url: translation.image_url
          };
        });

        // Find max sort_order from sortOrderOptions with parseInt
        let nextSortOrder = 1; // Default to 1 if no existing orders
        if (Array.isArray(sortOrderOptions) && sortOrderOptions.length > 0) {
          const validSortOrders = sortOrderOptions
            .map(option => {
              const parsedValue = parseInt(option);
              return parsedValue;
            })
            .filter(value => !isNaN(value));

          if (validSortOrders.length > 0) {
            nextSortOrder = Math.max(...validSortOrders) + 1;
          }
        }

        const jsonData = {
          'sort_order': parseInt(nextSortOrder),
          'translations': transformedTranslations
        };
        // console.log('jsonData', jsonData);
        await tutorialOperation.add(jsonData);
        showAlert('success', 'Success', i18n.t('storeCreatedSuccess'));
      }

      handleClose();
      onComplete();
    } catch (error) {
      console.error('Error in tutorial operation:', error);
      showAlert('error', 'Operation Failed', error.message || i18n.t('genericError'));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      size="full"
      scrollBehavior="inside"
      closeOnOverlayClick={false}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        maxW={modalMaxWidth}
        w={modalSize}
        mx="auto"
        my={{ base: 0, md: 4 }}
        h={{ base: "100vh", md: "auto" }}
      >
        <ModalHeader>
          {initialData ? i18n.t('editTutorial') : i18n.t('createTutorial')}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {alertInfo && (
            <Alert status={alertInfo.status} mb={4}>
              <AlertIcon />
              <AlertTitle mr={2}>{alertInfo.title}</AlertTitle>
              <AlertDescription>{alertInfo.description}</AlertDescription>
            </Alert>
          )}

          <form id="tutorial-form" onSubmit={handleSubmit}>
            <VStack spacing={6} align="stretch">
              {initialData && sortOrderOptions.length > 0 && (
                <Box px={contentPadding}>
                  <FormControl isInvalid={errors.sort_order} mb={4}>
                    <FormLabel>{i18n.t('sortOrder')}</FormLabel>
                    <Select
                      value={formData.sort_order}
                      onChange={(e) => handleBasicInfoChange('sort_order', e.target.value)}
                      placeholder={i18n.t('selectPageOrder')}
                    >
                      {sortOrderOptions.map((order) => (
                        <option key={order} value={order}>
                          {order}
                        </option>
                      ))}
                    </Select>
                    {errors.sort_order && (
                      <Text color="red.500" fontSize="sm" mt={1}>
                        {errors.sort_order}
                      </Text>
                    )}
                  </FormControl>
                </Box>
              )}

              <Tabs index={activeLanguageTab} onChange={setActiveLanguageTab}>
                <TabList px={contentPadding}>
                  {Object.values(LANGUAGES).map(code => (
                    <Tab key={code}>
                      {i18n.getLanguageLabel(code)}
                    </Tab>
                  ))}
                </TabList>
                <TabPanels>
                  {Object.values(LANGUAGES).map(langCode => (
                    <TabPanel key={langCode}>
                      <VStack spacing={4} align="stretch" px={contentPadding}>
                        <FormControl isInvalid={errors[`${langCode}_image_url`]}>
                          <FormLabel>{i18n.t('tutorialImage')}</FormLabel>
                          <DragNDrop
                            onFileUpload={(file) => handleImageUpload(langCode, file)}
                            currentImage={formData.translations.find(t => t.language === langCode)?.image_url}
                            dragText={i18n.t('dragImageHere')}
                          />
                          {errors[`${langCode}_image_url`] && (
                            <Text color="red.500" fontSize="sm" mt={1}>
                              {errors[`${langCode}_image_url`]}
                            </Text>
                          )}
                        </FormControl>
                      </VStack>
                    </TabPanel>
                  ))}
                </TabPanels>
              </Tabs>
            </VStack>
          </form>
        </ModalBody>
        <ModalFooter>
          <Center w="full">
            <Button
              colorScheme="blue"
              size="lg"
              type="submit"
              form="tutorial-form"
              isLoading={isLoading}
              loadingText={initialData ? i18n.t('updating') : i18n.t('creating')}
              width={submitButtonSize}
              bg="blue.500"
              color="white"
              _hover={{ bg: 'blue.600' }}
              _active={{ bg: 'blue.700' }}
              fontSize="md"
              fontWeight="bold"
              boxShadow="lg"
              borderRadius="md"
              transition="all 0.2s"
            >
              {initialData ? i18n.t('update') : i18n.t('create')}
            </Button>
          </Center>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

AddEditTutorialModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  sortOrderOptions: PropTypes.arrayOf(PropTypes.number),
  initialData: PropTypes.shape({
    id: PropTypes.number,
    sort_order: PropTypes.number,
    tutorial_settings_translations: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      language: PropTypes.string.isRequired,
      image_url: PropTypes.string,
      tutorial_setting_id: PropTypes.number,
      created_at: PropTypes.string,
      updated_at: PropTypes.string,
    })),
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
  }),
};

AddEditTutorialModal.defaultProps = {
  initialData: null,
  sortOrderOptions: [],
};

export default AddEditTutorialModal;
import React, { useState } from 'react';
import BasePageComponent from '../../components/base/BasePageComponent'
import AddEditNewsModal from './AddEditNewsModal';
import NewsCard from './NewsCard';

const NewsPage = () => {
  const [language, setLanguage] = useState('en'); 
  const tableName = 'users_profile';
  const primaryKey = 'id';

  const keyMappings = {
    given_name: 'Given Name',
    surname: 'Surname',
    email: 'Email',
    phone: 'Phone',
    birthday: 'Birthday',
    gender: 'Gender',
    points:'Points',
    // is_active: 'Active',
    // created_at: 'Created at',
    // updated_at: 'Updated at',
    // deleted_at: 'Deleted at',
  };

  const tableHeaders = [
    'given_name',
    'surname',
    'email',
    'phone',
    'birthday',
    'gender',
    'points',
    // is_active: 'Active',
    // created_at: 'Created at',
    // updated_at: 'Updated at',
    // deleted_at: 'Deleted at',
  ];

  return (
    <BasePageComponent
      txt="Member Info"
      addItemComponent={AddEditNewsModal}
      itemCardComponent={NewsCard}
      showToggleView={false}
      tableName={tableName}
      tableHeaders={tableHeaders}
      keyMappings={keyMappings} 
      showPagination={true}
      showSearch={true}
      searchPlaceholder="Search by email"
      searchFields="email"
      showAddIcon={false}
      primaryKey={primaryKey}
    />
  );
};

export default NewsPage;


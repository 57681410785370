import BaseAddEditModal from '../../components/base/BaseAddEditModal';
import React from 'react';

const AddEditNewsModal = ({ isOpen, onClose, initialData, onSave,isEdit }) => {
  console.log("Initial Data:", initialData)
  const dataStructure = {
    given_name: { type: 'text', label: 'Given Name', required: true },
    surname: { type: 'text', label: 'Surname', required: true },
    email: { type: 'email', label: 'Email', required: true },
    phone: { type: 'text', label: 'Phone', required: true },
    birthday: {
          type: 'date',
          label: 'Birthday',
          required: true
        },
    gender: {
      type: "select",
      label: 'Gender',
      required: true,
      options: [
        { value: 'Male', label: 'Male' },
        { value: 'Female', label: 'Female' },
        { value: 'Other', label: 'Other' },
      ]
    },
    points: { type: 'inputNum', label: 'Points', required: true }
  };

  return (
    <BaseAddEditModal
      isEdit={isEdit}
      isOpen={isOpen}
      onClose={onClose}
      initialData={initialData}
      onSave={onSave}
      dataStructure={dataStructure}
      tableName="users_profile"
      isMultiLanguageSupported={false} 
    />
  );
};

export default AddEditNewsModal;









// translations.js

/**
 * @typedef {'en' | 'zh-Hant' | 'zh-Hans'} Language
 */

// Configuration object
export const translationConfig = {
    defaultLanguage: 'en',
    englishOnly: true // Flag to control English-only text display
};

/**
 * @type {Record<Language, Record<string, string>>}
 */
export const translations = {
    en: {
        // Form Labels
        region: 'Region',
        district: 'District',
        storeName: 'Store Name',
        address: 'Address',
        businessHours: 'Business Hours',
        remarks: 'Remarks',
        storeImage: 'Store Image',
        location: 'Store Location',
        latitude: 'Latitude',
        longitude: 'Longitude',

        // Placeholders
        selectRegion: 'Select region',
        selectDistrict: 'Select district',
        enterStoreName: 'Enter store name',
        enterAddress: 'Enter address',
        enterBusinessHours: 'e.g., Mon-Fri 9am-6pm',
        optionalRemarks: 'Optional remarks',

        // Button Text
        create: 'Submit',
        update: 'Submit',
        cancel: 'Cancel',
        save: 'Save Changes',

        // Modal Titles
        createStore: 'Create New Store',
        editStore: 'Edit Store',

        // Messages
        requiredField: 'This field is required',
        validationError: 'Please check all required fields',

        // Image Upload
        dragImageHere: 'Drag image here or click to upload',
        invalidImageType: 'Please upload a valid image file (JPEG, PNG, or GIF)',
        imageSizeLimit: 'Image size should be less than 5MB',
        uploadSuccess: 'Image uploaded successfully',
        uploadError: 'Failed to upload image',

        // Success Messages
        storeCreated: 'Store created successfully',
        storeUpdated: 'Store updated successfully',

        // Error Messages
        invalidLatitude: 'Valid latitude required (-90 to 90)',
        invalidLongitude: 'Valid longitude required (-180 to 180)',
        genericError: 'An error occurred. Please try again.',
        networkError: 'Network error. Please check your connection.',

        // Validation Messages
        required: 'Required',
        invalidFormat: 'Invalid format',

        // Tabs
        generalInfo: 'General Information',
        locationDetails: 'Location Details',
        additionalInfo: 'Additional Information'
    },

    'zh-Hant': {
        region: '地區',
        district: '分區',
        storeName: '店鋪名稱',
        address: '地址',
        businessHours: '營業時間',
        remarks: '備註',
        storeImage: '店舖圖片',
        location: '店舖位置',
        latitude: '緯度',
        longitude: '經度',

        selectRegion: '選擇地區',
        selectDistrict: '選擇分區',
        enterStoreName: '輸入店鋪名稱',
        enterAddress: '輸入地址',
        enterBusinessHours: '例如：星期一至五 上午9時至下午6時',
        optionalRemarks: '選填備註',

        create: '創建店舖',
        update: '更新店舖',
        cancel: '取消',
        save: '保存更改',

        createStore: '創建新店舖',
        editStore: '編輯店舖',

        requiredField: '此欄位為必填',
        validationError: '請檢查所有必填欄位',

        dragImageHere: '拖拽圖片至此或點擊上傳',
        invalidImageType: '請上傳有效的圖片檔案（JPEG、PNG 或 GIF）',
        imageSizeLimit: '圖片大小應小於 5MB',
        uploadSuccess: '圖片上傳成功',
        uploadError: '圖片上傳失敗',

        storeCreated: '店舖創建成功',
        storeUpdated: '店舖更新成功',

        invalidLatitude: '請輸入有效緯度 (-90 至 90)',
        invalidLongitude: '請輸入有效經度 (-180 至 180)',
        genericError: '發生錯誤，請重試',
        networkError: '網絡錯誤，請檢查連接',

        required: '必填',
        invalidFormat: '格式無效',

        generalInfo: '基本信息',
        locationDetails: '位置詳情',
        additionalInfo: '附加信息'
    },

    'zh-Hans': {
        region: '地区',
        district: '分区',
        storeName: '店铺名称',
        address: '地址',
        businessHours: '营业时间',
        remarks: '备注',
        storeImage: '店铺图片',
        location: '店铺位置',
        latitude: '纬度',
        longitude: '经度',

        selectRegion: '选择地区',
        selectDistrict: '选择分区',
        enterStoreName: '输入店铺名称',
        enterAddress: '输入地址',
        enterBusinessHours: '例如：星期一至五 上午9时至下午6时',
        optionalRemarks: '选填备注',

        create: '创建店铺',
        update: '更新店铺',
        cancel: '取消',
        save: '保存更改',

        createStore: '创建新店铺',
        editStore: '编辑店铺',

        requiredField: '此字段为必填',
        validationError: '请检查所有必填字段',

        dragImageHere: '拖拽图片至此或点击上传',
        invalidImageType: '请上传有效的图片文件（JPEG、PNG 或 GIF）',
        imageSizeLimit: '图片大小应小于 5MB',
        uploadSuccess: '图片上传成功',
        uploadError: '图片上传失败',

        storeCreated: '店铺创建成功',
        storeUpdated: '店铺更新成功',

        invalidLatitude: '请输入有效纬度 (-90 至 90)',
        invalidLongitude: '请输入有效经度 (-180 至 180)',
        genericError: '发生错误，请重试',
        networkError: '网络错误，请检查连接',

        required: '必填',
        invalidFormat: '格式无效',

        generalInfo: '基本信息',
        locationDetails: '位置详情',
        additionalInfo: '附加信息'
    }
};

export const i18n = {
    /**
     * Get translated text for a given key and language
     * @param {string} key - Translation key
     * @param {Language} [lang] - Target language
     * @returns {string} Translated text
     */
    t(key, lang = translationConfig.defaultLanguage) {
        // Return English text if englishOnly is true, regardless of selected language
        if (translationConfig.englishOnly) {
            return translations.en[key] || key;
        }

        const translation = translations[lang]?.[key];
        if (!translation) {
            console.warn(`Translation missing for key: ${key} in language: ${lang}`);
            return translations.en[key] || key;
        }

        return translation;
    },

      // Get language label
  getLanguageLabel(code, displayLang = translationConfig.defaultLanguage) {
    if (translationConfig.englishOnly) {
      return LANGUAGE_LABELS.en[code] || code;
    }
    return LANGUAGE_LABELS[displayLang]?.[code] || LANGUAGE_LABELS.en[code] || code;
  },

    /**
     * Change the current language
     * @param {Language} lang - New language
     */
    setLanguage(lang) {
        if (translations[lang]) {
            translationConfig.defaultLanguage = lang;
        } else {
            console.warn(`Language ${lang} not supported, falling back to en`);
            translationConfig.defaultLanguage = 'en';
        }
    },

    /**
     * Get all available languages - always returns all languages
     * @returns {Language[]} Array of available languages
     */
    getAvailableLanguages() {
        return Object.keys(translations);
    },

    /**
     * Set English-only text display mode
     * @param {boolean} englishOnly - Whether to display English text only
     */
    setEnglishOnly(englishOnly) {
        translationConfig.englishOnly = englishOnly;
    },

    /**
     * Check if a language is supported
     * @param {string} lang - Language to check
     * @returns {boolean} Whether the language is supported
     */
    isLanguageSupported(lang) {
        return lang in translations;
    },

    /**
     * Get the current language
     * @returns {Language} Current language
     */
    getCurrentLanguage() {
        return translationConfig.defaultLanguage;
    },

    /**
     * Check if English-only mode is enabled
     * @returns {boolean} Whether English-only mode is enabled
     */
    isEnglishOnly() {
        return translationConfig.englishOnly;
    },

    /**
     * Get translation for multiple keys at once
     * @param {string[]} keys - Array of translation keys
     * @param {Language} [lang] - Target language
     * @returns {Record<string, string>} Object with translations
     */
    tMultiple(keys, lang = translationConfig.defaultLanguage) {
        return keys.reduce((acc, key) => ({
            ...acc,
            [key]: this.t(key, lang)
        }), {});
    }
};

export const INITIAL_FORM_STATE = {
    latitude: '',
    longitude: '',
    region_id: '',
    district_id: '',
    stores_translation: Object.keys(translations).reduce((acc, lang) => ({
        ...acc,
        [lang]: {
            address: '',
            remarks: '',
            branch_name: '',
            business_hours: '',
            image_url: '',
            language: lang,
        },
    }), {})
};

// Language codes for easy access
export const LANGUAGES = {
    ENGLISH: 'en',
    TRADITIONAL_CHINESE: 'zh-Hant',
    SIMPLIFIED_CHINESE: 'zh-Hans'
};

// Language labels with English translations
export const LANGUAGE_LABELS = {
    // When displayed in English
    en: {
        'en': 'English',
        'zh-Hant': 'Traditional Chinese',
        'zh-Hans': 'Simplified Chinese'
    },
    // When displayed in Traditional Chinese
    'zh-Hant': {
        'en': 'English',
        'zh-Hant': '繁體中文',
        'zh-Hans': '简体中文'
    },
    // When displayed in Simplified Chinese
    'zh-Hans': {
        'en': 'English',
        'zh-Hant': '繁体中文',
        'zh-Hans': '简体中文'
    }
};

export default i18n;
import React, { useState, useEffect } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';

import { post } from '../../../../api/request';

const AlertDialogComponent = ({ isOpen, onClose, onComplete, user, title, message }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const cancelRef = React.useRef();

  // Reset states when dialog reopens
  const handleDelete = async () => {
    setIsLoading(true);
    setError(null);

    try {

      const userData = {
        user_id: user?.id,
        operator_user_id: localStorage.getItem('userId')
      };

      const endpoint = `/cms_delete_user`;
      const response = await post(endpoint, userData);
      // console.log('response', response);
      if (response.data) {
        handleClose();
        onComplete();
      } else {
        throw new Error('Failed to delete user');
      }
    } catch (err) {
      console.error('Error deleting user:', err);
      setError(err.message || 'Failed to delete user');
    } finally {
      setIsLoading(false);
    }
  };

  // Handle close with cleanup
  const handleClose = () => {
    setIsLoading(false);
    setError(null);
    onClose();
  };

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={handleClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>

          <AlertDialogBody>
            {message}
            {error && (
              <Alert status="error" mt={4}>
                <AlertIcon />
                {error}
              </Alert>
            )}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={handleClose}>
              Cancel
            </Button>
            <Button
              colorScheme="red"
              onClick={handleDelete}
              ml={3}
              isLoading={isLoading}
              loadingText="Deleting..."
            >
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default AlertDialogComponent;
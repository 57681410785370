import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Grid,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Tab,
  Text,
  useBreakpointValue,
  Select,
  Textarea,
  Divider,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Center,
} from '@chakra-ui/react';

import TableOperations from '../../components/base/utils/TableOperations';
import DragNDrop from '../../components/base/DragNDrop';
import { i18n, LANGUAGES } from './translations';
import {
  useRegionDistrict,
  getRegionTranslatedName,
  getDistrictTranslatedName,
  getRegionFromDistrict
} from './region-district-service';
import PropTypes from 'prop-types';

const INITIAL_FORM_STATE = {
  district_id: '',
  region_id: '',
  latitude: '',
  longitude: '',
  translations: Object.values(LANGUAGES).map(lang => ({
    language: lang,
    address: '',
    remarks: '',
    branch_name: '',
    business_hours: '',
    image_url: '',
  }))
};

const AddEditStoreModal = ({ isOpen, onClose, onComplete, initialData = null }) => {
  const modalSize = useBreakpointValue({
    base: "90%",
    md: "90%",
    lg: "85%",
    xl: "80%"
  });

  const modalMaxWidth = useBreakpointValue({
    base: "90%",
    md: "800px",
    lg: "1000px",
    xl: "1200px"
  });

  const contentPadding = useBreakpointValue({
    base: "1rem",
    md: "1.5rem",
    lg: "2rem"
  });

  const submitButtonSize = useBreakpointValue({
    base: "80%",
    md: "70%",
    lg: "60%",
    xl: "50%"
  });

  const [formData, setFormData] = useState(INITIAL_FORM_STATE);
  const [activeLanguageTab, setActiveLanguageTab] = useState(0);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(i18n.getCurrentLanguage());
  const [alertInfo, setAlertInfo] = useState(null);
  const { regions, districts, loading: regionsLoading } = useRegionDistrict();

  const showAlert = (status, title, description) => {
    setAlertInfo({ status, title, description });
    setTimeout(() => setAlertInfo(null), 3000);
  };

  useEffect(() => {
    if (isOpen) {
      if (initialData) {
        const regionId = getRegionFromDistrict(initialData.district_id, districts);
        setFormData({
          id: initialData.id,
          district_id: initialData.district_id?.toString(),
          region_id: regionId?.toString(),
          latitude: initialData.latitude?.toString(),
          longitude: initialData.longitude?.toString(),
          translations: Object.values(LANGUAGES).map(lang => {
            const translation = initialData.translations.find(t => t.language === lang) || {};
            return {
              id: translation.id,
              language: lang,
              address: translation.address || '',
              remarks: translation.remarks || '',
              branch_name: translation.branch_name || '',
              business_hours: translation.business_hours || '',
              image_url: translation.image_url || '',
              store_id: translation.store_id
            };
          })
        });
      } else {
        setFormData(INITIAL_FORM_STATE);
      }
      setActiveLanguageTab(0);
      setErrors({});
      setAlertInfo(null);
    }
  }, [isOpen, initialData, districts]);

  useEffect(() => {
    const languages = Object.values(LANGUAGES);
    const newLanguage = languages[activeLanguageTab];
    if (newLanguage) {
      setCurrentLanguage(newLanguage);
      i18n.setLanguage(newLanguage);
    }
  }, [activeLanguageTab]);

  const handleClose = () => {
    setFormData(INITIAL_FORM_STATE);
    setErrors({});
    setActiveLanguageTab(0);
    setAlertInfo(null);
    onClose();
  };

  const getAvailableDistricts = useMemo(() => {
    if (!formData.region_id) return [];
    return districts.filter(district =>
      district.regionId === parseInt(formData.region_id)
    );
  }, [formData.region_id, districts]);

  const handleBasicInfoChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value,
      ...(field === 'region_id' ? { district_id: '' } : {}),
    }));
    setErrors(prev => ({ ...prev, [field]: '' }));
  };

  const handleTranslationChange = (language, field, value) => {
    setFormData(prev => ({
      ...prev,
      translations: prev.translations.map(translation =>
        translation.language === language
          ? { ...translation, [field]: value }
          : translation
      )
    }));
    setErrors(prev => ({ ...prev, [`${language}_${field}`]: '' }));
  };

  const handleImageUpload = async (language, file) => {
    if (!file) return;

    const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
    const maxSize = 5 * 1024 * 1024;

    if (!validImageTypes.includes(file.type)) {
      showAlert('error', 'Invalid File Type', i18n.t('invalidImageType'));
      return;
    }

    if (file.size > maxSize) {
      showAlert('error', 'File Too Large', i18n.t('imageSizeLimit'));
      return;
    }

    try {
      const image_url = await global.genericapi.uploadImageAndGetPublicUrl('store_location', file);
      handleTranslationChange(language, 'image_url', image_url);
      showAlert('success', 'Success', i18n.t('uploadSuccess'));
    } catch (error) {
      showAlert('error', 'Upload Failed', error.message);
    }
  };

  const validateForm = () => {
    const newErrors = {};

    const lat = parseFloat(formData.latitude);
    const lng = parseFloat(formData.longitude);

    if (!formData.latitude || isNaN(lat) || lat < -90 || lat > 90) {
      newErrors.latitude = i18n.t('invalidLatitude');
    }
    if (!formData.longitude || isNaN(lng) || lng < -180 || lng > 180) {
      newErrors.longitude = i18n.t('invalidLongitude');
    }
    if (!formData.region_id) {
      newErrors.region_id = i18n.t('required');
    }
    if (!formData.district_id) {
      newErrors.district_id = i18n.t('required');
    }

    formData.translations.forEach(translation => {
      if (!translation.branch_name?.trim()) {
        newErrors[`${translation.language}_branch_name`] = i18n.t('required');
      }
      if (!translation.address?.trim()) {
        newErrors[`${translation.language}_address`] = i18n.t('required');
      }
      // if (!translation.business_hours?.trim()) {
      //   newErrors[`${translation.language}_business_hours`] = i18n.t('required');
      // }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      showAlert('error', 'Validation Error', i18n.t('checkRequiredFields'));
      return;
    }

    setIsLoading(true);

    try {
      const storesTableName = 'stores';
      const storesOperation = TableOperations[storesTableName] || TableOperations.default;

      if (initialData) {
        const jsonData = {
          'p_store_id': parseInt(initialData.id),
          'p_district_id': parseInt(formData.district_id),
          'p_latitude': parseFloat(formData.latitude),
          'p_longitude': parseFloat(formData.longitude),
          'translations': formData.translations.map(translation => ({
            'id': parseInt(translation.id),
            'language': translation.language,
            'branch_name': translation.branch_name,
            'address': translation.address,
            'business_hours': translation.business_hours,
            'remarks': translation.remarks,
            'image_url': translation.image_url
          }))
        };
        // console.log('jsonData',jsonData);
        await storesOperation.update(jsonData);
        showAlert('success', 'Success', i18n.t('storeUpdatedSuccess'));
      } else {
        const jsonData = {
          'district_id': parseInt(formData.district_id),
          'latitude': parseFloat(formData.latitude),
          'longitude': parseFloat(formData.longitude),
          'translations': formData.translations.map(translation => ({
            'language': translation.language,
            'branch_name': translation.branch_name,
            'address': translation.address,
            'business_hours': translation.business_hours,
            'remarks': translation.remarks,
            'image_url': translation.image_url
          }))
        };
        await storesOperation.add(jsonData);
        showAlert('success', 'Success', i18n.t('storeCreatedSuccess'));
      }

      handleClose();
      onComplete();
    } catch (error) {
      console.error('Error in store operation:', error);
      showAlert('error', 'Operation Failed', error.message || i18n.t('genericError'));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      size="full"
      scrollBehavior="inside"
      closeOnOverlayClick={false}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        maxW={modalMaxWidth}
        w={modalSize}
        mx="auto"
        my={{ base: 0, md: 4 }}
        h={{ base: "100vh", md: "auto" }}
      >
        <ModalHeader>
          {initialData ? i18n.t('editStore') : i18n.t('createStore')}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {alertInfo && (
            <Alert status={alertInfo.status} mb={4}>
              <AlertIcon />
              <AlertTitle mr={2}>{alertInfo.title}</AlertTitle>
              <AlertDescription>{alertInfo.description}</AlertDescription>
            </Alert>
          )}

          <form id="store-form" onSubmit={handleSubmit}>
            <VStack spacing={6} align="stretch">
              <Tabs index={activeLanguageTab} onChange={setActiveLanguageTab}>
                <TabList px={contentPadding}>
                  {Object.values(LANGUAGES).map(code => (
                    <Tab key={code}>
                      {i18n.getLanguageLabel(code)}
                    </Tab>
                  ))}
                </TabList>
                <TabPanels>
                  {Object.values(LANGUAGES).map(langCode => (
                    <TabPanel key={langCode}>
                      <VStack spacing={4} align="stretch" px={contentPadding}>
                        <FormControl isInvalid={errors[`${langCode}_branch_name`]}>
                          <FormLabel>{i18n.t('storeName')}</FormLabel>
                          <Input
                            value={formData.translations.find(t => t.language === langCode)?.branch_name || ''}
                            onChange={(e) => handleTranslationChange(langCode, 'branch_name', e.target.value)}
                            placeholder={i18n.t('enterStoreName')}
                          />
                          {errors[`${langCode}_branch_name`] && (
                            <Text color="red.500" fontSize="sm" mt={1}>
                              {errors[`${langCode}_branch_name`]}
                            </Text>
                          )}
                        </FormControl>

                        <FormControl>
                          <FormLabel>{i18n.t('storeImage')}</FormLabel>
                          <DragNDrop
                            onFileUpload={(file) => handleImageUpload(langCode, file)}
                            currentImage={formData.translations.find(t => t.language === langCode)?.image_url}
                            dragText={i18n.t('dragImageHere')}
                          />
                        </FormControl>

                        <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                          <FormControl isInvalid={errors.region_id}>
                            <FormLabel>{i18n.t('region')}</FormLabel>
                            <Select
                              value={formData.region_id}
                              onChange={(e) => handleBasicInfoChange('region_id', e.target.value)}
                              placeholder={i18n.t('selectRegion')}
                              isDisabled={regionsLoading}
                            >
                              {regions.map(region => (
                                <option key={region.id} value={region.id}>
                                  {getRegionTranslatedName(region, langCode)}
                                </option>
                              ))}
                            </Select>
                            {errors.region_id && (
                              <Text color="red.500" fontSize="sm" mt={1}>
                                {errors.region_id}
                              </Text>
                            )}
                          </FormControl>

                          <FormControl
                            isInvalid={errors.district_id}
                            isDisabled={!formData.region_id || regionsLoading}
                          >
                            <FormLabel>{i18n.t('district')}</FormLabel>
                            <Select
                              value={formData.district_id}
                              onChange={(e) => handleBasicInfoChange('district_id', e.target.value)}
                              placeholder={i18n.t('selectDistrict')}
                            >
                              {getAvailableDistricts.map(district => (
                                <option key={district.id} value={district.id}>
                                  {getDistrictTranslatedName(district, langCode)}
                                </option>
                              ))}
                            </Select>
                            {errors.district_id && (
                              <Text color="red.500" fontSize="sm" mt={1}>
                                {errors.district_id}
                              </Text>
                            )}
                          </FormControl>
                        </Grid>
                        <FormControl isInvalid={errors[`${langCode}_address`]}>
                          <FormLabel>{i18n.t('address')}</FormLabel>
                          <Textarea
                            value={formData.translations.find(t => t.language === langCode)?.address || ''}
                            onChange={(e) => handleTranslationChange(langCode, 'address', e.target.value)}
                            placeholder={i18n.t('enterAddress')}
                            rows={3}
                          />
                          {errors[`${langCode}_address`] && (
                            <Text color="red.500" fontSize="sm" mt={1}>
                              {errors[`${langCode}_address`]}
                            </Text>
                          )}
                        </FormControl>

                        <FormControl isInvalid={errors[`${langCode}_business_hours`]}>
                          <FormLabel>{i18n.t('businessHours')}</FormLabel>
                          <Input
                            value={formData.translations.find(t => t.language === langCode)?.business_hours || ''}
                            onChange={(e) => handleTranslationChange(langCode, 'business_hours', e.target.value)}
                            placeholder={i18n.t('enterBusinessHours')}
                          />
                          {errors[`${langCode}_business_hours`] && (
                            <Text color="red.500" fontSize="sm" mt={1}>
                              {errors[`${langCode}_business_hours`]}
                            </Text>
                          )}
                        </FormControl>

                        <FormControl>
                          <FormLabel>{i18n.t('remarks')}</FormLabel>
                          <Textarea
                            value={formData.translations.find(t => t.language === langCode)?.remarks || ''}
                            onChange={(e) => handleTranslationChange(langCode, 'remarks', e.target.value)}
                            placeholder={i18n.t('optionalRemarks')}
                            rows={3}
                          />
                        </FormControl>
                      </VStack>
                    </TabPanel>
                  ))}
                </TabPanels>
              </Tabs>

              <Divider />

              <Box px={contentPadding}>
                <Text fontSize="lg" fontWeight="bold" mb={4}>
                  {i18n.t('location')}
                </Text>
                <VStack spacing={4} align="stretch">
                  <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                    <FormControl isInvalid={errors.latitude}>
                      <FormLabel>{i18n.t('latitude')}</FormLabel>
                      <Input
                        type="number"
                        step="0.000001"
                        value={formData.latitude}
                        onChange={(e) => handleBasicInfoChange('latitude', e.target.value)}
                        placeholder="-90 to 90"
                      />
                      {errors.latitude && (
                        <Text color="red.500" fontSize="sm" mt={1}>
                          {errors.latitude}
                        </Text>
                      )}
                    </FormControl>

                    <FormControl isInvalid={errors.longitude}>
                      <FormLabel>{i18n.t('longitude')}</FormLabel>
                      <Input
                        type="number"
                        step="0.000001"
                        value={formData.longitude}
                        onChange={(e) => handleBasicInfoChange('longitude', e.target.value)}
                        placeholder="-180 to 180"
                      />
                      {errors.longitude && (
                        <Text color="red.500" fontSize="sm" mt={1}>
                          {errors.longitude}
                        </Text>
                      )}
                    </FormControl>
                  </Grid>
                </VStack>
              </Box>
            </VStack>
          </form>
        </ModalBody>
        <ModalFooter>
          <Center w="full">
            <Button
              colorScheme="blue"
              size="lg"
              type="submit"
              form="store-form"
              isLoading={isLoading}
              loadingText={initialData ? i18n.t('updating') : i18n.t('creating')}
              width={submitButtonSize}
              bg="blue.500"
              color="white"
              _hover={{ bg: 'blue.600' }}
              _active={{ bg: 'blue.700' }}
              fontSize="md"
              fontWeight="bold"
              boxShadow="lg"
              borderRadius="md"
              transition="all 0.2s"
            >
              {initialData ? i18n.t('update') : i18n.t('create')}
            </Button>
          </Center>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

AddEditStoreModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  initialData: PropTypes.shape({
    id: PropTypes.number,
    district_id: PropTypes.number,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    translations: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      address: PropTypes.string,
      remarks: PropTypes.string,
      language: PropTypes.string.isRequired,
      store_id: PropTypes.number,
      image_url: PropTypes.string,
      created_at: PropTypes.string,
      updated_at: PropTypes.string,
      branch_name: PropTypes.string,
      business_hours: PropTypes.string,
    })),
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
  }),
};

AddEditStoreModal.defaultProps = {
  initialData: null,
};

export default AddEditStoreModal;
// StoresPage.js
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  Box,
  Flex,
  Input,
  VStack,
  InputGroup,
  InputLeftElement,
  useColorModeValue,
  IconButton,
  Spinner,
  Center,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  useDisclosure,
  Badge,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  HStack
} from '@chakra-ui/react';
import { SearchIcon, RepeatIcon, AddIcon, ChevronDownIcon } from '@chakra-ui/icons';
import DataTablePagination from '../../components/base/DataTablePagination';
import DataTable from '../../components/base/DataTableTranslate';
import AddEditStoreModal from './AddEditStoreModal';
import DeleteStoreAlert from './AlertDialogComponent';
import { getDistrictById, getDistrictTranslatedName } from './region-district-service';
import { LANGUAGES } from './translations';
import { format } from 'date-fns';


// Table configuration object that defines column behavior and formatting
const createColumnMap = (currentLanguage) => ({
  index: {
    label: '#',
    sortable: false,
    width: '5%',
    align: 'center',
    formatter: (_, index, pagination) => (pagination.currentPage - 1) * pagination.itemsPerPage + index + 1
  },
  image_url: {
    label: 'Icon',
    sortable: false,
    width: '6%',
    isImage: true,
    align: 'center',
    formatter: (store) => {
      const translation = store.translations.find(t => t.language === currentLanguage) || store.translations[0];
      return {
        url: translation?.image_url || '/placeholder-image.jpg',
        alt: ''
      };
    }
  },
  store_number: {
    label: 'Number',
    sortField: 'id',
    width: '8%',
    align: 'center',
    formatter: (store) => `ST${String(store.id).padStart(5, '0')}`,
    searchable: true
  },
  branch_name: {
    label: 'Store Name',
    sortField: 'stores_translation.branch_name',
    sortable: false,
    width: '15%',
    minWidth: '160px',
    align: 'center',
    formatter: (store) => {
      const translation = store.translations.find(t => t.language === currentLanguage) || store.translations[0];
      return translation?.branch_name || '-';
    },
    searchable: true
  },
  district: {
    label: 'District',
    sortField: 'district_id',
    width: '10%',
    minWidth: '160px',
    align: 'center',
    formatter: (store) => {
      const district = getDistrictById(store.district_id);
      return district ? getDistrictTranslatedName(district, currentLanguage) : '-';
    },
    searchable: true
  },
  address: {
    label: 'Address',
    sortField: 'stores_translation.address',
    sortable: false,
    width: '18%',
    minWidth: '180px',
    align: 'center',
    formatter: (store) => {
      const translation = store.translations.find(t => t.language === currentLanguage) || store.translations[0];
      return translation?.address || '-';
    },
    searchable: true
  },
  business_hours: {
    label: 'Business Hours',
    sortField: 'stores_translation.business_hours',
    sortable: false,
    width: '18%',
    minWidth: '180px',
    align: 'center',
    formatter: (store) => {
      const translation = store.translations.find(t => t.language === currentLanguage) || store.translations[0];
      return translation?.business_hours || '-';
    },
    searchable: true
  },
  // updated_at: {
  //   label: 'Last Modified Time',
  //   sortField: 'updated_at',
  //   width: '15%',
  //   align: 'center',
  //   formatter: (store) => store.updated_at
  //     ? format(new Date(store.updated_at), 'yyyy-MM-dd HH:mm')
  //     : '-'
  // },
  is_active: {
    label: 'Status',
    sortField: 'is_active',
    sortable: false,
    width: '%',
    align: 'center',
    isStatus: true,
    formatter: (store) => ({
      value: true,
      label: 'Active',
      colorScheme: 'green'
    })
  }
});

const StoresPage = () => {
  // State management
  const [stores, setStores] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [filterConfig, setFilterConfig] = useState({ text: '', field: null, value: '' });
  const [sortConfig, setSortConfig] = useState({ field: 'id', direction: 'asc' });
  const [pagination, setPagination] = useState({ currentPage: 1, itemsPerPage: 10 });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [selectedStore, setSelectedStore] = useState(null);
  const [currentLanguage, setCurrentLanguage] = useState(LANGUAGES.ENGLISH);

  // Modal controls
  const {
    isOpen: isCreateModalOpen,
    onOpen: onCreateModalOpen,
    onClose: onCreateModalClose
  } = useDisclosure();

  const {
    isOpen: isUpdateModalOpen,
    onOpen: onUpdateModalOpen,
    onClose: onUpdateModalClose
  } = useDisclosure();

  const {
    isOpen: isAlertDialogOpen,
    onOpen: onAlertDialogOpen,
    onClose: onAlertDialogClose
  } = useDisclosure();

  // Memoized column map
  const columnMap = useMemo(() => createColumnMap(currentLanguage), [currentLanguage]);

  // Process data for table display
  const processDataForTable = useCallback((storesData) => {
    return storesData.map((store, index) => {
      // Process each field according to columnMap
      const processedData = {};

      Object.keys(columnMap).forEach(key => {
        // Get formatted value using the column's formatter
        const formattedValue = columnMap[key].formatter?.(
          store,
          index,
          pagination,
          currentLanguage
        );

        // Store the formatted value
        processedData[key] = formattedValue;
      });

      // Add id for reference but don't render it
      processedData._id = store.id;

      return processedData;
    });
  }, [columnMap, pagination, currentLanguage]);

  // Data fetching
  const fetchStores = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const searchFields = Object.keys(columnMap)
        .filter(key => columnMap[key].searchable)
        .map(key => columnMap[key].sortField);

      const options = {
        joins: [{
          table: 'stores_translation',
          fields: '*'
        }],
        filterConfig: {
          text: searchText,
          field: filterConfig.field,
          value: filterConfig.value
        },
        sortConfig,
        pagination,
        searchFields
      };

      const { data } = await global.genericapi.fetchRecordsNew('stores', options);
      const { data: total } = await global.genericapi.fetchRecordsNew('stores');
      setStores(data);
      setTotalCount(total.length);
    } catch (error) {
      console.error('Error fetching stores:', error);
      setError(error.message || 'Failed to fetch stores');
    } finally {
      setIsLoading(false);
    }
  }, [filterConfig, sortConfig, pagination, searchText, columnMap]);

  // Initial data fetch
  useEffect(() => {
    fetchStores();
  }, [fetchStores]);

  // Event handlers
  // Handlers
  const handleSort = useCallback((field) => {
    setSortConfig(prevConfig => ({
      field,
      direction: prevConfig.field === field && prevConfig.direction === 'asc' ? 'desc' : 'asc'
    }));
  }, []);

  const handleUpdateClick = useCallback((item) => {
    // Fetch the original store data using the _id
    const originalStore = stores.find(store => store.id === item._id);
    setSelectedStore(originalStore);
    // console.log('originalStore');
    onUpdateModalOpen();
  }, [onUpdateModalOpen, stores]);

  const handleDeleteClick = useCallback((item) => {
    const originalStore = stores.find(store => store.id === item._id);
    setSelectedStore(originalStore);
    // console.log('originalStore');
    onAlertDialogOpen();
  }, [onAlertDialogOpen, stores]);

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      fetchStores();
    }
  };

  const handlePageChange = useCallback((newPage) => {
    setPagination(prev => ({ ...prev, currentPage: newPage }));
  }, []);

  const handleItemsPerPageChange = useCallback((newItemsPerPage) => {
    setPagination(prev => ({ ...prev, itemsPerPage: newItemsPerPage, currentPage: 1 }));
  }, []);

  // Theme values
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');

  return (
    <Box width="100%" maxWidth="100vw" overflowX="hidden">
      {error && (
        <Alert status="error" mb={4}>
          <AlertIcon />
          <AlertTitle mr={2}>Error!</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}

      <Box
        bg={bgColor}
        // borderRadius="lg"
        // boxShadow="md"
        p={[3, 4, 5]}
        // borderWidth={1}
        // borderColor={borderColor}
        width="100%"
      >
        <VStack spacing={[3, 4, 5]} align="stretch">
          <Flex
            direction={["column", "row"]}
            justifyContent="space-between"
            alignItems={["flex-start", "center"]}
            wrap="wrap"
            gap={4}
          >
            <InputGroup maxW={["full", "300px"]}>
              {/* <InputLeftElement pointerEvents="none">
                <SearchIcon color="gray.300" />
              </InputLeftElement>
              <Input
                placeholder="Search stores..."
                value={searchText}
                onChange={handleSearchChange}
                onKeyDown={handleKeyDown}
                borderRadius="full"
              /> */}
            </InputGroup>

            <HStack spacing={4} mt={[0, 0]} wrap="wrap">
              <IconButton variant='action' ml="auto" icon={<RepeatIcon />} aria-label="Refresh" onClick={fetchStores} />
              <IconButton
                variant='action' ml="auto"
                icon={<AddIcon />}
                aria-label="Add"
                onClick={onCreateModalOpen}
              />
            </HStack>
          </Flex>

          {isLoading ? (
            <Center py={10}>
              <Spinner size="xl" color="blue.500" thickness="4px" />
            </Center>
          ) : (
            <DataTable
              data={processDataForTable(stores)}
              columnMap={columnMap}
              sortField={sortConfig.field}
              sortDirection={sortConfig.direction}
              onSort={handleSort}
              onEdit={handleUpdateClick}
              onDelete={handleDeleteClick}
              onRefresh={fetchStores}
            />
          )}

          {totalCount < pagination.itemsPerPage ? <></> : (
            <DataTablePagination
              currentPage={pagination.currentPage}
              totalPages={Math.ceil(totalCount / pagination.itemsPerPage)}
              onPageChange={handlePageChange}
              itemsPerPage={pagination.itemsPerPage}
              setItemsPerPage={handleItemsPerPageChange}
              itemsPerPageOptions={[5, 10, 20, 50]}
            />
          )}

        </VStack>
      </Box>

      <AddEditStoreModal
        isOpen={isCreateModalOpen}
        onClose={onCreateModalClose}
        onComplete={fetchStores}
      />

      <AddEditStoreModal
        isOpen={isUpdateModalOpen}
        initialData={selectedStore}
        onClose={onUpdateModalClose}
        onComplete={fetchStores}
      />

      <DeleteStoreAlert
        isOpen={isAlertDialogOpen}
        onClose={onAlertDialogClose}
        onComplete={fetchStores}
        store={selectedStore}
        title="Delete Store"
        message="Are you sure you want to delete this store? This action cannot be undone."
      />
    </Box>
  );
};

export default StoresPage;
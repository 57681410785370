// translations.js
export const translationConfig = {
    defaultLanguage: 'en',
    englishOnly: true
};

export const translations = {
    en: {
        // Modal titles and actions
        editUser: 'Edit User',
        createUser: 'Create New User',
        update: 'Submit',
        create: 'Submit',
        cancel: 'Cancel',
        updating: 'Updating...',
        creating: 'Creating...',
        save: 'Save Changes',

        // Form Labels
        email: 'Email',
        password: 'Password',
        fullName: 'Full Name',
        department: 'Department',
        activeStatus: 'Active Status',
        role: 'Role Type',
        lastSignIn: 'Last Sign In',
        createdAt: 'Created At',
        updatedAt: 'Updated At',
        roleDescription: 'Role Description: ',

        // Placeholders
        enterEmail: 'Enter email address',
        enterPassword: 'Enter password',
        enterFullName: 'Enter full name',
        selectDepartment: 'Select department',
        selectRole: 'Select Role Type',

        // Department Options
        IT: 'IT Department',
        HR: 'Human Resources',
        FINANCE: 'Finance',
        MARKETING: 'Marketing',
        OPERATIONS: 'Operations',

        // Status
        active: 'Active',
        inactive: 'Inactive',
        cannotDeactivateOwnAccount: 'You cannot deactivate your own account',

        // Success Messages
        userCreatedSuccess: 'User created successfully',
        userUpdatedSuccess: 'User updated successfully',
        operationSuccess: 'Operation completed successfully',
        changesSaved: 'Changes saved successfully',

        // Error Messages
        validationError: 'Please check all required fields',
        genericError: 'An error occurred. Please try again.',
        networkError: 'Network error. Please check your connection.',
        operationFailed: 'Operation failed',
        unauthorized: 'Unauthorized access',
        serverError: 'Server error occurred',

        // Validation Messages
        required: 'This field is required',
        invalidEmail: 'Invalid email format',
        passwordRequired: 'Password is required for new users',
        invalidFormat: 'Invalid format',
        roleRequired: 'Role type is required',
        departmentRequired: 'Department is required',

        // Confirmation Messages
        confirmDelete: 'Are you sure you want to delete this user?',
        confirmDeactivate: 'Are you sure you want to deactivate this user?',
        confirmActivate: 'Are you sure you want to activate this user?',

        // Button Labels
        submit: 'Submit',
        confirm: 'Confirm',
        delete: 'Delete',
        deactivate: 'Deactivate',
        activate: 'Activate',
        close: 'Close',
        back: 'Back'
    },

    'zh-Hant': {
        // Modal titles and actions
        editUser: '編輯用戶',
        createUser: '創建新用戶',
        update: '提交',
        create: '提交',
        cancel: '取消',
        updating: '更新中...',
        creating: '創建中...',
        save: '保存更改',

        // Form Labels
        email: '電子郵件',
        password: '密碼',
        fullName: '全名',
        department: '部門',
        activeStatus: '狀態',
        role: '角色類型',
        lastSignIn: '最後登入',
        createdAt: '創建時間',
        updatedAt: '更新時間',
        roleDescription: '角色描述：',

        // Placeholders
        enterEmail: '輸入電子郵件地址',
        enterPassword: '輸入密碼',
        enterFullName: '輸入全名',
        selectDepartment: '選擇部門',
        selectRole: '選擇角色類型',

        // Department Options
        IT: 'IT部門',
        HR: '人力資源',
        FINANCE: '財務',
        MARKETING: '市場營銷',
        OPERATIONS: '運營',

        // Status
        active: '啟用',
        inactive: '停用',
        cannotDeactivateOwnAccount: '您無法停用自己的帳戶',

        // Success Messages
        userCreatedSuccess: '用戶創建成功',
        userUpdatedSuccess: '用戶更新成功',
        operationSuccess: '操作完成',
        changesSaved: '更改已保存',

        // Error Messages
        validationError: '請檢查所有必填欄位',
        genericError: '發生錯誤，請重試',
        networkError: '網絡錯誤，請檢查連接',
        operationFailed: '操作失敗',
        unauthorized: '未經授權的訪問',
        serverError: '服務器錯誤',

        // Validation Messages
        required: '此欄位為必填',
        invalidEmail: '無效的電子郵件格式',
        passwordRequired: '新用戶需要設置密碼',
        invalidFormat: '格式無效',
        roleRequired: '角色類型為必選',
        departmentRequired: '部門為必選',

        // Confirmation Messages
        confirmDelete: '確定要刪除此用戶嗎？',
        confirmDeactivate: '確定要停用此用戶嗎？',
        confirmActivate: '確定要啟用此用戶嗎？',

        // Button Labels
        submit: '提交',
        confirm: '確認',
        delete: '刪除',
        deactivate: '停用',
        activate: '啟用',
        close: '關閉',
        back: '返回'
    },

    'zh-Hans': {
        // Modal titles and actions
        editUser: '编辑用户',
        createUser: '创建新用户',
        update: '提交',
        create: '提交',
        cancel: '取消',
        updating: '更新中...',
        creating: '创建中...',
        save: '保存更改',

        // Form Labels
        email: '电子邮件',
        password: '密码',
        fullName: '全名',
        department: '部门',
        activeStatus: '状态',
        role: '角色类型',
        lastSignIn: '最后登录',
        createdAt: '创建时间',
        updatedAt: '更新时间',
        roleDescription: '角色描述：',

        // Placeholders
        enterEmail: '输入电子邮件地址',
        enterPassword: '输入密码',
        enterFullName: '输入全名',
        selectDepartment: '选择部门',
        selectRole: '选择角色类型',

        // Department Options
        IT: 'IT部门',
        HR: '人力资源',
        FINANCE: '财务',
        MARKETING: '市场营销',
        OPERATIONS: '运营',

        // Status
        active: '启用',
        inactive: '停用',
        cannotDeactivateOwnAccount: '您无法停用自己的账户',

        // Success Messages
        userCreatedSuccess: '用户创建成功',
        userUpdatedSuccess: '用户更新成功',
        operationSuccess: '操作完成',
        changesSaved: '更改已保存',

        // Error Messages
        validationError: '请检查所有必填字段',
        genericError: '发生错误，请重试',
        networkError: '网络错误，请检查连接',
        operationFailed: '操作失败',
        unauthorized: '未经授权的访问',
        serverError: '服务器错误',

        // Validation Messages
        required: '此字段为必填',
        invalidEmail: '无效的电子邮件格式',
        passwordRequired: '新用户需要设置密码',
        invalidFormat: '格式无效',
        roleRequired: '角色类型为必选',
        departmentRequired: '部门为必选',

        // Confirmation Messages
        confirmDelete: '确定要删除此用户吗？',
        confirmDeactivate: '确定要停用此用户吗？',
        confirmActivate: '确定要启用此用户吗？',

        // Button Labels
        submit: '提交',
        confirm: '确认',
        delete: '删除',
        deactivate: '停用',
        activate: '启用',
        close: '关闭',
        back: '返回'
    }
};

export const i18n = {
    t(key, lang = translationConfig.defaultLanguage) {
        if (translationConfig.englishOnly) {
            return translations.en[key] || key;
        }

        const translation = translations[lang]?.[key];
        if (!translation) {
            console.warn(`Translation missing for key: ${key} in language: ${lang}`);
            return translations.en[key] || key;
        }

        return translation;
    },

    getLanguageLabel(code, displayLang = translationConfig.defaultLanguage) {
        if (translationConfig.englishOnly) {
            return LANGUAGE_LABELS.en[code] || code;
        }
        return LANGUAGE_LABELS[displayLang]?.[code] || LANGUAGE_LABELS.en[code] || code;
    },

    setLanguage(lang) {
        if (translations[lang]) {
            translationConfig.defaultLanguage = lang;
        } else {
            console.warn(`Language ${lang} not supported, falling back to en`);
            translationConfig.defaultLanguage = 'en';
        }
    },

    getAvailableLanguages() {
        return Object.keys(translations);
    },

    setEnglishOnly(englishOnly) {
        translationConfig.englishOnly = englishOnly;
    },

    isLanguageSupported(lang) {
        return lang in translations;
    },

    getCurrentLanguage() {
        return translationConfig.defaultLanguage;
    },

    isEnglishOnly() {
        return translationConfig.englishOnly;
    },

    tMultiple(keys, lang = translationConfig.defaultLanguage) {
        return keys.reduce((acc, key) => ({
            ...acc,
            [key]: this.t(key, lang)
        }), {});
    }
};

export const LANGUAGES = {
    ENGLISH: 'en',
    TRADITIONAL_CHINESE: 'zh-Hant',
    SIMPLIFIED_CHINESE: 'zh-Hans'
};

export const LANGUAGE_LABELS = {
    en: {
        'en': 'English',
        'zh-Hant': 'Traditional Chinese',
        'zh-Hans': 'Simplified Chinese'
    },
    'zh-Hant': {
        'en': '英文',
        'zh-Hant': '繁體中文',
        'zh-Hans': '简体中文'
    },
    'zh-Hans': {
        'en': '英文',
        'zh-Hant': '繁体中文',
        'zh-Hans': '简体中文'
    }
};

export default i18n;
import React, { useState, useCallback } from 'react';
import { Box, VStack, Text, Image } from '@chakra-ui/react';

export const DragNDrop = ({ onFileUpload, currentImage }) => {
  const [isDragging, setIsDragging] = useState(false);

  const handleDrag = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDragIn = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  }, []);

  const handleDragOut = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  }, []);

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    
    const files = e.dataTransfer.files;
    if (files && files.length > 0) {
      onFileUpload(files[0]);
    }
  }, [onFileUpload]);

  return (
    <VStack spacing={4} width="200px">
      <Box
        width="200px"
        height="150px"
        border="2px dashed"
        borderColor={isDragging ? "blue.400" : "gray.200"}
        borderRadius="md"
        display="flex"
        alignItems="center"
        justifyContent="center"
        bg={isDragging ? "blue.50" : "gray.50"}
        transition="all 0.2s"
        onDragEnter={handleDragIn}
        onDragLeave={handleDragOut}
        onDragOver={handleDrag}
        onDrop={handleDrop}
        cursor="pointer"
        onClick={() => {
          const input = document.createElement('input');
          input.type = 'file';
          input.accept = 'image/*';
          input.onchange = (e) => {
            if (e.target.files?.[0]) {
              onFileUpload(e.target.files[0]);
            }
          };
          input.click();
        }}
      >
        {currentImage ? (
          <Image
            src={currentImage}
            alt="Preview"
            maxH="130px"
            maxW="180px"
            objectFit="contain"
          />
        ) : (
          <Text color="gray.500" fontSize="sm" textAlign="center" p={2}>
            {/* Drag image here<br/>or click to upload */}
            Click to upload
          </Text>
        )}
      </Box>
    </VStack>
  );
};

export default DragNDrop;
import React, { useState, useEffect } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';

import TableOperations from './utils/TableOperations';

const AlertDialogComponent = ({ isOpen, onClose, data, onComplete, title, message, tableName }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const cancelRef = React.useRef();

  // Reset states when dialog reopens
  useEffect(() => {
    if (isOpen) {
      setIsLoading(false);
      setError(null);
    }
  }, [isOpen]);

  const handleDelete = async () => {
    setIsLoading(true);
    setError(null);
    const deleteOperation = TableOperations[tableName] || TableOperations.default;

    try {
      await deleteOperation.delete(data);
      onClose();
      onComplete();
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle close with cleanup
  const handleClose = () => {
    setIsLoading(false);
    setError(null);
    onClose();
  };

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={handleClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>

          <AlertDialogBody>
            {message}
            {error && (
              <Alert status="error" mt={4}>
                <AlertIcon />
                {error}
              </Alert>
            )}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={handleClose}>
              Cancel
            </Button>
            <Button 
              colorScheme="red" 
              onClick={handleDelete} 
              ml={3} 
              isLoading={isLoading}
              loadingText="Deleting..."
            >
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default AlertDialogComponent;
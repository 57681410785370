// translations.js

/**
 * @typedef {'en' | 'zh-Hant' | 'zh-Hans'} Language
 */

export const translationConfig = {
  defaultLanguage: 'en',
  englishOnly: true
};

/**
* @type {Record<Language, Record<string, string>>}
*/
export const translations = {
  en: {
    // Column Labels
    number: 'Number',
    title: 'Title',
    message: 'Message',
    image: 'Image',
    targetType: 'Platform',
    topic: 'Topic',
    status: 'Status',
    scheduledTime: 'Scheduled Time',
    sentTime: 'Sent Time',
    deepLink: 'Deep Link',

    // Status Options
    draft: 'Draft',
    scheduled: 'Scheduled',
    sent: 'Sent',
    failed: 'Failed',
    canceled: 'Canceled',

    // Platform Types
    ios: 'iOS',
    android: 'Android',
    all: 'All',

    // Buttons & Actions
    sendNow: 'Send Now',
    resend: 'Resend',
    refresh: 'Refresh',
    add: 'Add',
    delete: 'Delete',
    edit: 'Edit',
    cancel: 'Cancel',
    create: 'Create',
    update: 'Update',
    creating: 'Creating...',
    updating: 'Updating...',

    // Messages
    error: 'Error',
    success: 'Success',
    operationSuccess: 'Operation completed successfully',
    fetchError: 'Failed to fetch notifications',
    notificationSent: 'Notification sent successfully',
    sendError: 'Failed to send notification',
    deletePushNotification: 'Delete Push Notification',
    deleteConfirmMessage: 'Are you sure you want to delete this push notification? This action cannot be undone.',
    notificationCreated: 'Notification created successfully',
    notificationUpdated: 'Notification updated successfully',
    genericError: 'An error occurred. Please try again.',
    networkError: 'Network error. Please check your connection.',

    // Form Titles & Labels
    createNotification: 'Create New Notification',
    editNotification: 'Edit Notification',
    notificationTitle: 'Title',
    notificationMessage: 'Message',
    notificationSettings: 'Notification Settings',
    notificationImage: 'Notification Image',
    targetPlatform: 'Target Platform',
    fcmTokens: 'FCM Tokens',
    scheduleTime: 'Schedule Time',

    // Placeholders
    enterTitle: 'Enter notification title',
    enterMessage: 'Enter notification message',
    enterDeepLink: 'Enter deep link URL',
    dragImageHere: 'Drag image here or click to upload',

    // Image Upload
    invalidImageType: 'Please upload a valid image file (JPEG, PNG, or GIF)',
    imageSizeLimit: 'Image size should be less than 5MB',
    uploadSuccess: 'Image uploaded successfully',
    uploadError: 'Failed to upload image',

    // Validation Messages
    required: 'Required',
    scheduledTimeMustBeFuture: 'Scheduled time must be in future',
    scheduledTimeRequired: 'Scheduled time is required',
    titleRequired: 'Title is required',
    messageRequired: 'Message is required',
    targetRequired: 'Target platform is required',
    checkRequiredFields: 'Please check all required fields',
    onlyAvailableForScheduled: 'Time selection only available for scheduled status',
    selectScheduledTime: 'Select scheduled time',
  },

  'zh-Hant': {
    // Column Labels
    number: '編號',
    title: '標題',
    message: '訊息',
    image: '圖片',
    targetType: '目標類型',
    topic: '主題',
    status: '狀態',
    scheduledTime: '排程時間',
    sentTime: '發送時間',
    deepLink: '深度連結',

    // Status Options
    draft: '草稿',
    scheduled: '已排程',
    sent: '已發送',
    failed: '失敗',
    canceled: '已取消',

    // Platform Types
    ios: 'iOS',
    android: 'Android',
    all: '全部',

    // Buttons & Actions
    sendNow: '立即發送',
    resend: '重新發送',
    refresh: '重新整理',
    add: '新增',
    delete: '刪除',
    edit: '編輯',
    cancel: '取消',
    create: '建立',
    update: '更新',
    creating: '建立中...',
    updating: '更新中...',

    // Messages
    error: '錯誤',
    success: '成功',
    operationSuccess: '操作成功完成',
    fetchError: '獲取通知失敗',
    notificationSent: '通知發送成功',
    sendError: '發送通知失敗',
    deletePushNotification: '刪除推送通知',
    deleteConfirmMessage: '您確定要刪除此推送通知嗎？此操作無法撤銷。',
    notificationCreated: '通知建立成功',
    notificationUpdated: '通知更新成功',
    genericError: '發生錯誤，請重試',
    networkError: '網路錯誤，請檢查連線',

    // Form Titles & Labels
    createNotification: '建立新通知',
    editNotification: '編輯通知',
    notificationTitle: '標題',
    notificationMessage: '訊息',
    notificationSettings: '通知設定',
    notificationImage: '通知圖片',
    targetPlatform: '目標平台',
    fcmTokens: 'FCM 代碼',
    scheduleTime: '排程時間',

    // Placeholders
    enterTitle: '輸入通知標題',
    enterMessage: '輸入通知訊息',
    enterDeepLink: '輸入深度連結網址',
    dragImageHere: '拖曳圖片至此或點擊上傳',

    // Image Upload
    invalidImageType: '請上傳有效的圖片檔案（JPEG、PNG 或 GIF）',
    imageSizeLimit: '圖片大小應小於 5MB',
    uploadSuccess: '圖片上傳成功',
    uploadError: '圖片上傳失敗',

    // Validation Messages
    required: '必填',
    scheduledTimeMustBeFuture: '排程時間必須是未來時間',
    scheduledTimeRequired: '必須設定排程時間',
    titleRequired: '必須輸入標題',
    messageRequired: '必須輸入訊息',
    targetRequired: '必須選擇目標平台',
    checkRequiredFields: '請檢查所有必填欄位',
    onlyAvailableForScheduled: '僅排程狀態可選擇時間',
    selectScheduledTime: '選擇排程時間',
  },

  'zh-Hans': {
    // Column Labels
    number: '编号',
    title: '标题',
    message: '消息',
    image: '图片',
    targetType: '目标类型',
    topic: '主题',
    status: '状态',
    scheduledTime: '计划时间',
    sentTime: '发送时间',
    deepLink: '深度链接',

    // Status Options
    draft: '草稿',
    scheduled: '已计划',
    sent: '已发送',
    failed: '失败',
    canceled: '已取消',

    // Platform Types
    ios: 'iOS',
    android: 'Android',
    all: '全部',

    // Buttons & Actions
    sendNow: '立即发送',
    resend: '重新发送',
    refresh: '刷新',
    add: '添加',
    delete: '删除',
    edit: '编辑',
    cancel: '取消',
    create: '创建',
    update: '更新',
    creating: '创建中...',
    updating: '更新中...',

    // Messages
    error: '错误',
    success: '成功',
    operationSuccess: '操作成功完成',
    fetchError: '获取通知失败',
    notificationSent: '通知发送成功',
    sendError: '发送通知失败',
    deletePushNotification: '删除推送通知',
    deleteConfirmMessage: '您确定要删除此推送通知吗？此操作无法撤销。',
    notificationCreated: '通知创建成功',
    notificationUpdated: '通知更新成功',
    genericError: '发生错误，请重试',
    networkError: '网络错误，请检查连接',

    // Form Titles & Labels
    createNotification: '创建新通知',
    editNotification: '编辑通知',
    notificationTitle: '标题',
    notificationMessage: '消息',
    notificationSettings: '通知设置',
    notificationImage: '通知图片',
    targetPlatform: '目标平台',
    fcmTokens: 'FCM 令牌',
    scheduleTime: '计划时间',

    // Placeholders
    enterTitle: '输入通知标题',
    enterMessage: '输入通知消息',
    enterDeepLink: '输入深度链接网址',
    dragImageHere: '拖拽图片至此或点击上传',

    // Image Upload
    invalidImageType: '请上传有效的图片文件（JPEG、PNG 或 GIF）',
    imageSizeLimit: '图片大小应小于 5MB',
    uploadSuccess: '图片上传成功',
    uploadError: '图片上传失败',

    // Validation Messages
    required: '必填',
    scheduledTimeMustBeFuture: '计划时间必须是未来时间',
    scheduledTimeRequired: '必须设定计划时间',
    titleRequired: '必须输入标题',
    messageRequired: '必须输入消息',
    targetRequired: '必须选择目标平台',
    checkRequiredFields: '请检查所有必填字段',
    onlyAvailableForScheduled: '仅计划状态可选择时间',
    selectScheduledTime: '选择计划时间',
  }
};

export const LANGUAGES = {
  ENGLISH: 'en',
  TRADITIONAL_CHINESE: 'zh-Hant',
  SIMPLIFIED_CHINESE: 'zh-Hans'
};

export const LANGUAGE_LABELS = {
  en: {
    'en': 'English',
    'zh-Hant': 'Traditional Chinese',
    'zh-Hans': 'Simplified Chinese'
  },
  'zh-Hant': {
    'en': 'English',
    'zh-Hant': '繁體中文',
    'zh-Hans': '简体中文'
  },
  'zh-Hans': {
    'en': 'English',
    'zh-Hant': '繁体中文',
    'zh-Hans': '简体中文'
  }
};

export const i18n = {
  t(key, lang = translationConfig.defaultLanguage) {
    if (translationConfig.englishOnly) {
      return translations.en[key] || key;
    }

    const translation = translations[lang]?.[key];
    if (!translation) {
      console.warn(`Translation missing for key: ${key} in language: ${lang}`);
      return translations.en[key] || key;
    }

    return translation;
  },

  getLanguageLabel(code, displayLang = translationConfig.defaultLanguage) {
    if (translationConfig.englishOnly) {
      return LANGUAGE_LABELS.en[code] || code;
    }
    return LANGUAGE_LABELS[displayLang]?.[code] || LANGUAGE_LABELS.en[code] || code;
  },

  setLanguage(lang) {
    if (translations[lang]) {
      translationConfig.defaultLanguage = lang;
    } else {
      console.warn(`Language ${lang} not supported, falling back to en`);
      translationConfig.defaultLanguage = 'en';
    }
  },

  getAvailableLanguages() {
    return Object.keys(translations);
  },

  setEnglishOnly(englishOnly) {
    translationConfig.englishOnly = englishOnly;
  },

  isLanguageSupported(lang) {
    return lang in translations;
  },

  getCurrentLanguage() {
    return translationConfig.defaultLanguage;
  },

  isEnglishOnly() {
    return translationConfig.englishOnly;
  },

  tMultiple(keys, lang = translationConfig.defaultLanguage) {
    return keys.reduce((acc, key) => ({
      ...acc,
      [key]: this.t(key, lang)
    }), {});
  }
};

export default i18n;
// AddEditUserModal.js
import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  useBreakpointValue,
  Center,
  Switch,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Select,
  InputGroup,
  InputRightElement,
  IconButton,
  Tooltip,
  Spinner
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import PropTypes from 'prop-types';
import { post } from '../../../../api/request';
import { i18n } from './translations';
import JSEncrypt from 'jsencrypt';

// Add this constant for the public key
const PUBLIC_KEY = `-----BEGIN PUBLIC KEY-----
MIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQDgnRswswMMMQcb
Fdc6upa0wnsMDBe4TYfJLuVsETXWWzczYTbyrTgixLtZNmYIQT1WJkc9g0FPlrf5
+NEbG48za/4qsKw46SUbDkFm3OBPafOOMWdKPHQ8nDUNhOomzOpDnuewsCMFt3Pi
p+wtwPtW3yWoC7+ZgyqN7RkjAO0g4i4XxkztS+jwjdXQ7rNWEmu/kZu8CNtL3jyN
bn2HAZpgSwBCo4raa1JZ6tm7/PDGvSD9RGYvNpy4HXLfad+Czr3G7cgSGzGIj6UQ
G8CgJvqrkD4SIBRxdM5VEyji1uvF9LPMv0V0eqEfc+f9CVdBSE195/nud8fT20wg
1CALEQ8JAgMBAAECggEAB/ivaLmrraS2S7jpVeteEp111DpAI8F1pJdSW1TbiCdd
1MgaFP/+hmZcv0hp09hr4rkyTU2xlU9kKV4SHsAKQ6ENKMrzG8RzdYu6mLnVSKA6
HXVlpOnzFp0VGW0aicS7OinjL1866PanKVe8Dy4ihWVfPuDm3H3+FkIgQqCjaceo
JBZSNJa2rAi5BnYC14tM9zyAU+hL+3jAqT9Bc0UD8WCx7MQ6YcC85vaapyYIAgw0
/K6F1Vx1eIBLWGS2Iw3R86Tlzc2E5Z4mHZv5KM3bjbj9mWhk0a3E/DYF2M25rZIW
UfMVzvNlW6DE/8vrmYSKULo3cQFFijpvZZmfGTx9kQKBgQD2MNlbmGqy/ohgitgn
IoBZTxPmi52+oD+bhfsGUyCIHhR0xwSAGax6KSiiNjMo6RqereYsgQaHqOMdavbM
Yxhj255NHPWI4JsnAMtTyO6b1hlrhnrZ7NLr0k1l7UnN5RfwWusmCcQLDcxH8ZBH
YP+xTq8ohVvpk3WuwIZPVeEg+QKBgQDpkCuNRxNKJI2hgAisas7eY0ln0p1Xf6nl
a+VAlF6iI/wYDihOPUIi47Krx5Zj4DKb97oS5Rem/26FpKb3sC3FuBddtoZ0JzpK
N8DwLI+SfL76A+9HMfoLIJs9/aynFZ+lw5czRBzRQR7aokzCEPHo/rFrCdnIRA8y
90V4OvLykQKBgQD03GsjdfoR46WC2DXa3q7npQY3FIAlnD8HkX+1r7I4kCpqZ5Or
UV3B9vPqOpwxwX9veajodMAo56pUlRG5AYq9ry7Ipdf0duj6JwkUYVIpfw2za4L/
A1RZpsbCbPsuJQDlV5hwMeBh2ixnFfUZeXl3QbpnRBTBwZ+X2vb7K4CKCQKBgQCC
vi+DADxuDpQxyYgRbCCtIIsg0Sc+qbojMQi949Atl1Mw7Avn8CarfSaxPiKTehca
c8v3c8KPuwDVUtwcXsTgnzKFRP1y29qnXRpl16vu5mHJdpZlFjbQL8p3Ix+Y0GPz
2M805XX0NS3eWWf6Yp3oPVp3fqYokuCnOP/sU6NX4QKBgEZ4XliV0ojrnVgLH5GB
S1OvARqg9gG3BH20/XUnuFWdsPoTHhMk8iB0nYcg+BSjikT6tFFSAbvOaNLvtNfI
wQHkEF+zdpqX72aWOAwNcvG+QCuSRF3pU1d0IAlKN96X14dJKWd0+eSaA7C9poPl
XnDQT5DDKgVrlhX6ohJjU3JZ
-----END PUBLIC KEY-----`;

const DEPARTMENT_OPTIONS = [
  { value: 'IT', label: i18n.t('IT') },
  { value: 'HR', label: i18n.t('HR') },
  { value: 'FINANCE', label: i18n.t('FINANCE') },
  { value: 'MARKETING', label: i18n.t('MARKETING') },
  { value: 'OPERATIONS', label: i18n.t('OPERATIONS') }
];

const INITIAL_FORM_STATE = {
  email: '',
  password: '',
  full_name: '',
  department: '',
  is_active: true,
  role: ''
};

const AddEditUserModal = ({ isOpen, onClose, onComplete, initialData = null }) => {
  const modalSize = useBreakpointValue({
    base: "90%",
    md: "70%",
    lg: "50%",
    xl: "40%"
  });

  const modalMaxWidth = useBreakpointValue({
    base: "90%",
    md: "70%",
    lg: "50%",
    xl: "40%"
  });

  const contentPadding = useBreakpointValue({
    base: "1rem",
    md: "1.5rem",
    lg: "2rem"
  });

  const submitButtonSize = useBreakpointValue({
    base: "80%",
    md: "70%",
    lg: "60%",
    xl: "50%"
  });

  const [formData, setFormData] = useState(INITIAL_FORM_STATE);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [alertInfo, setAlertInfo] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [roles, setRoles] = useState([]);
  const [isLoadingRoles, setIsLoadingRoles] = useState(false);

  const currentUserId = localStorage.getItem('userId');
  // Add this computed value
  const isCurrentUser = initialData?.id === currentUserId;

  useEffect(() => {
    const fetchRoles = async () => {
      if (isOpen) {
        setIsLoadingRoles(true);
        try {
          const { data } = await global.genericapi.fetchRecordsNew('cms_roles');
          setRoles(data);

          // Set default viewer role for new users
          if (!initialData) {
            const viewerRole = data.find(role =>
              role.name.toLowerCase() === 'viewer'
            );
            if (viewerRole) {
              setFormData(prev => ({
                ...prev,
                role: viewerRole.id.toString()
              }));
            }
          }
        } catch (error) {
          console.error('Error fetching roles:', error);
          showAlert('error', 'Error', 'Failed to load roles');
        } finally {
          setIsLoadingRoles(false);
        }
      }
    };

    fetchRoles();
  }, [isOpen, initialData]);

  useEffect(() => {
    if (isOpen) {
      if (initialData) {
        const roleId = initialData.cms_user_roles?.[0]?.cms_roles?.id;
        setFormData({
          email: initialData.email || '',
          password: '',
          full_name: initialData.full_name || '',
          department: initialData.department || '',
          is_active: initialData.is_active ?? true,
          role: roleId ? roleId.toString() : ''
        });
      } else {
        setFormData(INITIAL_FORM_STATE);
      }
      setErrors({});
      setAlertInfo(null);
      setShowPassword(false);
    }
  }, [isOpen, initialData]);

  const showAlert = (status, title, description) => {
    setAlertInfo({ status, title, description });
    setTimeout(() => setAlertInfo(null), 3000);
  };

  const handleClose = () => {
    setFormData(INITIAL_FORM_STATE);
    setErrors({});
    setAlertInfo(null);
    setShowPassword(false);
    onClose();
  };

  const handleChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
    setErrors(prev => ({ ...prev, [field]: '' }));
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.email?.trim()) {
      newErrors.email = i18n.t('required');
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = i18n.t('invalidEmail');
    }

    if (!initialData && !formData.password?.trim()) {
      newErrors.password = i18n.t('passwordRequired');
    }

    if (!formData.full_name?.trim()) {
      newErrors.full_name = i18n.t('required');
    }

    // if (!formData.department) {
    //     newErrors.department = i18n.t('required');
    // }

    if (!formData.role) {
      newErrors.role = i18n.t('roleRequired');
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const encryptPassword = (password) => {
    try {
      const encrypt = new JSEncrypt();
      encrypt.setPublicKey(PUBLIC_KEY);
      return encrypt.encrypt(password);
    } catch (error) {
      console.error('Error encrypting password:', error);
      throw new Error('Failed to encrypt password');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // const encryptedPassword = encryptPassword(formData.password);
    // console.log('encryptedPassword', encryptedPassword);
    // return;

    if (!validateForm()) {
      showAlert('error', 'Validation Error', i18n.t('validationError'));
      return;
    }

    setIsLoading(true);

    try {
      const userData = {
        full_name: formData.full_name,
        role: parseInt(formData.role),
        department: formData.department,
        is_active: formData.is_active,
        operator_user_id: localStorage.getItem('userId')
      };

      if (!initialData) {
        userData.email = formData.email;
        userData.department = formData.department;
        // userData.password = formData.password;

        const encryptedPassword = encryptPassword(formData.password);
        if (!encryptedPassword) {
          throw new Error('Failed to encrypt password');
        }
        userData.password = encryptedPassword;

      } else {
        userData.user_id = initialData?.id;
      }

      const endpoint = initialData
        ? `/cms_update_user_info`
        : '/cms_register';

      const response = await post(endpoint, userData);

      if (initialData ? response.data?.success : response.data) {
        showAlert(
          'success',
          'Success',
          i18n.t(initialData ? 'userUpdatedSuccess' : 'userCreatedSuccess')
        );
        handleClose();
        onComplete();
      } else {
        throw new Error(i18n.t('genericError'));
      }
    } catch (error) {
      console.error('Error in user operation:', error);
      showAlert('error', 'Operation Failed', error.message || i18n.t('genericError'));
    } finally {
      setIsLoading(false);
    }
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      size="xl"
      scrollBehavior="inside"
      closeOnOverlayClick={false}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        maxW={modalMaxWidth}
        w={modalSize}
        mx="auto"
        my={{ base: 0, md: 4 }}
      >
        <ModalHeader>
          {initialData ? i18n.t('editUser') : i18n.t('createUser')}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {alertInfo && (
            <Alert status={alertInfo.status} mb={4}>
              <AlertIcon />
              <AlertTitle mr={2}>{alertInfo.title}</AlertTitle>
              <AlertDescription>{alertInfo.description}</AlertDescription>
            </Alert>
          )}

          <form id="user-form" onSubmit={handleSubmit} autoComplete="off">
            <VStack spacing={4} align="stretch" px={contentPadding}>
              <FormControl isInvalid={errors.email}>
                <FormLabel>{i18n.t('email')}</FormLabel>
                <Input
                  value={formData.email}
                  onChange={(e) => handleChange('email', e.target.value)}
                  placeholder={i18n.t('enterEmail')}
                  isReadOnly={!!initialData}
                  backgroundColor={initialData ? "gray.100" : "white"}
                  autoComplete="new-email"
                />
                {errors.email && (
                  <Text color="red.500" fontSize="sm" mt={1}>
                    {errors.email}
                  </Text>
                )}
              </FormControl>

              {!initialData && (
                <FormControl isInvalid={errors.password}>
                  <FormLabel>{i18n.t('password')}</FormLabel>
                  <InputGroup>
                    <Input
                      type={showPassword ? 'text' : 'password'}
                      value={formData.password}
                      onChange={(e) => handleChange('password', e.target.value)}
                      placeholder={i18n.t('enterPassword')}
                      autoComplete="new-password"
                    />
                    <InputRightElement>
                      <IconButton
                        variant="ghost"
                        onClick={() => setShowPassword(!showPassword)}
                        icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                        aria-label={showPassword ? 'Hide password' : 'Show password'}
                      />
                    </InputRightElement>
                  </InputGroup>
                  {errors.password && (
                    <Text color="red.500" fontSize="sm" mt={1}>
                      {errors.password}
                    </Text>
                  )}
                </FormControl>
              )}

              <FormControl isInvalid={errors.full_name}>
                <FormLabel>{i18n.t('fullName')}</FormLabel>
                <Input
                  value={formData.full_name}
                  onChange={(e) => handleChange('full_name', e.target.value)}
                  placeholder={i18n.t('enterFullName')}
                />
                {errors.full_name && (
                  <Text color="red.500" fontSize="sm" mt={1}>
                    {errors.full_name}
                  </Text>
                )}
              </FormControl>

              <FormControl isInvalid={errors.department}>
                <FormLabel>{i18n.t('department')}</FormLabel>
                <Input
                  value={formData.department}
                  onChange={(e) => handleChange('department', e.target.value)}
                  placeholder={'Enter Department'}
                />
                {errors.department && (
                  <Text color="red.500" fontSize="sm" mt={1}>
                    {errors.department}
                  </Text>
                )}
                {/* <Select
                  value={formData.department}
                  onChange={(e) => handleChange('department', e.target.value)}
                  placeholder={i18n.t('selectDepartment')}
                >
                  {DEPARTMENT_OPTIONS.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Select>
                {errors.department && (
                  <Text color="red.500" fontSize="sm" mt={1}>
                    {errors.department}
                  </Text>
                )} */}
              </FormControl>

              <FormControl isInvalid={errors.role}>
                <FormLabel>{i18n.t('role')}</FormLabel>
                {isLoadingRoles ? (
                  <Center py={2}>
                    <Spinner size="sm" />
                  </Center>
                ) : (
                  <Select
                    value={formData.role}
                    onChange={(e) => handleChange('role', e.target.value)}
                    placeholder={i18n.t('selectRole')}
                  >
                    {roles.map(role => (
                      <option key={role.id} value={role.id.toString()}>
                        {capitalizeFirstLetter(role.name)}
                      </option>
                    ))}
                  </Select>
                )}
                {formData.role && (
                  <Text fontSize="sm" color="gray.500" mt={1}>
                    {i18n.t('roleDescription')}
                    {roles.find(r => r.id.toString() === formData.role)?.description}
                  </Text>
                )}
                {errors.role && (
                  <Text color="red.500" fontSize="sm" mt={1}>
                    {errors.role}
                  </Text>
                )}
              </FormControl>

              {/* <FormControl display="flex" alignItems="center">
                <FormLabel mb="0">{i18n.t('activeStatus')}</FormLabel>
                <Tooltip
                  isDisabled={!isCurrentUser}
                  label={i18n.t('cannotDeactivateOwnAccount')}
                  hasArrow
                >
                  <Switch
                    isChecked={formData.is_active}
                    onChange={(e) => handleChange('is_active', e.target.checked)}
                    isDisabled={isCurrentUser}
                    cursor={isCurrentUser ? 'not-allowed' : 'pointer'}
                  />
                </Tooltip>
              </FormControl> */}
            </VStack>
          </form>
        </ModalBody>
        <ModalFooter>
          <Center w="full">
            <Button
              colorScheme="blue"
              size="lg"
              type="submit"
              form="user-form"
              isLoading={isLoading}
              loadingText={initialData ? i18n.t('updating') : i18n.t('creating')}
              width={submitButtonSize}
              bg="blue.500"
              color="white"
              _hover={{ bg: 'blue.600' }}
              _active={{ bg: 'blue.700' }}
              fontSize="md"
              fontWeight="bold"
              boxShadow="lg"
              borderRadius="md"
              transition="all 0.2s"
            >
              {initialData ? i18n.t('update') : i18n.t('create')}
            </Button>
          </Center>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

AddEditUserModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  initialData: PropTypes.shape({
    id: PropTypes.string,
    email: PropTypes.string,
    full_name: PropTypes.string,
    department: PropTypes.string,
    is_active: PropTypes.bool,
    cms_user_roles: PropTypes.arrayOf(PropTypes.shape({
      cms_roles: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        description: PropTypes.string
      })
    })),
    last_sign_in_at: PropTypes.string,
    created_at: PropTypes.string,
    updated_at: PropTypes.string
  })
};

AddEditUserModal.defaultProps = {
  initialData: null,
};

export default AddEditUserModal;
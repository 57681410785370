import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  Box,
  Flex,
  VStack,
  useColorModeValue,
  IconButton,
  Spinner,
  Center,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  useDisclosure,
  HStack,
  Badge,
  Tooltip,
  Text,
  Icon,
  Image
} from '@chakra-ui/react';
import { FaApple, FaAndroid } from 'react-icons/fa';
import { RepeatIcon, AddIcon } from '@chakra-ui/icons';
import { IoMdSend } from 'react-icons/io';
import DataTablePagination from '../../components/base/DataTablePagination';
import DataTable from '../../components/base/DataTableTranslate';
import AddEditPushNotificationModal from './AddEditPushNotificationModal';
import DeletePushNotificationAlert from './AlertDialogComponent';
import { format } from 'date-fns';
import { LANGUAGES, i18n } from './translations';
import { get, post } from '../../../../api/request';

const ICON_SIZE = "16px";  // Consistent icon size
const BADGE_HEIGHT = "32px"; // Consistent badge height

const getStatusColor = (status) => {
  switch (status) {
    case 'sent':
      return {
        bg: 'green.50',
        color: 'green.700',
        borderColor: 'green.200',
        icon: '✓',
      };
    case 'draft':
      return {
        bg: 'blue.50',
        color: 'blue.700',
        borderColor: 'blue.200',
        icon: '✎',
      };
    case 'scheduled':
      return {
        bg: 'purple.50',
        color: 'purple.700',
        borderColor: 'purple.200',
        icon: '⏰',
      };
    case 'failed':
      return {
        bg: 'red.50',
        color: 'red.700',
        borderColor: 'red.200',
        icon: '✕',
      };
    case 'canceled':
      return {
        bg: 'orange.50',
        color: 'orange.700',
        borderColor: 'orange.200',
        icon: '⊘',
      };
    default:
      return {
        bg: 'gray.50',
        color: 'gray.700',
        borderColor: 'gray.200',
        icon: '•',
      };
  }
};

const getTargetTypeColor = (type) => {
  switch (type) {
    case 'android':
      return {
        bg: 'teal.50',
        color: 'teal.700',
        borderColor: 'teal.200',
        icon: FaAndroid
      };
    case 'ios':
      return {
        bg: 'cyan.50',
        color: 'cyan.700',
        borderColor: 'cyan.200',
        icon: FaApple
      };
    default:
      return {
        bg: 'purple.50',
        color: 'purple.700',
        borderColor: 'purple.200',
        icons: [FaApple, FaAndroid]
      };
  }
};
const PushNotificationsPage = () => {
  // State management
  const [notifications, setNotifications] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [filterConfig, setFilterConfig] = useState({ text: '', field: null, value: '' });
  const [sortConfig, setSortConfig] = useState({ field: 'id', direction: 'asc' });
  const [pagination, setPagination] = useState({ currentPage: 1, itemsPerPage: 10 });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [currentLanguage, setCurrentLanguage] = useState(LANGUAGES.ENGLISH);

  const {
    isOpen: isCreateModalOpen,
    onOpen: onCreateModalOpen,
    onClose: onCreateModalClose
  } = useDisclosure();

  const {
    isOpen: isUpdateModalOpen,
    onOpen: onUpdateModalOpen,
    onClose: onUpdateModalClose
  } = useDisclosure();

  const {
    isOpen: isDeleteAlertOpen,
    onOpen: onDeleteAlertOpen,
    onClose: onDeleteAlertClose
  } = useDisclosure();

  const handleSendNotification = useCallback(async (notification) => {
    try {
      // setIsLoading(true);
      const endpoint = '/send_push';
      // console.log(notification.raw.id);

      const data = {
        notificationId: notification.raw.id
      };

      const response = await post(endpoint, data);
      if (response.data?.success) {
        setSuccessMessage(i18n.t('notificationSent'));

        // Fetch new data silently
        try {
          // const options = {
          //   joins: [{
          //     table: 'push_notification_translations',
          //     fields: '*'
          //   }],
          //   filterConfig,
          //   sortConfig,
          //   pagination
          // };
          const response = await get('notification_list');
          if (response.data) {
            // Sort the data by id in descending order
            const sortedData = response.data.sort((a, b) => a.id - b.id);
            setNotifications(sortedData);
            setTotalCount(response.data.total || 0);
          } else {
            throw new Error(response.data?.message || i18n.t('fetchError'));
          }
          // const { data } = await global.genericapi.fetchRecordsNew('push_notifications', options);
          // const { data: total } = await global.genericapi.fetchRecordsNew('push_notifications');
          // setNotifications(data);
          // setTotalCount(data.length);
        } catch (fetchError) {
          console.error('Error refreshing data:', fetchError);
        }

      } else {
        // If response exists but success is false, use the error message from response
        const errorMessage = response.data?.message || i18n.t('sendError');
        throw new Error(errorMessage);
      }
    } catch (error) {
      setError(error.message || i18n.t('sendError'));
    } finally {
      // setIsLoading(false);
    }
  }, []);

  // Column mapping configuration
  const columnMap = useMemo(() => ({
    index: {
      label: '#',
      sortable: false,
      width: '5%',
      align: 'center',
      formatter: (_, index, pagination) =>
        (pagination.currentPage - 1) * pagination.itemsPerPage + index + 1
    },
    send_action: {
      label: 'Send',
      sortable: false,
      width: '10%',
      minWidth: '100px',
      align: 'center',
      formatter: (notification) => {
        const isDraftOrScheduled = ['draft', 'scheduled'].includes(notification.raw.status);

        return (
          <Tooltip
            label={isDraftOrScheduled ? i18n.t('sendNow') : i18n.t('resend')}
            hasArrow
            placement="top"
          >
            <IconButton
              icon={<IoMdSend />}
              size="sm"
              variant="ghost"
              colorScheme="blue"
              onClick={(e) => {
                e.stopPropagation();
                handleSendNotification(notification);
              }}
              aria-label={isDraftOrScheduled ? 'Send Now' : 'Resend'}
              _hover={{ bg: 'blue.50', transform: 'scale(1.05)' }}
              _active={{ bg: 'blue.100', transform: 'scale(0.95)' }}
              transition="all 0.2s"
            />
          </Tooltip>
        );
      }
    },
    title: {
      label: i18n.t('title'),
      sortField: 'translations.title',
      sortable: false,
      width: '15%',
      minWidth: '200px',
      align: 'center',
      formatter: (notification) => {
        const translation = notification.raw.translations.find(
          t => t?.language === currentLanguage
        ) || notification.raw.translations[0];
        return translation?.title || '-';
      },
      searchable: true
    },
    message: {
      label: i18n.t('message'),
      sortField: 'translations.message',
      sortable: false,
      width: '20%',
      minWidth: '250px',
      align: 'center',
      formatter: (notification) => {
        const translation = notification.raw.translations.find(
          t => t?.language === currentLanguage
        ) || notification.raw.translations[0];
        return translation?.message || '-';
      },
      searchable: true
    },
    image_url: {
      label: i18n.t('image'),
      width: '10%',
      minWidth: '200px',
      align: 'center',
      sortable: false,
      imageConfig: {
        height: '60px',
        ratio: '16:9'  // This will make width approximately 106.67px (60px * 16/9)
      },
      formatter: (notification) => {
        const translation = notification.raw.translations.find(
          t => t.language === currentLanguage
        ) || notification.raw.translations[0];
        return {
          url: translation?.image || '/placeholder-image.jpg',
          alt: ``
        };
      }
    },

    target: {
      label: i18n.t('targetType'),
      sortField: 'target',
      sortable: false,
      width: '10%',
      minWidth: '150px',
      align: 'center',
      formatter: (notification) => {
        const targetStyle = getTargetTypeColor(notification.raw.target);
        return (
          <HStack
            spacing={0}
            px={3}
            height={BADGE_HEIGHT}
            bg={targetStyle.bg}
            color={targetStyle.color}
            borderRadius="full"
            border="1px solid"
            borderColor={targetStyle.borderColor}
            boxShadow="sm"
            transition="all 0.2s"
            _hover={{
              transform: 'translateY(-1px)',
              boxShadow: 'md',
            }}
            justify="center"
            align="center"
          >
            <HStack spacing={2} minW="24px" justify="center">
              {targetStyle.icons ? (
                // For 'all' target type
                <HStack spacing={0} align="center">
                  <Icon
                    as={targetStyle.icons[0]}
                    w={ICON_SIZE}
                    h={ICON_SIZE}
                  />
                  <Box w={1}></Box>
                  <Text>+</Text>
                  <Box w={1}></Box>
                  <Icon
                    as={targetStyle.icons[1]}
                    w={ICON_SIZE}
                    h={ICON_SIZE}
                  />
                </HStack>
              ) : (
                // For single platform
                <Icon
                  as={targetStyle.icon}
                  w={ICON_SIZE}
                  h={ICON_SIZE}
                />
              )}
            </HStack>
            {/* <Text
              fontSize="sm"
              fontWeight="medium"
              ml={2}
              lineHeight={BADGE_HEIGHT}
              textTransform="capitalize"
            >
              {notification.raw.target}
            </Text> */}
          </HStack>
        );
      },
      searchable: true
    },
    deep_link: {
      label: i18n.t('deepLink'),
      sortField: 'deep_link',
      sortable: false,
      width: '15%',
      minWidth: '250px',
      align: 'center',
      formatter: (notification) => notification.raw.deep_link || '-',
      searchable: true
    },
    status: {
      label: i18n.t('status'),
      sortField: 'status',
      sortable: false,
      width: '10%',
      minWidth: '150px',
      align: 'center',
      formatter: (notification) => {
        const statusStyle = getStatusColor(notification.raw.status);
        return (
          <HStack
            spacing={0}
            height={BADGE_HEIGHT}
            bg={statusStyle.bg}
            color={statusStyle.color}
            borderRadius="full"
            border="1px solid"
            borderColor={statusStyle.borderColor}
            boxShadow="sm"
            transition="all 0.2s"
            _hover={{
              transform: 'translateY(-1px)',
              boxShadow: 'md',
            }}
            justify="center"
            align="center"
          >
            {/* <Text
              fontSize="sm"
              w={ICON_SIZE}
              textAlign="center"
              lineHeight={ICON_SIZE}
            >
              {statusStyle.icon}
            </Text> */}
            <Text
              fontSize="sm"
              fontWeight="medium"
              ml={2}
              lineHeight={BADGE_HEIGHT}
              textTransform="capitalize"
            >
              {i18n.t(notification.raw.status)}
            </Text>
          </HStack>
        );
      }
    },
    scheduled_at: {
      label: i18n.t('scheduledTime'),
      sortField: 'scheduled_at',
      sortable: false,
      width: '15%',
      minWidth: '180px',
      align: 'center',
      formatter: (notification) => notification.raw.scheduled_at
        ? format(new Date(notification.raw.scheduled_at), 'yyyy-MM-dd HH:mm')
        : '-'
    },
    sent_at: {
      label: i18n.t('sentTime'),
      sortField: 'sent_at',
      sortable: false,
      width: '15%',
      minWidth: '180px',
      align: 'center',
      formatter: (notification) => notification.raw.sent_at
        ? format(new Date(notification.raw.sent_at), 'yyyy-MM-dd HH:mm')
        : '-'
    }
  }), [currentLanguage, handleSendNotification]);

  const processDataForTable = useCallback((notificationsData) => {
    return notificationsData.map((notification, index) => {
      const processedData = {};
      Object.keys(columnMap).forEach(key => {
        const formattedValue = columnMap[key].formatter?.(
          { raw: notification },
          index,
          pagination,
          currentLanguage
        );
        processedData[key] = formattedValue;
      });
      processedData._id = notification.id;
      processedData.raw = notification;
      return processedData;
    });
  }, [columnMap, pagination, currentLanguage]);

  const fetchNotifications = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      // const options = {
      //   joins: [{
      //     table: 'push_notification_translations',
      //     fields: '*'
      //   }],
      //   filterConfig,
      //   sortConfig,
      //   pagination
      // };
      // console.log('response', response.data);
      // const { data } = await global.genericapi.fetchRecordsNew('push_notifications', options);
      // const { data: total } = await global.genericapi.fetchRecordsNew('push_notifications');
      // setNotifications(data);
      // setTotalCount(data.length);
      const response = await get('notification_list');
      if (response.data) {
        // Sort the data by id in descending order
        const sortedData = response.data.sort((a, b) => a.id - b.id);
        setNotifications(sortedData);
        setTotalCount(response.data.total || 0);
      } else {
        throw new Error(response.data?.message || i18n.t('fetchError'));
      }

    } catch (error) {
      console.error('Error fetching notifications:', error);
      setError(error.message || i18n.t('fetchError'));
    } finally {
      setIsLoading(false);
    }
  }, [filterConfig, sortConfig, pagination]);

  useEffect(() => {
    fetchNotifications();
  }, [fetchNotifications]);

  const handleSort = useCallback((field) => {
    setSortConfig(prevConfig => ({
      field,
      direction: prevConfig.field === field && prevConfig.direction === 'asc' ? 'desc' : 'asc'
    }));
  }, []);

  const handleUpdateClick = useCallback((item) => {
    setSelectedNotification(item.raw);
    onUpdateModalOpen();
  }, [onUpdateModalOpen]);

  const handleDeleteClick = useCallback((item) => {
    setSelectedNotification(item.raw);
    onDeleteAlertOpen();
  }, [onDeleteAlertOpen]);

  const handlePageChange = useCallback((newPage) => {
    setPagination(prev => ({ ...prev, currentPage: newPage }));
  }, []);

  const handleItemsPerPageChange = useCallback((newItemsPerPage) => {
    setPagination(prev => ({ ...prev, itemsPerPage: newItemsPerPage, currentPage: 1 }));
  }, []);

  const handleModalComplete = useCallback(() => {
    fetchNotifications();
    setSuccessMessage(i18n.t('operationSuccess'));
  }, [fetchNotifications]);

  const bgColor = useColorModeValue('white', 'gray.800');

  // Clear success message after 3 seconds
  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage('');
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [successMessage]);

  return (
    <Box width="100%" maxWidth="100vw" overflowX="hidden">
      {error && (
        <Alert status="error" mb={4}>
          <AlertIcon />
          <AlertTitle mr={2}>{i18n.t('error')}!</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}

      {successMessage && (
        <Alert status="success" mb={4}>
          <AlertIcon />
          <AlertTitle mr={2}>{i18n.t('success')}!</AlertTitle>
          <AlertDescription>{successMessage}</AlertDescription>
        </Alert>
      )}

      <Box bg={bgColor} p={[3, 4, 5]} width="100%">
        <VStack spacing={[3, 4, 5]} align="stretch">
          <Flex
            direction={["column", "row"]}
            justifyContent="flex-end"
            alignItems={["flex-start", "center"]}
            wrap="wrap"
            gap={4}
          >
            <HStack spacing={4} mt={[0, 0]} wrap="wrap">
              <IconButton
                variant='action'
                icon={<RepeatIcon />}
                aria-label={i18n.t('refresh')}
                onClick={fetchNotifications}
              />
              <IconButton
                variant='action'
                icon={<AddIcon />}
                aria-label={i18n.t('add')}
                onClick={onCreateModalOpen}
              />
            </HStack>
          </Flex>

          {isLoading ? (
            <Center py={10}>
              <Spinner size="xl" color="blue.500" thickness="4px" />
            </Center>
          ) : (
            <DataTable
              data={processDataForTable(notifications)}
              columnMap={columnMap}
              sortField={sortConfig.field}
              sortDirection={sortConfig.direction}
              onSort={handleSort}
              onEdit={handleUpdateClick}
              onDelete={handleDeleteClick}
              onRefresh={fetchNotifications}
            />
          )}

          {/* {totalCount > pagination.itemsPerPage && (
            <DataTablePagination
              currentPage={pagination.currentPage}
              totalPages={Math.ceil(totalCount / pagination.itemsPerPage)}
              onPageChange={handlePageChange}
              itemsPerPage={pagination.itemsPerPage}
              setItemsPerPage={handleItemsPerPageChange}
              itemsPerPageOptions={[5, 10, 20, 50]}
            />
          )} */}
        </VStack>
      </Box>

      <AddEditPushNotificationModal
        isOpen={isCreateModalOpen}
        onClose={onCreateModalClose}
        onComplete={handleModalComplete}
      />

      <AddEditPushNotificationModal
        isOpen={isUpdateModalOpen}
        onClose={onUpdateModalClose}
        onComplete={handleModalComplete}
        initialData={selectedNotification}
      />

      <DeletePushNotificationAlert
        isOpen={isDeleteAlertOpen}
        onClose={onDeleteAlertClose}
        onComplete={handleModalComplete}
        notification={selectedNotification}
        title={i18n.t('deletePushNotification')}
        message={i18n.t('deleteConfirmMessage')}
      />
    </Box>
  );
};

export default PushNotificationsPage;
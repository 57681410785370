// translations.js

/**
 * @typedef {'en' | 'zh-Hant' | 'zh-Hans'} Language
 */

// Configuration object
export const translationConfig = {
    defaultLanguage: 'en',
    englishOnly: true // Flag to control English-only text display
};

/**
 * @type {Record<Language, Record<string, string>>}
 */
export const translations = {
    en: {
        // Tutorial related translations
        createTutorial: 'Create Tutorial Page',
        editTutorial: 'Edit Tutorial Page',
        tutorialImage: 'Tutorial Image',
        sortOrder: 'Order',
        selectPageOrder: 'Select Order',
        tutorialCreatedSuccess: 'Tutorial page created successfully',
        tutorialUpdatedSuccess: 'Tutorial page updated successfully',

        // Button Text
        create: 'Submit',
        update: 'Submit',
        cancel: 'Cancel',

        // Image Upload
        dragImageHere: 'Drag image here or click to upload',
        invalidImageType: 'Please upload a valid image file (JPEG, PNG, or GIF)',
        imageSizeLimit: 'Image size should be less than 5MB',
        uploadSuccess: 'Image uploaded successfully',
        uploadError: 'Failed to upload image',

        // Messages
        required: 'This field is required',
        checkRequiredFields: 'Please check all required fields',
        genericError: 'An error occurred. Please try again',
        updating: 'Updating...',
        creating: 'Creating...',
    },

    'zh-Hant': {
        createTutorial: '創建教學頁面',
        editTutorial: '編輯教學頁面',
        tutorialImage: '教學圖片',
        sortOrder: '頁面順序',
        selectPageOrder: '選擇頁面順序',
        tutorialCreatedSuccess: '教學頁面創建成功',
        tutorialUpdatedSuccess: '教學頁面更新成功',

        // Button Text
        create: '提交',
        update: '提交',
        cancel: '取消',

        // Image Upload
        dragImageHere: '拖拽圖片至此或點擊上傳',
        invalidImageType: '請上傳有效的圖片檔案（JPEG、PNG 或 GIF）',
        imageSizeLimit: '圖片大小應小於 5MB',
        uploadSuccess: '圖片上傳成功',
        uploadError: '圖片上傳失敗',

        // Messages
        required: '此欄位為必填',
        checkRequiredFields: '請檢查所有必填欄位',
        genericError: '發生錯誤，請重試',
        updating: '更新中...',
        creating: '創建中...',
    },

    'zh-Hans': {
        createTutorial: '创建教学页面',
        editTutorial: '编辑教学页面',
        tutorialImage: '教学图片',
        sortOrder: '页面顺序',
        selectPageOrder: '选择页面顺序',
        tutorialCreatedSuccess: '教学页面创建成功',
        tutorialUpdatedSuccess: '教学页面更新成功',

        // Button Text
        create: '提交',
        update: '提交',
        cancel: '取消',

        // Image Upload
        dragImageHere: '拖拽图片至此或点击上传',
        invalidImageType: '请上传有效的图片文件（JPEG、PNG 或 GIF）',
        imageSizeLimit: '图片大小应小于 5MB',
        uploadSuccess: '图片上传成功',
        uploadError: '图片上传失败',

        // Messages
        required: '此字段为必填',
        checkRequiredFields: '请检查所有必填字段',
        genericError: '发生错误，请重试',
        updating: '更新中...',
        creating: '创建中...',
    }
};

export const i18n = {
    /**
     * Get translated text for a given key and language
     * @param {string} key - Translation key
     * @param {Language} [lang] - Target language
     * @returns {string} Translated text
     */
    t(key, lang = translationConfig.defaultLanguage) {
        // Return English text if englishOnly is true, regardless of selected language
        if (translationConfig.englishOnly) {
            return translations.en[key] || key;
        }

        const translation = translations[lang]?.[key];
        if (!translation) {
            console.warn(`Translation missing for key: ${key} in language: ${lang}`);
            return translations.en[key] || key;
        }

        return translation;
    },

    // Get language label
    getLanguageLabel(code, displayLang = translationConfig.defaultLanguage) {
        if (translationConfig.englishOnly) {
            return LANGUAGE_LABELS.en[code] || code;
        }
        return LANGUAGE_LABELS[displayLang]?.[code] || LANGUAGE_LABELS.en[code] || code;
    },

    /**
     * Change the current language
     * @param {Language} lang - New language
     */
    setLanguage(lang) {
        if (translations[lang]) {
            translationConfig.defaultLanguage = lang;
        } else {
            console.warn(`Language ${lang} not supported, falling back to en`);
            translationConfig.defaultLanguage = 'en';
        }
    },

    /**
     * Get all available languages - always returns all languages
     * @returns {Language[]} Array of available languages
     */
    getAvailableLanguages() {
        return Object.keys(translations);
    },

    /**
     * Set English-only text display mode
     * @param {boolean} englishOnly - Whether to display English text only
     */
    setEnglishOnly(englishOnly) {
        translationConfig.englishOnly = englishOnly;
    },

    /**
     * Check if a language is supported
     * @param {string} lang - Language to check
     * @returns {boolean} Whether the language is supported
     */
    isLanguageSupported(lang) {
        return lang in translations;
    },

    /**
     * Get the current language
     * @returns {Language} Current language
     */
    getCurrentLanguage() {
        return translationConfig.defaultLanguage;
    },

    /**
     * Check if English-only mode is enabled
     * @returns {boolean} Whether English-only mode is enabled
     */
    isEnglishOnly() {
        return translationConfig.englishOnly;
    },

    /**
     * Get translation for multiple keys at once
     * @param {string[]} keys - Array of translation keys
     * @param {Language} [lang] - Target language
     * @returns {Record<string, string>} Object with translations
     */
    tMultiple(keys, lang = translationConfig.defaultLanguage) {
        return keys.reduce((acc, key) => ({
            ...acc,
            [key]: this.t(key, lang)
        }), {});
    }
};

export const INITIAL_FORM_STATE = {
    latitude: '',
    longitude: '',
    region_id: '',
    district_id: '',
    stores_translation: Object.keys(translations).reduce((acc, lang) => ({
        ...acc,
        [lang]: {
            address: '',
            remarks: '',
            branch_name: '',
            business_hours: '',
            image_url: '',
            language: lang,
        },
    }), {})
};

// Language codes for easy access
export const LANGUAGES = {
    ENGLISH: 'en',
    TRADITIONAL_CHINESE: 'zh-Hant',
    SIMPLIFIED_CHINESE: 'zh-Hans'
};

// Language labels with English translations
export const LANGUAGE_LABELS = {
    // When displayed in English
    en: {
        'en': 'English',
        'zh-Hant': 'Traditional Chinese',
        'zh-Hans': 'Simplified Chinese'
    },
    // When displayed in Traditional Chinese
    'zh-Hant': {
        'en': 'English',
        'zh-Hant': '繁體中文',
        'zh-Hans': '简体中文'
    },
    // When displayed in Simplified Chinese
    'zh-Hans': {
        'en': 'English',
        'zh-Hant': '繁体中文',
        'zh-Hans': '简体中文'
    }
};

export default i18n;
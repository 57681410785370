import { supabase } from "../../api/supabase";

const AuthService = {

    async signIn(body) {
        const res = await supabase.auth.signInWithPassword({
            email: body['email'],
            password: body['password'],
        })
        return res
    },

    async signIn(body) {
        const res = await supabase.auth.signInWithPassword({
            email: body['email'],
            password: body['password'],
        })
        return res
    },

    async signOut(body) {
        const res = await supabase.auth.signOut()
        return res
    },

    async signUp(body) {
        const res = await supabase.auth.signUp({
            email: body['email'],
            password: body['password'],
        })
        return res
    },

    async getUser() {
        const res = await supabase.auth.getUser()
        return res
    },

    updateLastSignIn: async (userId) => {
        try {
            const { data, error } = await supabase
                .from('cms_users')
                .update({ last_sign_in_at: new Date().toISOString() })
                .eq('id', userId);

            if (error) {
                console.error('Error updating last sign in:', error);
            }
            return { data, error };
        } catch (error) {
            console.error('Error in updateLastSignIn:', error);
            // Don't throw error as this is not critical
        }
    }



}
export default AuthService
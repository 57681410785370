import React from 'react';
import {
  Flex,
  Button,
  Select,
  Text,
  IconButton,
  useColorModeValue,
  useBreakpointValue,
  Tooltip,
} from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

const DataTablePagination = ({
  currentPage,
  totalPages,
  onPageChange,
  itemsPerPage,
  setItemsPerPage,
  itemsPerPageOptions,
}) => {
  const pageNumbers = [];
  const maxPageNumbers = useBreakpointValue({ base: 3, sm: 5, md: 7 });
  let startPage, endPage;

  if (totalPages <= maxPageNumbers) {
    startPage = 1;
    endPage = totalPages;
  } else {
    const maxPagesBeforeCurrentPage = Math.floor(maxPageNumbers / 2);
    const maxPagesAfterCurrentPage = Math.ceil(maxPageNumbers / 2) - 1;
    if (currentPage <= maxPagesBeforeCurrentPage) {
      startPage = 1;
      endPage = maxPageNumbers;
    } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
      startPage = totalPages - maxPageNumbers + 1;
      endPage = totalPages;
    } else {
      startPage = currentPage - maxPagesBeforeCurrentPage;
      endPage = currentPage + maxPagesAfterCurrentPage;
    }
  }

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const textColor = useColorModeValue('gray.600', 'gray.300');
  const activeColor = useColorModeValue('blue.500', 'blue.300');

  return (
    <Flex 
      direction={{ base: "column", sm: "row" }} 
      align="center" 
      justify="space-between" 
      wrap="wrap"
      w="full" 
      py={4} 
      px={{ base: 4, md: 6 }}
      bg={bgColor}
      borderRadius="lg"
      shadow="sm"
      borderWidth={1}
      borderColor={borderColor}
    >
      <Flex align="center" mb={{ base: 4, sm: 0 }} order={{ base: 2, sm: 1 }}>
        <Text mr={2} fontSize="sm" fontWeight="medium" color={textColor}>
          Items per page:
        </Text>
        <Select
          value={itemsPerPage}
          onChange={(e) => setItemsPerPage(Number(e.target.value))}
          size="sm"
          w="70px"
          borderRadius="md"
          focusBorderColor={activeColor}
        >
          {itemsPerPageOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </Select>
      </Flex>
      
      <Flex align="center" justify="center" wrap="wrap" order={{ base: 1, sm: 2 }}>
        <Tooltip label="Previous page" placement="top">
          <IconButton
            icon={<ChevronLeftIcon />}
            onClick={() => onPageChange(currentPage - 1)}
            isDisabled={currentPage === 1}
            aria-label="Previous page"
            size="sm"
            mr={2}
            borderRadius="md"
            variant="outline"
          />
        </Tooltip>
        
        {pageNumbers.map((page) => (
          <Tooltip key={page} label={`Go to page ${page}`} placement="top">
            <Button
              onClick={() => onPageChange(page)}
              variant={page === currentPage ? 'solid' : 'ghost'}
              colorScheme="blue"
              size="sm"
              mx={1}
              borderRadius="md"
              display={{ base: page === currentPage ? 'flex' : 'none', sm: 'flex' }}
            >
              {page}
            </Button>
          </Tooltip>
        ))}
        
        <Tooltip label="Next page" placement="top">
          <IconButton
            icon={<ChevronRightIcon />}
            onClick={() => onPageChange(currentPage + 1)}
            isDisabled={currentPage === totalPages}
            aria-label="Next page"
            size="sm"
            ml={2}
            borderRadius="md"
            variant="outline"
          />
        </Tooltip>
      </Flex>
      
      <Text fontSize="sm" color={textColor} mt={{ base: 4, sm: 0 }} order={{ base: 3, sm: 3 }} w={{ base: "full", sm: "auto" }} textAlign={{ base: "center", sm: "left" }}>
        Page {currentPage} of {totalPages}
      </Text>
    </Flex>
  );
};

export default DataTablePagination;
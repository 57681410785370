import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Grid,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Tab,
  Text,
  Select,
  Textarea,
  Divider,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Center,
  Stack,
  Radio,
  RadioGroup,
  FormHelperText,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import TableOperations from '../../components/base/utils/TableOperations';
import DragNDrop from '../../components/base/DragNDrop';
import { i18n, LANGUAGES } from './translations';
import { post } from '../../../../api/request';

// Modal configuration
const modalConfig = {
  maxWidth: {
    base: "90%",
    md: "800px",
    lg: "1000px",
    xl: "1200px"
  },
  width: {
    base: "95%",
    md: "90%",
    lg: "85%",
    xl: "80%"
  },
  padding: {
    base: "1rem",
    md: "1.5rem",
    lg: "2rem"
  },
  buttonWidth: {
    base: "80%",
    md: "70%",
    lg: "60%",
    xl: "50%"
  }
};

const NOTIFICATION_TARGET = {
  IOS: 'ios',
  ANDROID: 'android',
  ALL: 'all'
};

const NOTIFICATION_STATUS = {
  DRAFT: 'draft',
  SCHEDULED: 'scheduled',
  SENT: 'sent',
  FAILED: 'failed',
  CANCELED: 'canceled'
};

const INITIAL_FORM_STATE = {
  target: NOTIFICATION_TARGET.ALL,
  deep_link: '',
  status: NOTIFICATION_STATUS.DRAFT,
  scheduled_at: '',
  translations: Object.values(LANGUAGES).map(lang => ({
    language: lang,
    title: '',
    message: '',
    image: ''
  }))
};

const safeGetTranslation = (translations, language) => {
  try {
    return translations?.find(t => t.language === language) || {};
  } catch (error) {
    console.error('Error accessing translation:', error);
    return {};
  }
};

const AddEditPushNotificationModal = ({ isOpen, onClose, onComplete, initialData = null }) => {
  const [formData, setFormData] = useState(INITIAL_FORM_STATE);
  const [activeLanguageTab, setActiveLanguageTab] = useState(0);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [alertInfo, setAlertInfo] = useState(null);
  const [currentLanguage, setCurrentLanguage] = useState(LANGUAGES.ENGLISH);

  const showAlert = (status, title, description) => {
    setAlertInfo({ status, title, description });
    setTimeout(() => setAlertInfo(null), 3000);
  };

  const formatDateForServer = (dateString) => {
    if (!dateString) return null;
    try {
      const date = new Date(dateString);
      return date.toISOString();
    } catch (error) {
      console.error('Date formatting error:', error);
      return null;
    }
  };

  const formatDateForInput = (dateString) => {
    if (!dateString) return '';
    try {
      const date = new Date(dateString);
      return date.toISOString().slice(0, 16);
    } catch (error) {
      console.error('Date formatting error:', error);
      return '';
    }
  };

  useEffect(() => {
    if (isOpen) {
      if (initialData) {
        setFormData({
          id: initialData.id,
          target: initialData.target,
          deep_link: initialData.deep_link || '',
          status: initialData.status || NOTIFICATION_STATUS.DRAFT,
          scheduled_at: initialData.scheduled_at ?
            formatDateForInput(initialData.scheduled_at) : '',
          translations: Object.values(LANGUAGES).map(lang => {
            const translation = initialData.translations.find(t => t.language === lang) || {};
            return {
              language: lang,
              title: translation.title || '',
              message: translation.message || '',
              image: translation.image || ''
            };
          })
        });
      } else {
        setFormData(INITIAL_FORM_STATE);
      }
      setActiveLanguageTab(0);
      setErrors({});
      setAlertInfo(null);
    }
  }, [isOpen, initialData]);

  useEffect(() => {
    const languages = Object.values(LANGUAGES);
    const newLanguage = languages[activeLanguageTab];
    if (newLanguage) {
      setCurrentLanguage(newLanguage);
      i18n.setLanguage(newLanguage);
    }
  }, [activeLanguageTab]);

  const handleClose = () => {
    setFormData(INITIAL_FORM_STATE);
    setErrors({});
    setActiveLanguageTab(0);
    setAlertInfo(null);
    onClose();
  };

  const handleBasicInfoChange = (field, value) => {
    setFormData(prev => {
      const updates = { [field]: value };

      if (field === 'status' && value !== NOTIFICATION_STATUS.SCHEDULED) {
        updates.scheduled_at = '';
      }

      return { ...prev, ...updates };
    });

    setErrors(prev => ({ ...prev, [field]: '' }));
  };

  const handleTranslationChange = (language, field, value) => {
    setFormData(prev => ({
      ...prev,
      translations: prev.translations.map(translation =>
        translation.language === language
          ? { ...translation, [field]: value }
          : translation
      )
    }));
    setErrors(prev => ({ ...prev, [`${language}_${field}`]: '' }));
  };

  const handleImageUpload = async (language, file) => {
    if (!file) return;

    const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
    const maxSize = 5 * 1024 * 1024;

    if (!validImageTypes.includes(file.type)) {
      showAlert('error', 'Invalid File Type', i18n.t('invalidImageType'));
      return;
    }

    if (file.size > maxSize) {
      showAlert('error', 'File Too Large', i18n.t('imageSizeLimit'));
      return;
    }

    try {
      const image_url = await global.genericapi.uploadImageAndGetPublicUrl('push_notifications', file);
      handleTranslationChange(language, 'image', image_url);
      showAlert('success', 'Success', i18n.t('uploadSuccess'));
    } catch (error) {
      showAlert('error', 'Upload Failed', error.message);
    }
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.target) {
      newErrors.target = i18n.t('targetRequired');
    }

    formData.translations.forEach(translation => {
      if (!translation.title?.trim()) {
        newErrors[`${translation.language}_title`] = i18n.t('titleRequired');
      }
      if (!translation.message?.trim()) {
        newErrors[`${translation.language}_message`] = i18n.t('messageRequired');
      }
    });

    if (formData.status === NOTIFICATION_STATUS.SCHEDULED) {
      if (!formData.scheduled_at) {
        newErrors.scheduled_at = i18n.t('scheduledTimeRequired');
      } else {
        const scheduledDate = new Date(formData.scheduled_at);
        const now = new Date();
        now.setMilliseconds(0);
        scheduledDate.setMilliseconds(0);

        if (scheduledDate <= now) {
          newErrors.scheduled_at = i18n.t('scheduledTimeMustBeFuture');
        }
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      showAlert('error', 'Error', i18n.t('checkRequiredFields'));
      return;
    }

    setIsLoading(true);

    try {
      const jsonData = {
        target: formData.target,
        deep_link: formData.deep_link || null,
        status: formData.status,
        scheduled_at: formData.status === NOTIFICATION_STATUS.SCHEDULED ?
          formatDateForServer(formData.scheduled_at) : null,
        translations: formData.translations.map(translation => ({
          language: translation.language,
          title: translation.title,
          message: translation.message,
          image: translation.image
        })),
        created_by: localStorage.getItem('userId')
      };

      if (initialData) {
        jsonData.id = parseInt(initialData.id);
      }

      const response = await post('edit_notification', jsonData);

      if (initialData ? response.data?.success : response.data?.success) {
        showAlert(
          'success',
          'Success',
          i18n.t(initialData ? 'notificationUpdated' : 'notificationCreated')
        );
        handleClose();
        onComplete();
      }
    } catch (error) {
      // console.error('Error in notification operation:', error);
      showAlert('error', 'Error', error.message || i18n.t('genericError'));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      size="full"
      scrollBehavior="inside"
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent
        maxW={modalConfig.maxWidth}
        w={modalConfig.width}
        mx="auto"
        my={4}
        minH={{ base: "100vh", md: "auto" }}
      >
        <ModalHeader>
          {initialData ? i18n.t('editNotification') : i18n.t('createNotification')}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p={modalConfig.padding}>
          {alertInfo && (
            <Alert status={alertInfo.status} mb={4}>
              <AlertIcon />
              <AlertTitle mr={2}>{alertInfo.title}</AlertTitle>
              <AlertDescription>{alertInfo.description}</AlertDescription>
            </Alert>
          )}

          <form id="notification-form" onSubmit={handleSubmit}>
            <VStack spacing={6} align="stretch">
              <Tabs index={activeLanguageTab} onChange={setActiveLanguageTab}>
                <TabList>
                  {Object.values(LANGUAGES).map(lang => (
                    <Tab key={lang}>
                      {i18n.getLanguageLabel(lang)}
                    </Tab>
                  ))}
                </TabList>
                <TabPanels>
                  {Object.values(LANGUAGES).map(lang => (
                    <TabPanel key={lang}>
                      <VStack spacing={4} align="stretch">
                        <FormControl isInvalid={errors[`${lang}_title`]}>
                          <FormLabel>{i18n.t('notificationTitle')}</FormLabel>
                          <Input
                            value={safeGetTranslation(formData.translations, lang)?.title || ''}
                            onChange={(e) => handleTranslationChange(lang, 'title', e.target.value)}
                            placeholder={i18n.t('enterTitle')}
                          />
                          {errors[`${lang}_title`] && (
                            <Text color="red.500" fontSize="sm" mt={1}>
                              {errors[`${lang}_title`]}
                            </Text>
                          )}
                        </FormControl>

                        <FormControl isInvalid={errors[`${lang}_message`]}>
                          <FormLabel>{i18n.t('notificationMessage')}</FormLabel>
                          <Textarea
                            value={safeGetTranslation(formData.translations, lang)?.message || ''}
                            onChange={(e) => handleTranslationChange(lang, 'message', e.target.value)}
                            placeholder={i18n.t('enterMessage')}
                            rows={4}
                          />
                          {errors[`${lang}_message`] && (
                            <Text color="red.500" fontSize="sm" mt={1}>
                              {errors[`${lang}_message`]}
                            </Text>
                          )}
                        </FormControl>

                        <FormControl>
                          <FormLabel>{i18n.t('notificationImage')}</FormLabel>
                          <DragNDrop
                            onFileUpload={(file) => handleImageUpload(lang, file)}
                            currentImage={formData.translations.find(t => t.language === lang)?.image}
                            dragText={i18n.t('dragImageHere')}
                          />
                        </FormControl>
                      </VStack>
                    </TabPanel>
                  ))}
                </TabPanels>
              </Tabs>

              <Divider />

              <Box>
                <Text fontSize="lg" fontWeight="bold" mb={4}>
                  {i18n.t('notificationSettings')}
                </Text>
                <VStack spacing={4} align="stretch">
                  <FormControl isInvalid={errors.target}>
                    <FormLabel>{i18n.t('targetPlatform')}</FormLabel>
                    <RadioGroup
                      value={formData.target}
                      onChange={(value) => handleBasicInfoChange('target', value)}
                    // isDisabled={initialData?.status === NOTIFICATION_STATUS.SENT}
                    >
                      <Stack direction="row" spacing={4}>
                        <Radio value={NOTIFICATION_TARGET.ALL}>
                          {i18n.t('all')}
                        </Radio>
                        <Radio value={NOTIFICATION_TARGET.IOS}>
                          {i18n.t('ios')}
                        </Radio>
                        <Radio value={NOTIFICATION_TARGET.ANDROID}>
                          {i18n.t('android')}
                        </Radio>
                      </Stack>
                    </RadioGroup>
                    {errors.target && (
                      <Text color="red.500" fontSize="sm" mt={1}>
                        {errors.target}
                      </Text>
                    )}
                  </FormControl>

                  <FormControl>
                    <FormLabel>{i18n.t('deepLink')}</FormLabel>
                    <Input
                      value={formData.deep_link}
                      onChange={(e) => handleBasicInfoChange('deep_link', e.target.value)}
                      placeholder={i18n.t('enterDeepLink')}
                    // isDisabled={initialData?.status === NOTIFICATION_STATUS.SENT}
                    />
                  </FormControl>

                  <FormControl isInvalid={errors.status}>
                    <FormLabel>{i18n.t('status')}</FormLabel>
                    <RadioGroup
                      value={formData.status}
                      onChange={(value) => handleBasicInfoChange('status', value)}
                    >
                      <Stack direction={["column", "row"]} spacing={4}>
                        <Radio value={NOTIFICATION_STATUS.DRAFT}>
                          {i18n.t('draft')}
                        </Radio>
                        <Radio value={NOTIFICATION_STATUS.SCHEDULED}>
                          {i18n.t('scheduled')}
                        </Radio>
                        <Radio value={NOTIFICATION_STATUS.CANCELED} isDisabled={!initialData}>
                          {i18n.t('canceled')}
                        </Radio>
                        <Radio
                          value={NOTIFICATION_STATUS.SENT}
                          isDisabled={true}
                        >
                          {i18n.t('sent')}
                        </Radio>
                        <Radio
                          value={NOTIFICATION_STATUS.FAILED}
                          isDisabled={true}
                        >
                          {i18n.t('failed')}
                        </Radio>
                      </Stack>
                    </RadioGroup>
                    {errors.status && (
                      <Text color="red.500" fontSize="sm" mt={1}>
                        {errors.status}
                      </Text>
                    )}
                  </FormControl>

                  <FormControl isInvalid={errors.scheduled_at}>
                    <FormLabel>{i18n.t('scheduleTime')}</FormLabel>
                    <Input
                      type="datetime-local"
                      value={formData.scheduled_at}
                      onChange={(e) => handleBasicInfoChange('scheduled_at', e.target.value)}
                      isDisabled={formData.status !== NOTIFICATION_STATUS.SCHEDULED}
                      min={new Date().toISOString().slice(0, 16)}
                    />
                    <FormHelperText>
                      {formData.status === NOTIFICATION_STATUS.SCHEDULED
                        ? i18n.t('selectScheduledTime')
                        : i18n.t('onlyAvailableForScheduled')}
                    </FormHelperText>
                    {errors.scheduled_at && (
                      <Text color="red.500" fontSize="sm" mt={1}>
                        {errors.scheduled_at}
                      </Text>
                    )}
                  </FormControl>
                </VStack>
              </Box>
            </VStack>
          </form>
        </ModalBody>
        <ModalFooter>
          <Center w="full">
            <Button
              colorScheme="blue"
              size="lg"
              type="submit"
              form="notification-form"
              isLoading={isLoading}
              loadingText={initialData ? i18n.t('updating') : i18n.t('creating')}
              width={modalConfig.buttonWidth}
              bg="blue.500"
              color="white"
              _hover={{ bg: 'blue.600' }}
              _active={{ bg: 'blue.700' }}
              fontSize="md"
              fontWeight="bold"
              boxShadow="lg"
              borderRadius="md"
              transition="all 0.2s"
            // isDisabled={[NOTIFICATION_STATUS.SENT, NOTIFICATION_STATUS.FAILED].includes(initialData?.status)}
            >
              {initialData ? i18n.t('update') : i18n.t('create')}
            </Button>
          </Center>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

AddEditPushNotificationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  initialData: PropTypes.shape({
    id: PropTypes.number,
    target: PropTypes.oneOf(['ios', 'android', 'all']),
    deep_link: PropTypes.string,
    status: PropTypes.oneOf(Object.values(NOTIFICATION_STATUS)),
    scheduled_at: PropTypes.string,
    translations: PropTypes.arrayOf(PropTypes.shape({
      language: PropTypes.string.isRequired,
      title: PropTypes.string,
      message: PropTypes.string,
      image: PropTypes.string
    }))
  })
};

AddEditPushNotificationModal.defaultProps = {
  initialData: null,
};

export default AddEditPushNotificationModal;
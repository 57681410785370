// FormFields/ImageField.jsx
import React, { useState , useEffect, useCallback, useRef, forwardRef } from 'react';
import { FormControl, FormLabel, Input, Image, Box,Button,Text, useColorModeValue} from '@chakra-ui/react';
import { Icon } from '@chakra-ui/react';
import { MdPhoto } from 'react-icons/md';
import { blacken } from '@chakra-ui/theme-tools';
const ImageField =({ label, value, onChange, tableName, fileInputRef,showPreview = true, language, isMultiLanguageSupported, ...rest }) => {
  const [uploading, setUploading] = useState(false);
  const [isImageError, setIsImageError] = useState(false);
  const iconColor = useColorModeValue('gray.300', 'gray.300');
  console.log("ImageField",value);

  useEffect(() => {
    if (value) {
      console.log("Updated value:", value);
      setIsImageError(false);
    }
  }, [value,fileInputRef]);
  const handleImageChange = async (e) => {

    const file = e.target.files[0];
    if (!file) return;

    setUploading(true);
    try {
      const folder = tableName === 'icon_setting' ? 'icon' : 
                     tableName === 'tutorial_settings' ? 'tutorial' : 'icon';
                     console.log(folder,999999,file)
      const publicUrl = await global.genericapi.uploadImageAndGetPublicUrl(folder, file);
      if (publicUrl) {
        onChange(publicUrl);
        setIsImageError(false);

      }

    } catch (error) {
      console.error('Error uploading image:', error);
    } finally {
      setUploading(false);
    }
    console.log('Image Field Props:', { label, value });
  };
  const handleClick = () => {
    console.log('fileInputRef:', fileInputRef); // 打印 current 的值
    console.log('fileInputRef.current:', fileInputRef.current); // 打印 current 的值
    if (isMultiLanguageSupported) {
     
      if (fileInputRef.current && fileInputRef.current[language]) {
        fileInputRef.current[language].click();
      } else {
        console.error(`fileInputRef 的 language ${language} 未定义`);
      }
    } else {
     console.log(fileInputRef,9999)
      if (fileInputRef.current) {
        fileInputRef.current.click();
      } else {
        console.error('fileInputRef 未定义');
      }
    }
  };


  return (
    <FormControl mb={4}>
      <FormLabel>{label}</FormLabel>
      {value && !isImageError ? (
        <Box>
          {showPreview && value && (
          <Image 
            src={value} 
            boxSize="150px" 
            objectFit="contain" 
            mt={2} 
            onError={(e) => {
              setIsImageError(true);
            }} // 图片加载失败时触发
          />
        )}
        </Box>
      ) : (
         <Box display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center" width={160} height={160} border="1px" borderStyle="dashed" borderColor="gray.300" p={1}  onClick={handleClick}>
            <box color={iconColor}>
              <Icon as={MdPhoto} boxSize={30} color={iconColor} />
            </box>
            <box>
              <Text fontSize="sm" color="gray.500" cursor="pointer" >
                Click to upload an image
              </Text>
            </box>
          </Box>
      )}
       <Input
        ref={(ref) => {
          console.log('Assigned ref:', ref); // 打印传入的 DOM 元素
          console.log('Current fileInputRef:', fileInputRef); // 打印 fileInputRef 的状态
      
          if (isMultiLanguageSupported) {
            if (ref) {
              fileInputRef.current[language] = ref;
            }
          } else {
            fileInputRef.current = ref;
          }
          console.log('Updated fileInputRef:', fileInputRef);
        }}
        type="file"
        onChange={handleImageChange}
        style={{ display: 'none' }} // 隐藏 input
        {...rest}
      />

    </FormControl>
  );
};

export default ImageField;
